import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import "./AddNewEmployeeForm.scss"
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';

const AddNewEmployeeForm = ({ setOpenModal,
    userCookie,
    employees,
    setEmployees,
    handleNotifyAlert,
    SetLoadingVisibility }) => {
    const modalRef = useRef();
    const usernameElementRef = useRef();
    const userEmailElementRef = useRef();
    const passwordElementRef = useRef();
    const confirmPasswordElementRef = useRef();
    const userphoneNoElementRef = useRef();
    const errorMessageElementRef = useRef();

    const closeModal = e => {
        if (modalRef.current === e.target) {
          setOpenModal();
        }
      };

    const handelAddNewEmployee = () => {
        SetLoadingVisibility(true)
        let username = usernameElementRef.current.value;
        let userEmail = userEmailElementRef.current.value;
        let password = passwordElementRef.current.value;
        let confirmPassword = confirmPasswordElementRef.current.value;
        let phoneNo = `+91${userphoneNoElementRef.current.value}`;
        //console.log(username, password, confirmPassword);
        if (userphoneNoElementRef.current.value.length === 10) {
            if (username.length >= 6 && password.length > 5 && userEmail.length > 5) {
                if (password === confirmPassword && password.length >= 6) {
                    // if (userDesignation !== "Select") {
                    //     errorMessageElementRef.current.style.opacity = 0;
                    axios({
                        url: backEndIP + "/admin/register",
                        // url: backEndIP + "/user/register",
                        method: "POST",
                        headers: {
                            authorization: userCookie.token
                        },
                        data: {
                            useremail: userEmail,
                            username: username,
                            password: password,
                            // designation: userDesignation,
                            phone_no: phoneNo,
                        },
                    })
                        .then(async (employeeResp) => {
                            SetLoadingVisibility(false)
                            setOpenModal(false)
                            handleNotifyAlert(
                                "notify",
                                `${employeeResp.data.message}`,
                                "success",
                                () => { }
                            )
                            const employeesResp = await axios({
                                url: backEndIP + "/admin/employee",
                                // url: backEndIP + "/user/login",
                                method: "GET",
                                headers: {
                                    authorization: userCookie.token
                                },
                            }).catch((err) => {
                                console.log(err);
                            })
                            setEmployees(employeesResp.data.data)
                        })
                        .catch((err) => {
                            SetLoadingVisibility(false)
                            setOpenModal(false)
                            handleNotifyAlert(
                                "notify",
                                `${err.response.data.message}`,
                                "failed",
                                () => { }
                            )
                        });
                    // } else {
                    //     errorMessageElementRef.current.innerHTML = "Please Select Designation"
                    //     errorMessageElementRef.current.style.opacity = 1;
                    // }
                } else {
                    if (password.length < 6) {
                        errorMessageElementRef.current.innerHTML =
                            "minimum length of password should be 6";
                        errorMessageElementRef.current.style.opacity = 1;
                    } else {
                        errorMessageElementRef.current.innerHTML = "password does not match";
                        errorMessageElementRef.current.style.opacity = 1;
                    }
                }
            } else {
                if (username.length < 6) {
                    errorMessageElementRef.current.innerHTML = "pls enter username";
                    errorMessageElementRef.current.style.opacity = 1;
                } else {
                    errorMessageElementRef.current.innerHTML = "pls enter password";
                    errorMessageElementRef.current.style.opacity = 1;
                }
            }
        } else {
            errorMessageElementRef.current.innerHTML = "Enter Correct Phone No";
            errorMessageElementRef.current.style.opacity = 1;
        }
    };

    // console.log("Designation =", designationElementRef)

    return (
        <div onClick={closeModal} ref={modalRef} className="AddNewEmployee-modalBackground">
            <div className="AddNewEmployee-modalContainer" >
                <div className="AddNewEmployee-modalContainer__title">
                    <div className="AddNewEmployee-modalContainer__title__heading">Add New Employee</div>
                    <button className="AddNewEmployee-modalContainer__title__cancleBtn" onClick={() => { setOpenModal(false) }}>X</button>
                </div>
                <div className="AddNewEmployee-modalContainer__register-container">
                    <input ref={usernameElementRef}
                        className="AddNewEmployee-modalContainer__register-container__input-email"
                        type={"text"}
                        placeholder={"User Name"}></input>
                    <input ref={userEmailElementRef}
                        className="AddNewEmployee-modalContainer__register-container__input-email"
                        type={"email"}
                        placeholder={"User Email Id"}></input>
                    <div
                        className="AddNewEmployee-modalContainer__register-container__input-email">
                        <input readOnly
                            type={"text"}
                            className="AddNewEmployee-modalContainer__register-container__input-email__fixDigit"
                            value={"+91"} />
                        <input
                            ref={userphoneNoElementRef}
                            className="AddNewEmployee-modalContainer__register-container__input-email__phone"
                            type={"number"}
                            placeholder={"User Phone No"}
                            onChange={(e) => {
                                if (e.target.value.length > 10) {
                                    userphoneNoElementRef.current.value = e.target.value.slice(0, 10)
                                }
                            }}
                        ></input>
                    </div>
                    {/* <select
                        ref={designationElementRef}
                        className="AddNewEmployee-modalContainer__register-container__input-email"
                        placeholder={"User Designation"}
                        onChange={() => {
                            errorMessageElementRef.current.style.opacity = 0;
                           // console.log(designationElementRef.current.value)
                        }}
                    >
                        <option value={"Select"}>Select</option>
                        {designationVal.map((item, i) => {
                            return (<option key={i + 1} value={item._id}>{item.accessRole}</option>)
                        })}</select> */}
                    <input ref={passwordElementRef}
                        className="AddNewEmployee-modalContainer__register-container__input-email"
                        type={"password"}
                        placeholder={"Password"}></input>
                    <input ref={confirmPasswordElementRef}
                        className="AddNewEmployee-modalContainer__register-container__input-email"
                        type={"password"}
                        placeholder={"Confirm Password"}></input>
                    <div ref={errorMessageElementRef}
                        className="AddNewEmployee-modalContainer__register-container__error-msg"
                    >message</div>
                </div>
                <div className="AddNewEmployee-modalContainer__button" >
                    <div className="AddNewEmployee-modalContainer__button__OkayBtn" onClick={handelAddNewEmployee}>Submit</div>
                </div>
            </div>
        </div >
    );
}


export default AddNewEmployeeForm;