
import { validateAddress, validateAerialDistance, validateAerialTime, validateAreaAvailable, validateATC, validateBloodBank, validateChargingFacilityTypes, validateCompatibleDrones, validateCountry, validateDistrict, validateElectricityTimings, validateFireBrigade, validateHospital, validateLatitude, validateLocationName, validateLongitude, validateMaxFlightTime, validateNearestFacilities, validateNetworkAvailability, validatePinCode, validatePoliceStation, validatePotentialUseCases, validateQRLanding, validateRelatedHubs, validateRoadDistance, validateRoadTime, validateShadesForPilots, validateState, validateStateName, validateStorageFacilityTypeandCapacity, validateSurfaceType, validateTagName, validateZone } from "../../../utility/nodeFormFunction";


export const validateFunctions = {
    "location_name": validateLocationName,
    "latitude": validateLatitude,
    "longitude": validateLongitude,
    "country_id": validateCountry,
    "state_id": validateState,
    "zone_id": validateZone,
    "tag_name": validateTagName,
    "relatedHubs": validateRelatedHubs,
    "pin_code": validatePinCode,
    "district": validateDistrict,
    "address": validateAddress,
    "QRLanding": validateQRLanding,
    "surfaceType": validateSurfaceType,
    "areaAvailable": validateAreaAvailable,
    "networkAvailability": validateNetworkAvailability,
    "storageFacilityTypeandCapacity": validateStorageFacilityTypeandCapacity,
    "chargingFacilityTypes": validateChargingFacilityTypes,
    "electricityTimings": validateElectricityTimings,
    "shadesForPilots": validateShadesForPilots,
    "compatibleDrones": validateCompatibleDrones,
    "potentialUseCases": validatePotentialUseCases,
    "nearestFacilities": validateNearestFacilities,
    "roadDistance": validateRoadDistance,
    "roadTime": validateRoadTime,
    "maxFlightTime": validateMaxFlightTime,
    "aerialDistance": validateAerialDistance,
    "aerialTime": validateAerialTime,
    "hospital": validateHospital,
    "ATC": validateATC,
    "policeStation": validatePoliceStation,
    "bloodBank": validateBloodBank,
    "fireBrigade": validateFireBrigade,
};


export const nodeFormData = {
    noOfSections: 3,
    section_1: {
        label: "Basic",
        noOfRows: 5,
        row_1: {
            height: 2, //in units
            noOfCols: 3,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Location Name", isMandatory: true, keyName: "location_name", type: "text", width: (1 / 3), validate: validateFunctions },


                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "Latitude", isMandatory: true, keyName: "latitude", type: "number", width: (1 / 3), validate: validateFunctions },
                ]
            },
            col_3: {
                width: 1 / 3,
                data: [
                    { label: "Longitude", isMandatory: true, keyName: "longitude", type: "number", width: (1 / 3), validate: validateFunctions }
                ]
            }
        },

        row_2: {
            height: 1, //in units
            noOfCols: 3,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Country", isMandatory: true, keyName: "country_id", type: "text", width: (1 / 3), validate: validateFunctions }

                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "State", isMandatory: true, keyName: "state_id", type: "text", width: (1 / 2), validate: validateFunctions }

                ]
            },
            col_3: {
                width: 1 / 3,
                data: [
                    { label: "Zone", isMandatory: true, keyName: "zone_id", type: "text", width: (1 / 2), validate: validateFunctions },
                ]
            }
        },
        row_3: {
            height: 1,
            noOfCols: 2,

            col_1: {
                width: 1 / 3,
                data: [
                    // { label: "State Name", isMandatory: true, keyName: "state", type: "text", width: (1 / 2), validate: validateStateName },
                    { label: "Tag Name", isMandatory: true, keyName: "tag_name", type: "text", width: (1 / 2), validate: validateFunctions }
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "Hub", isMandatory: true, keyName: "relatedHubs", type: "text", width: (1 / 2), validate: validateFunctions },
                ]
            }
        },
        row_4: {
            height: 1,
            noOfCols: 2,

            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Pin Code", isMandatory: true, keyName: "pin_code", type: "number", width: (1 / 2), validate: validateFunctions },
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "District", isMandatory: true, keyName: "district", type: "text", width: (1 / 3), validate: validateFunctions },
                ]
            },

        },
        row_5: {
            height: 1,
            noOfCols: 1,

            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Address", isMandatory: true, keyName: "address", type: "text", width: (1 / 3), validate: validateFunctions }
                ]
            },

        },

    },
    section_2: {
        label: "Operation",
        noOfRows: 6,
        row_1: {
            height: 2, //in units
            noOfCols: 3,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "QR Landing", isMandatory: true, keyName: "QRLanding", type: "text", width: (2 / 3), validate: validateFunctions },
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "Surface Type", isMandatory: true, keyName: "surfaceType", type: "text", width: (1 / 3), validate: validateFunctions }
                ]
            },
            col_3: {
                width: 1 / 3,
                data: [
                    { label: "Area Available [sq. ft.]", isMandatory: false, keyName: "areaAvailable", type: "number", width: (1 / 2), validate: validateFunctions },
                ]
            }
        },
        row_2: {
            height: 2,
            noOfCols: 2,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Network Availability", isMandatory: false, keyName: "networkAvailability", type: "text", width: (1 / 2), validate: validateFunctions }
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "Storage Facility Type and Capacity", isMandatory: true, keyName: "storageFacilityTypeandCapacity", type: "text", width: (1 / 2), validate: validateFunctions },
                ]
            },

        },
        row_3: {
            height: 2, //in units
            noOfCols: 3,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Charging Facility Types", isMandatory: true, keyName: "chargingFacilityTypes", type: "text", width: (1 / 2), validate: validateFunctions }
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "Electricity Timings", isMandatory: false, keyName: "electricityTimings", type: "text", width: (1 / 2), validate: validateFunctions },
                ]
            },
            col_3: {
                width: 1 / 3,
                data: [
                    { label: "Shades For Pilots", isMandatory: false, keyName: "shadesForPilots", type: "text", width: (1 / 2), validate: validateFunctions }
                ]
            }
        },
        row_4: {
            height: 2, //in units
            noOfCols: 3,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Compatible Drones", isMandatory: false, keyName: "compatibleDrones", type: "text", width: (1 / 2), validate: validateFunctions },
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "Potential Use Cases", isMandatory: false, keyName: "potentialUseCases", type: "text", width: (1 / 2), validate: validateFunctions },
                ]
            },
            col_3: {
                width: 1 / 3,
                data: [
                    { label: "Nearest Facilities", isMandatory: false, keyName: "nearestFacilities", type: "text", width: (1 / 2), validate: validateFunctions }
                ]
            }
        },
        row_5: {
            height: 2, //in units
            noOfCols: 2,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Road Distance", isMandatory: false, keyName: "roadDistance", type: "number", width: (1 / 2), validate: validateFunctions }
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "Road Time", isMandatory: false, keyName: "roadTime", type: "number", width: (1 / 3), validate: validateFunctions },
                ]
            },

        },
        row_6: {
            height: 2, //in units
            noOfCols: 3,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Maximum Flight Time", isMandatory: false, keyName: "maxFlightTime", type: "number", width: (1 / 2), validate: validateFunctions },
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "Aerial Distance", isMandatory: false, keyName: "aerialDistance", type: "number", width: (1 / 2), validate: validateFunctions },
                ]
            },
            col_3: {
                width: 1 / 3,
                data: [
                    { label: "Aerial Time", isMandatory: false, keyName: "aerialTime", type: "number", width: (1 / 2), validate: validateFunctions }
                ]
            }
        },
    },
    section_3: {
        label: "Facilities",
        noOfRows: 2,
        row_1: {
            height: 2, //in units
            noOfCols: 2,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Hospital", isMandatory: true, keyName: "hospital", type: "text", width: (2 / 3), validate: validateFunctions },
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "ATC", isMandatory: false, keyName: "ATC", type: "text", width: (1 / 3), validate: validateFunctions }
                ]
            },

        },
        row_2: {
            height: 2, //in units
            noOfCols: 3,
            col_1: {
                width: 1 / 3,
                data: [
                    { label: "Police Station", isMandatory: true, keyName: "policeStation", type: "text", width: (1 / 3), validate: validateFunctions },
                ]
            },
            col_2: {
                width: 1 / 3,
                data: [
                    { label: "Blood Bank", isMandatory: false, keyName: "bloodBank", type: "text", width: (1 / 3), validate: validateFunctions },
                ]
            },
            col_3: {
                width: 1 / 3,
                data: [
                    { label: "FireBrigade", isMandatory: false, keyName: "fireBrigade", type: "text", width: (1 / 3), validate: validateFunctions }
                ]
            }
        },
    }
}




const content = {
    outurl: "/hub/add_hub",
    input: [{ label: "Latitude", key: "latitude", type: "number" },
    { label: "Longitude", key: "longitude", type: "number" },
    { label: "Location Name", key: "location_name", type: "text" },
    { label: "District", key: "district", type: "text" },
    { label: "Full Address", key: "address", type: "text" },
    { label: "Pin code", key: "pin_code", type: "number" },
    { label: "Road Distane (km)", key: "roadDistance", type: "number" },
    { label: "Road Time (hh:mm)", key: "roadTime", type: "string" },
    { label: "State Name", key: "state", type: "string" },
    { label: "Related Hubs", key: "relatedHubs", type: "string" },
    { label: "Tag Name", key: "tag_name", type: "string" },
    { label: "QR Landing", key: "QRLanding", type: "string" },
    { label: "Surface Type", key: "surfaceType", type: "string" },
    { label: "Area Available [sq. ft.]", key: "areaAvailable", type: "string" },
    { label: "Network Availability", key: "networkAvailability", type: "string" },
    { label: "Storage Facility Type and Capacity", key: "storageFacilityTypeandCapacity", type: "string" },
    { label: "Charging Facility Types", key: "chargingFacilityTypes", type: "string" },
    { label: "Electricity Timings", key: "electricityTimings", type: "string" },
    { label: "Shades For Pilots", key: "shadesForPilots", type: "string" },
    { label: "Compatible Drones", key: "compatibleDrones", type: "string" },
    { label: "Potential Use Cases", key: "potentialUseCases", type: "string" },
    { label: "Nearest Facilities", key: "nearestFacilities", type: "string" },
    { label: "Maximum Flight Time", key: "maxFlightTime", type: "string" },
    { label: "Aerial Distance", key: "aerialDistance", type: "string" },
    { label: "Aerial Time", key: "aerialTime", type: "string" },
    { label: "Hospital", key: "hospital", type: "string" },
    { label: "ATC", key: "ATC", type: "string" },
    { label: "Police Station", key: "policeStation", type: "string" },
    { label: "Blood Bank", key: "bloodBank", type: "string" },
    { label: "FireBrigade", key: "fireBrigade", type: "string" },

    ],
    drop_down: [{ label: "Country", key: "country_id", inurl: "/country/get-country" },
    { label: "Zone", key: "zone_id", inurl: "/zone/get_zone" },
    { label: "State", key: "state_id", inurl: "/state/get_state" },
    { label: "Nearest Hub", key: "hub_id", inurl: "/hub/getHub" }]
}