import React, { useRef } from "react";
import "./OrderCancellationPopup.scss";

function OrderCancellationPopup({ setOpenModal, content, pageTitle }) {
  const modalRef = useRef();

  const closeModal = ()=> {
      setOpenModal(false);
  };


  return (
    <div className="CustomPopUpTempleteBackground">
      <div className="CustomPopUpTempleteContainer" >
        {pageTitle !== undefined && <div className="CustomPopUpTempleteContainer__title">{pageTitle}</div>}
        <div className="CustomPopUpTempleteContainer__msg">{content}</div>
        <button className="CustomPopUpTempleteContainer__close" onClick={closeModal} >X</button>
      </div>
    </div>

  );
}

export default OrderCancellationPopup;
