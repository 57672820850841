import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";


import "./HomePage.scss";
import AttitudeInstrument from "./AttitudeInstrument";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomAlertBox from "./CustomAlert/CustomAlertBox";
import LocationDropDown from "./LocationDropDown/LocationDropDown";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import InfoIndicatorBox from "./InfoIndicatorBox/InfoIndicatorBox";
import io from "socket.io-client";
import axios from "axios";
import { backEndIP, clientSocketIP, copyRightTitle } from "./MyConfiguration";
import VideoPlayer from "./videoPlayer/VideoPlayer";
import { GMapMissionPlanning } from "./GMapMissionPlanning";
import MissionPlanPointRow from "./MissionPlanPointRow/MissionPlanPointRow";
import { commandMacroNames, commandMacroNumbers } from "./commands";
import ImportExportBtn from "./ImportExportBtn/ImportExportBtn";
import MissionPointsViewer from "./MissionPointsViewer/MissionPointsViewer";
import { mapOptions } from "./MyConfiguration";
// import StandardButton from "./customButtons/StandardButton";
import CustomNotification from "./CustomNotification/CustomNotification";
import ChecklistInputForm from "./ChecklistInputForm/ChecklistInputForm";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import { getTotalDistance, logoutAction } from "./utility";
import { selectHubNodeLocations, selectHubNodeLocationsLoading, setHubNodeLocations } from "../ReduxStore/HubNodeLocationsSlice";
import { useDispatch, useSelector } from "react-redux";
import MissionPlanIcon from "../MissionPlanIcon";
import MonitorDroneIcon from "./MonitorDroneIcon";
import ReactSlider from "react-slider";
import { Avatar, Dropdown } from "rsuite";
// https://ws5002.dineshborse.in
// const socket = io("http://192.168.0.91:5002", {
//     autoConnect: false,
//     transports: ["websocket", "polling"],
//     extraHeaders: {
//         "token": userCookie.token
//       }
// });
const getLatLongStr = (num) => {

    let numStr = num.toString();
    // console.log("getLatLongStr prev", numStr)
    numStr = numStr.split(".").join("");

    // const len =numStr.length;
    // Number((num * Math.pow(10, -7)).toFixed(7))
    const len = numStr.length
    for (let i = 0; i < 9 - len; i++) {
        console.log("add", i, len, numStr)
        numStr += "0"
    }

    numStr = numStr.split("")
    numStr.splice(2, 0, '.')
    numStr = numStr.join("")
    // console.log("getLatLongStr after", numStr)
    return numStr
}

const HomePage = ({ userCookie, setuserCookie, removeCookie, themeGlobal }) => {

    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [lastPong, setLastPong] = useState(null);
    const [socket, setSocket] = useState(io(clientSocketIP, {
        autoConnect: false,
        transports: ["websocket", "polling"],
        auth: {
            token: userCookie.token,
            page: "monitor",
        },
    }))
    const dispatch = useDispatch()
    const [droneInfo, setDroneInfo] = useState({});
    const [isConnected, setIsConnected] = useState(false);
    // const DroneInfoKeys = ["mode", "roll", "pitch", "yaw", "heading", "lat",
    //     "long", "alt"];
    const [checklistToggle, SetChecklistToggle] = useState(false);
    const [changeChecklistKey, SetChangeChecklistKey] = useState("");
    const themesAvailable = {
        light: {
            "--primaryColor": "white",
            "--secondaryColor": "#F2F2F2",
            "--primaryTextColor": "#08030A",
            "--secondaryTextColor": "#e2e2e2",
        },
        dark: {
            "--primaryColor": "#000",
            "--secondaryColor": "#0B0B0B",
            "--primaryTextColor": "white",
            "--secondaryTextColor": "#e2e2e2",
        },
    };
    const modalRef = useRef();
    // const themeSelected = useContext(ThemeContext);
    const [ConnectionStatus, setConnectionStatus] = useState(false);
    const [ConnectingSpinner, setConnectingSpinner] = useState(false);
    const [ConnectBtnString, setConnectBtnString] = useState("Connect Server");
    const [currentMessage, setCurrentMessage] = useState(
        "For starting Please connect to Drone"
    );
    const [loadingVisibility, setLoadingVisibility] = useState(false);
    const [customAlertVisibility, setCustomAlertVisibility] = useState(false);
    const [alertBoxMesage, setAlertBoxMessage] = useState({
        message: "",
        func: () => { },
    });
    const [notificationVisibility, setNotificationVisibility] = useState(false);
    const [messageToDisplay, setMessageToDisplay] = useState({
        type: "notify",
        message: "msg",
        msgtype: "success",
        func: () => { },
    });
    const [isAborted, setIsAborted] = useState(true);

    // const [dimen, setDimen] = useState({ height: window.innerHeight, width: window.innerHeight })

    const [startLocation, setStartLocation] = useState("HUB");
    // const [SelectedDrone, setSelectedDrone] = useState("");
    const [endLocation, setEndLocation] = useState("HUB");
    const [routePoints, SetRoutePoints] = useState([]);
    const [centerToDrone, SetCenterToDrone] = useState(false);
    const [fitRoute, setFitRoute] = useState(false);
    const [gcsLog, setGCS_Log] = useState([]);
    const [updateSelf, setUpdateSelf] = useState(false);
    const [armStatus, setArmStatus] = useState(false);
    // const [checklistDone, setChecklistDone] = useState(true);
    const [preFlightChecklistItems, setPreFlightChecklistItems] = useState([]);
    const [postFlightChecklistItems, setPostFlightChecklistItems] = useState([]);
    const [flightData, setFlightData] = useState({});
    const [userInitial, setUserInitial] = useState("");
    const [preChecklistUpdateObj, setPreChecklistUpdateObj] = useState({});
    const [isPreChecklistChanged, setIsPreChecklistChanged] = useState(false)
    const [showSubmitPreChecklistDone, setShowSubmitPreChecklistDone] = useState(false)
    const [showSubmitPostChecklistDone, setShowSubmitPostChecklistDone] = useState(false)
    const [postChecklistUpdateObj, setPostChecklistUpdateObj] = useState({});
    const [isPostChecklistChanged, setIsPostChecklistChanged] = useState(false)
    // const [nameLogo, setNameLogo] = useState('TE-logoName.svg')

    // const [EagleLogo, SetEagleLogo] = useState('TechEagle_Logo.svg')

    // const [theme, setTheme] = useState(-1)
    // const MovingRingRef = useRef();

    const [allLocations, setAllLocations] = useState([]);
    const hubNodeLocationsLoadingState = useSelector(selectHubNodeLocationsLoading)
    const hubNodeLocations = useSelector(selectHubNodeLocations);

    const handlePreChecklistSaveClick = (type) => {
        if (type === "Pre-Flight") {
            socket.emit("client:updatePreFlightChecklistItems", preChecklistUpdateObj)
        }
        if (type === "Post-Flight") {
            socket.emit("client:updatePostFlightChecklistItems", postChecklistUpdateObj)
        }
    }

    const handleMarkChecklistDone = (type) => {
        if (type === "Pre-Flight") {
            const myFlag = preFlightChecklistItems.every(item => item.confirm)
            if (myFlag) { socket.emit("client:updatePreFlightChecklistDone", "") }
            else {
                handleUpdateAlertNotifyMsg(
                    "alert",
                    `Please Complete checklist before submitting`,
                    "failed",
                    () => { }
                );
            }
        }
        if (type === "Post-Flight") {
            const myFlag = postFlightChecklistItems.every(item => item.confirm)
            if (myFlag) { socket.emit("client:updatePostFlightChecklistDone", "") }
            else {
                handleUpdateAlertNotifyMsg(
                    "alert",
                    `Please Complete checklist before submitting`,
                    "failed",
                    () => { }
                );
            }
        }
    }

    useEffect(() => {
        setLoadingVisibility(true)
        axios({
            url: backEndIP + "/admin/verifytoken",
            // url: backEndIP + "/user/login",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then(async (loginData) => {
            await setuserCookie("token", loginData.data.token, { path: "/" })
            await setuserCookie("username", loginData.data.userName, { path: "/" })
            await setuserCookie("useremail", loginData.data.userEmail, { path: "/" })
            await setuserCookie("designation", loginData.data.designation, { path: "/" })
            await setuserCookie("accessRole", loginData.data.accessRole, { path: "/" })
            SetassignedDroneId(loginData.data.droneAssigned)
            SetDroneFlightId(loginData.data.flightId)
            setFlightData(loginData.data.flightData)
            console.log("Flightinfo =", loginData.data.flightId, loginData.data.flightData)
            setUserInitial(loginData.data.userName[0].toLocaleUpperCase())
            setLoadingVisibility(false)
            // if (loginData.data.flightId !== "") {
            //     socket.emit("client:getPreFlightChecklist", loginData.data.flightId)

            // }
            if (loginData.data.userEmail.split("@")[1] === "techeagle.in") {
                // navigate("/monitor")
            }
            else {
                socket.off();
                socket.close();
                navigate("/view-flight")
            }
        }).catch(async (err) => {
            // window.alert("login again")
            setLoadingVisibility(false)
            console.log(err);
            socket.off();
            socket.close();
            await removeCookie("token");
            await removeCookie("username");
            await removeCookie("useremail");
            await removeCookie("designation");
            await removeCookie("accessRole");
            navigate("/")
        })
    }, [])

    useEffect(() => {
        const hub_id = flightData?.hub_id;

        if (hub_id) {
            const newAllLocations = []
            Object.keys(hubNodeLocations)?.forEach(loc_id => {
                // if (hubNodeLocations[loc_id].tag === "HUB" && loc_id === hub_id) {
                //     newAllLocations.push("HUB")
                // }
                // else 
                if (hubNodeLocations[loc_id].hub_id === hub_id) {
                    const number = parseInt(hubNodeLocations[loc_id].tag.slice(1))
                    newAllLocations.push(number)

                }
            });
            newAllLocations.sort((a, b) => a - b)

            newAllLocations.forEach((loc, i) => {
                newAllLocations[i] = "L" + loc;
            });
            newAllLocations.unshift("HUB")
            // console.log("newAllLocations", newAllLocations)
            setAllLocations(newAllLocations);
            setStartLocation("HUB")
            setEndLocation("HUB")
        }
    }, [flightData])

    useEffect(() => {
        if (hubNodeLocationsLoadingState === "idle") {
            axios({
                url: backEndIP + "/location/hub-node-all-locations",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((respHubNodeLocations) => {
                console.log("respHubNodeLocations=", respHubNodeLocations.data)
                dispatch(setHubNodeLocations(respHubNodeLocations.data.data))
            }).catch((err) => {
                console.log(err);

            })
        }
    }, [hubNodeLocationsLoadingState])

    useEffect(() => {
        if (preFlightChecklistItems) {
            const myFlag = preFlightChecklistItems.every(item => item.confirm)
            setShowSubmitPreChecklistDone(myFlag)
        }
    }, [preFlightChecklistItems])

    useEffect(() => {
        if (postFlightChecklistItems) {
            const myFlag = postFlightChecklistItems.every(item => item.confirm)
            setShowSubmitPostChecklistDone(myFlag)
        }
    }, [postFlightChecklistItems])


    const handleUpdateAlertNotifyMsg = (type, message, msgtype, func) => {
        setMessageToDisplay({
            type: type,
            message: message,
            msgtype: msgtype,
            func: () => {
                func();
            },
        });
        if (type === "notify") {
            setNotificationVisibility(true);
        } else if (type === "alert") {
            setNotificationVisibility(true);
        }
    };







    useEffect(() => {
        let TimerID
        if (isAborted) {
            clearInterval(abortTimerID.timer_id);
            setTimerCount((prev) => { return 10 });
            setAbortBoxVisibility(false);
            setCustomAlertVisibility(false);
        }
        else {
            TimerID = setInterval(() => {
                setTimerCount((prev) => {
                    console.log(prev);
                    if (prev === 0) {
                        clearInterval(abortTimerID.timer_id);
                        setAbortBoxVisibility(false);
                        setIsAborted(true)
                        if (droneFlightId !== null) { socket.emit("client:launch_mission"); }
                        return 10;
                    }
                    return prev - 1;
                });
            }, 1000);
            abortTimerID.timer_id = TimerID;
            setAbortBoxVisibility(true);
            setCustomAlertVisibility(false);
        }

        return () => {
            clearInterval(TimerID);
        }

    }, [isAborted])




    // const AllDrones = ["01", "02", "03"];
    const navigate = useNavigate();

    const [abortBoxVisibility, setAbortBoxVisibility] = useState(false);
    const [timerCount, setTimerCount] = useState(10);
    const [assignedDroneId, SetassignedDroneId] = useState("Unassigned")
    const [droneFlightId, SetDroneFlightId] = useState(null)
    const abortTimerID = { timer_id: "" };



    const RefLogBox = useRef();

    useEffect(() => {
        RefLogBox.current.scrollIntoView({ behavior: "smooth" });
    }, [gcsLog.length]);


    let peerConnection;
    const peerConnectionConfig = {
        iceServers: [
            {
                "urls": "stun:stun.l.google.com:19302",
            },
        ]
    };
    // const [videoRef, SetVideoRef] = useState(null);
    const videoRef = useRef();
    useEffect(() => {
        if (hubNodeLocationsLoadingState === "idle") {
            axios({
                url: backEndIP + "/location/hub-node-all-locations",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((respHubNodeLocations) => {
                console.log("respHubNodeLocations=", respHubNodeLocations.data)
                dispatch(setHubNodeLocations(respHubNodeLocations.data.data))
            }).catch((err) => {
                console.log(err);

            })
        }
    }, [hubNodeLocationsLoadingState])
    useEffect(() => {
        socket.on("connect", () => {
            setIsConnected(true);
            socket.emit("upgrade");
            socket.emit("client:get_route")
            socket.emit("client:getFlightData")
            // setConnectBtnString("Disconnect");
            gcsLog.push(
                [new Date().toTimeString().split("(")[0], "connected to server"]
                // `${new Date().toTimeString().split("(")[0]}: ${"cconnect dronnected to server"}`
            );
            setUpdateSelf(prev => !prev)
            socket.emit("watcher");
            console.log(`socket.emit("watcher");`)

        });


        socket.on("pong", () => {
            setLastPong(new Date().toISOString());
        });
        socket.on("server:monitor_data", (data) => {
            // console.log(data);
            // if (checklistDone) {
            setDroneInfo(data);
            // }
        });
        socket.on("server:set_route", (data) => {
            console.log("server:set_route", data);
            // SetRoutePoints([])
            SetRoutePoints((prev) => { return [] })
            SetRoutePoints((prev) => { return data })
        });
        socket.on("server:gcs_log", (data) => {

            gcsLog.push([new Date().toTimeString().split("(")[0], data]
                // `${new Date().toTimeString().split("(")[0]}: ${data}`
            );
            setUpdateSelf(prev => !prev)
            // setGCS_Log([...gcsLog])
            // gcsLog.push(data.split(":"));
        });
        socket.on("server:fetch_mission", (data) => {
            // currentMissionPlanRoutePoints.push({ lat: point.lat, lng: point.long });
            console.log("server:fetch_mission =", data)
            readMissionFile(data)
            // // let temparr ={};
            // data.forEach((item) => {
            //     item.cmd = commandMacroNames[item.cmd]
            // })
            // console.log(data)
            // setCurrentMissionPlan([...data]);
            // let routePoints = []
            // data.forEach((point => {
            //     routePoints.push({ lat: point.lat, lng: point.long })
            // }))
            // setCurrentMissionPlanRoutePoints(routePoints);

        });

        socket.on("server:flight_Completed", (data) => {
            console.log("server:flight_Completed =", data)
            setMessageToDisplay({
                type: "alert",
                msgtype: "success",
                message: "Hurray!! You have successfully completed the flight",
                func: () => {
                    setNotificationVisibility(false);
                    navigate('/fleetmanagement')
                },
            });
            setNotificationVisibility(true);
        });


        socket.on("server:arm_status", (data) => {
            setArmStatus(true);
            console.log(data)
        })
        socket.on("broadcaster", () => {
            socket.emit("watcher");
        });
        socket.on("offer", (id, description) => {
            peerConnection = new RTCPeerConnection(peerConnectionConfig);
            peerConnection
                .setRemoteDescription(description)
                .then(() => peerConnection.createAnswer())
                .then(sdp => peerConnection.setLocalDescription(sdp))
                .then(() => {
                    socket.emit("answer", id, peerConnection.localDescription);
                });
            peerConnection.ontrack = event => {
                videoRef.current.srcObject = event.streams[0];
                // SetVideoRef(event.streams[0]);
            };
            peerConnection.onicecandidate = event => {
                if (event.candidate) {
                    socket.emit("candidate", id, event.candidate);
                }
            };
        });
        socket.on("candidate", (id, candidate) => {
            peerConnection
                .addIceCandidate(new RTCIceCandidate(candidate))
                .catch(e => console.error(e));

            console.log(candidate)
        });
        socket.on("server:setPreFlightChecklistItems", (data) => {
            if (data.length) {
                setPreFlightChecklistItems(data);
                flightData.preFlightChecklist = data
                setIsPreChecklistChanged(false);
                setPreChecklistUpdateObj({});
            }
        });
        socket.on("server:setPostFlightChecklistItems", (data) => {

            if (data.length) {
                setPostFlightChecklistItems(data);
                flightData.postFlightChecklist = data
                setIsPostChecklistChanged(false);
                setPostChecklistUpdateObj({});
            }
        });

        socket.on("server:setFlightId", (data) => {
            console.log("server:setFlightId", data)
            SetDroneFlightId(data.flightId?data.flightId:null);
        });
        socket.on("server:setFlightData", (data) => {
            setFlightData(data)
            setPreFlightChecklistItems(data.preFlightChecklist)
            setPostFlightChecklistItems(data.postFlightChecklist)
            setIsPreChecklistChanged(false);
            setPreChecklistUpdateObj({});
            setIsPostChecklistChanged(false);
            setPostChecklistUpdateObj({});
            setStartLocation(hubNodeLocations[data.start_location])
            setEndLocation(hubNodeLocations[data.start_location])
            console.log(data)
        })

        socket.on("disconnect", () => {
            setIsConnected(false);
            gcsLog.push(
                [new Date().toTimeString().split("(")[0], "disconnected from server"]
                // `${new Date().toTimeString().split("(")[0]}: ${"disconnected from server"}`
            );
            setUpdateSelf(prev => !prev)
            peerConnection?.close();

        });

        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("pong");
            socket.off("server:monitor_data");
            socket.off("server:set_route");
            socket.off("server:gcs_log");
            socket.off("broadcaster");
            socket.off("offer");
            socket.off("candidate");
            socket.off("server:fetch_mission");
            socket.off("server:setPreFlightChecklistItems");
            socket.off("server:setPostFlightChecklistItems");
            socket.off("server:setFLightId");
            socket.off("server:setFlightData");
            socket.off("server:flight_Completed");
        };
    }, [socket]);

    useEffect(() => {
        socket.emit("client:getFlightData")
        console.log("droneFlightId =", droneFlightId)
    }, [droneFlightId])

    const handleRTLBtnClick = () => {
        fetch(backEndIP + "/rtl-ec645b6577c7135ab7ebe510ed45f0690b")
            // fetch(backEndIP + '/monitor')         //api for the get request
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            });
        setCustomAlertVisibility(false);
        // if (droneFlightId !== null) {
        socket.emit("client:set_rtl");
        // }
    };
    const handleSetLocationsClick = () => {
        if (startLocation !== endLocation) {
            console.log(`${startLocation}-${endLocation}`);
            setAlertBoxMessage({
                message: `please confirm Origin : ${startLocation} and Destination : ${endLocation}?`,
                func: () => {
                    setCustomAlertVisibility(false);
                    if (!droneInfo.arm_status) {
                        socket.emit(
                            "client:upload_location",
                            `${startLocation}-${endLocation}`
                        );
                    }
                },
            });
            setCustomAlertVisibility(true);
        } else {
            if (droneInfo?.arm_status) {
                handleUpdateAlertNotifyMsg(
                    "alert",
                    `New mission cannot be uploaded while ongoing flight and when Drone is Armed`,
                    "failed",
                    () => { }
                );
            }
            else {
                setAlertBoxMessage({
                    message: "Please make sure launch and Landing Locations are different",
                    func: () => {
                        setCustomAlertVisibility(false);
                    },
                });
                setCustomAlertVisibility(true);
            }
        }
    };

    const handleConnectClick = (e) => {
        setConnectingSpinner(true);
        // console.log(e.currentTarget.style)
        if (isConnected) {
            // setConnectBtnString("Dro");
            socket.disconnect();
        }
        else {
            setConnectBtnString("Connecting");
            socket.connect();
        }

    };


    const handleArmDroneClick = () => {
        socket.emit("client:set_arm")
        setCustomAlertVisibility(false);
    };
    const handleMarkMIssionCompleted = () => {
        socket.emit("client:markMissionCompletedFromClient")
        setCustomAlertVisibility(false);
    }
    const handleDisarmDroneClick = () => {
        socket.emit("client:set_disarm")
        setCustomAlertVisibility(false);
    };




    const handleReadMissionClick = () => {
        socket.emit("client:get_route")
    }
    const handleReadMissionFileClick = () => {
        socket.emit("client:read-mission");
    }
    const handleUploadMissionClick = () => {
        let MissionPlanToBeSent = [];
        currentMissionPlan.forEach((item) => {
            MissionPlanToBeSent.push({ ...item });
        });
        MissionPlanToBeSent.forEach((item) => {
            item.cmd = commandMacroNumbers[item.cmd]
        })

        // socket.emit("client:upload-mission", MissionPlanToBeSent)
        socket.emit("client:upload-mission", [getWPFileData(), getTotalDistance(currentMissionPlanRoutePoints)])
        setCustomAlertVisibility(false);
    }

    const InfoBoxDataArray = [
        {
            posi: 1,
            label: "Altitude (m)",
            value: `${droneInfo.alt ? droneInfo.alt : "00"}`,
        },
        {
            posi: 2,
            label: "AirSpeed (Km/hr)",
            value: `${droneInfo.a_speed ? droneInfo.a_speed : "00"}`,
        },
        {
            posi: 3,
            label: "GndSpeed (Km/hr)",
            value: `${droneInfo.g_speed ? droneInfo.g_speed : "00"}`,
        },
        {
            posi: 4,
            label: "Voltage (V)",
            value: `${droneInfo.voltage ? droneInfo.voltage : "00"}`,
        },
        {
            posi: 5,
            label: "ETA (mm:ss)",
            value: `${droneInfo.eta ? droneInfo.eta : "00:00"}`,
        },
        {
            posi: 6,
            label: "Distance (Km)",
            value: `${droneInfo.distance ? droneInfo.distance : "00"}`,
        },
        {
            posi: 7,
            label: "Climb rate (m/s)",
            value: `${droneInfo.climb_rate ? droneInfo.climb_rate : "00"}`,
        },
        {
            posi: 8,
            label: "Mode",
            value: `${droneInfo.mode ? droneInfo.mode : "NA"}`,
        },
        {
            posi: 9,
            label: "GPS-fix",
            value: `${droneInfo.gpsfix ? droneInfo.gpsfix : "NA"}`,
        },
    ];


    const [isMissionPlaning, setIsMissionPlaning] = useState(false);
    const [isPreFlightCheck, setIsPreFlightCheck] = useState(false);
    const [isCameraFeedNeeded, setIsCameraFeedNeeded] = useState(false);
    const [elevationGraphVisibility, setelevationGraphVisibility] = useState(false);
    const sendCommand = (command, data = {}) => {
        socket.emit("client:command", { command: command, ...data })
        console.log("sendCommand", command, data)
    }

    const droneCommandExecute = (command, data = {}) => {
        if (command === "takeoff") {

            setAlertBoxMessage({
                message:
                    "Are you sure you want Drone to TakeOff?",
                func: () => {
                    // socket.emit("client:command", { command: "takeoff" })
                    sendCommand("takeoff", data)
                    setCustomAlertVisibility(false);
                },
            });
            setCustomAlertVisibility(true);

        }
        else if (command === "land") {

            setAlertBoxMessage({
                message:
                    "Are you sure you want Drone to Land?",
                func: () => {
                    // socket.emit("client:command", { command: "takeoff" })
                    sendCommand("land", data)
                    setCustomAlertVisibility(false);
                },
            });
            setCustomAlertVisibility(true);

        }
        else if (command === "Pre-Flight Check") {

            setAlertBoxMessage({
                message:
                    "Are you sure you want to Start Pre-Flight Check?",
                func: () => {
                    // socket.emit("client:command", { command: "takeoff" })
                    sendCommand("pre-flight-check", data);
                    setCustomAlertVisibility(false);
                },
            });
            setCustomAlertVisibility(true);

        }
        else if (command === "go_to_location") {
            setAlertBoxMessage({
                message:
                    `Confirm Send Drone to Location: Lat :${data.lat} | Long :${data.lng} | Alt :${data.altitude}`,
                func: () => {
                    // socket.emit("client:command", { command: "go_to_location", ...data })
                    sendCommand("go_to_location", data)
                    setCustomAlertVisibility(false);
                },
            });
            setCustomAlertVisibility(true);

        }
        else if (command === "planMission") {

            setAlertBoxMessage({
                message:
                    "Are you sure you want Load Mission Planner?",
                func: () => {
                    // navigate("/plan-mission")
                    setIsMissionPlaning(true);
                    setCustomAlertVisibility(false);
                },
            });
            setCustomAlertVisibility(true);

        }
        else if (command === "stopPlanning") {

            setAlertBoxMessage({
                message:
                    "Are you sure you want stop Mission Planning?",
                func: () => {
                    // navigate("/plan-mission")
                    setIsMissionPlaning(false);
                    setCustomAlertVisibility(false);
                },
            });
            setCustomAlertVisibility(true);

        }
    }


    const connectWatcher = () => {
        socket.emit("watcher")
    }
    useEffect(() => {
        connectWatcher();
        if (isConnected) {
            setConnectBtnString("Disconnect");
        }
        else {
            setConnectBtnString("Connect Server");
        }
    }, [isConnected])


    const [currentMissionPlan, setCurrentMissionPlan] = useState([]);
    const [selectedWayPoint, setSelectedWayPoint] = useState(null)
    const [currentMissionPlanRoutePoints, setCurrentMissionPlanRoutePoints] = useState([]);

    const getTransitionAngle = (missionPlan) => {
        const i = currentMissionPlan[0];
        const j = currentMissionPlan[1];
        const lat = (j.lat - i.lat);
        const lng = (j.long - i.long);
        let radians = Math.atan2(lng, lat);
        if (radians < 0) { radians += 2 * Math.PI }
        const angle = radians * (180 / Math.PI)
        // console.log(angle)
        i.yaw = parseInt(angle)
        j.yaw = parseInt(0)
        return angle
    }

    const deleteMissionPoint = (index) => {
        const missionplan = [...currentMissionPlan.slice(0, index), ...currentMissionPlan.slice(index + 1)];
        const missionplanRoutePoints = [...currentMissionPlanRoutePoints.slice(0, index), ...currentMissionPlanRoutePoints.slice(index + 1)];
        if (missionplan.length >= 2) {
            getTransitionAngle(missionplan)
        }
        setCurrentMissionPlan(missionplan);
        setCurrentMissionPlanRoutePoints(missionplanRoutePoints)
        setelevationGraphVisibility(false)
    }
    const addMissionPoint = (point) => {
        currentMissionPlan.push(point);
        currentMissionPlanRoutePoints.push({ lat: point.lat, lng: point.long });
        if (currentMissionPlan.length >= 2) {
            getTransitionAngle(currentMissionPlan)
        }
        console.log(point)
        setCurrentMissionPlan([...currentMissionPlan]);
        setCurrentMissionPlanRoutePoints([...currentMissionPlanRoutePoints])
        console.log(currentMissionPlan);
    }
    const updateMissionPoint = (point, index) => {
        currentMissionPlan[index] = point;
        currentMissionPlanRoutePoints[index] = { lat: point.lat, lng: point.long };
        if (currentMissionPlan.length >= 2) {
            getTransitionAngle(currentMissionPlan)
        }
        setCurrentMissionPlan([...currentMissionPlan]);
        setCurrentMissionPlanRoutePoints([...currentMissionPlanRoutePoints])
        console.log(currentMissionPlan);
    }
    const setSelectedMissionWayPoint = (index) => {
        setSelectedWayPoint(index)
    }
    const addMissionPointAt = (point, index) => {
        if (index !== 0) {
            const partA = currentMissionPlan.slice(0, index)
            const partB = currentMissionPlan.slice(index)
            const newRoutePoint = { lat: point.lat, lng: point.long };
            const newcurrentMissionPlan = [...partA, point, ...partB]
            console.log(newcurrentMissionPlan)
            if (newcurrentMissionPlan.length >= 2) {
                getTransitionAngle(newcurrentMissionPlan)
            }
            setCurrentMissionPlan(newcurrentMissionPlan);
            const RpartA = currentMissionPlanRoutePoints.slice(0, index)
            const RpartB = currentMissionPlanRoutePoints.slice(index)
            const newcurrentMissionPlanRoutePoints = [...RpartA, newRoutePoint, ...RpartB]
            // console.log(newcurrentMissionPlanRoutePoints)
            setCurrentMissionPlanRoutePoints([...RpartA, newRoutePoint, ...RpartB])
            // console.log(currentMissionPlan);

        }
    }
    const moveMissionPointUp = (index) => {
        if (index !== 0) {
            let temp = currentMissionPlan[index];
            currentMissionPlan[index] = currentMissionPlan[index - 1];
            currentMissionPlan[index - 1] = temp;
            temp = currentMissionPlanRoutePoints[index];
            currentMissionPlanRoutePoints[index] = currentMissionPlanRoutePoints[index - 1];
            currentMissionPlanRoutePoints[index - 1] = temp;
            if (currentMissionPlan.length >= 2) {
                getTransitionAngle(currentMissionPlan)
            }
            setCurrentMissionPlan([...currentMissionPlan]);
            setCurrentMissionPlanRoutePoints([...currentMissionPlanRoutePoints])
            console.log(currentMissionPlan);
        }
    }
    const moveMissionPointDown = (index) => {
        if (index !== currentMissionPlan.length - 1) {
            let temp = currentMissionPlan[index];
            currentMissionPlan[index] = currentMissionPlan[index + 1];
            currentMissionPlan[index + 1] = temp;
            temp = currentMissionPlanRoutePoints[index];
            currentMissionPlanRoutePoints[index] = currentMissionPlanRoutePoints[index + 1];
            currentMissionPlanRoutePoints[index + 1] = temp;
            if (currentMissionPlan.length >= 2) {
                getTransitionAngle(currentMissionPlan)
            }
            setCurrentMissionPlan([...currentMissionPlan]);
            setCurrentMissionPlanRoutePoints([...currentMissionPlanRoutePoints])
            console.log(currentMissionPlan);
        }
    }

    const [defaultAlt, setDefaultAlt] = useState(100);
    const [waypointRadius, setWaypointRadius] = useState(100);
    const [loiterRadius, setLoiterRadius] = useState(250);
    const [defaultFrame, setDefaultFrame] = useState(3);

    const WPFrames = {
        Absolute: 0,
        Relative: 3
    }
    const waypointFormat = {
        0: 'cmd',
        1: 'p1',
        2: 'p2',
        3: 'p3',
        4: 'yaw',
        5: 'lat',
        6: 'long',
        7: 'alt',
    }

    const readMissionFile = (file) => {
        console.log(file)
        let missionList = file.split('\n')
        console.log(missionList)
        if (missionList[0].includes("QGC WPL 110")) {
            const missionPointsFromFile = [];
            for (let i = 2; i < missionList.length - 1; i++) {
                console.log(missionList[i])
                let missionPointData = missionList[i].split('\t');
                missionPointsFromFile.push({
                    frame: parseInt(missionPointData[2]),
                    cmd: commandMacroNames[parseInt(missionPointData[3])],
                    p1: parseInt(missionPointData[4]),
                    p2: parseInt(missionPointData[5]),
                    p3: parseInt(missionPointData[6]),
                    yaw: parseInt(missionPointData[7]),
                    lat: Number(missionPointData[8]),
                    long: Number(missionPointData[9]),
                    alt: parseInt(missionPointData[10]),

                })
            }
            console.log(missionPointsFromFile)
            const routePoints = [];
            missionPointsFromFile.forEach(((point, i) => {
                // if(point.cmd === "CONDITION_YAW"){
                //     console.log("CONDITION_YAW found")
                //     point.lat=missionPointsFromFile[i-1].lat
                //     point.long=missionPointsFromFile[i-1].long
                // }
                routePoints.push({ lat: point.lat, lng: point.long })
            }))
            setCurrentMissionPlan(missionPointsFromFile);
            setCurrentMissionPlanRoutePoints(routePoints);
        }
        else {
            console.log("invalid file")
        }
    }

    const renderToggle = props => (
        <Avatar circle {...props} src="" style={{ backgroundColor: "#FD6125" }} />
    );

    const getWPFileData = () => {
        if (currentMissionPlan.length === 0) { return -1 }
        let wayPointsFileStr = "QGC WPL 110\n"
        wayPointsFileStr += '0\t' + '1\t' + '0\t';
        wayPointsFileStr += 16 + "\t"
        for (let i = 0; i < 4; i++) {
            wayPointsFileStr += 0 + "\t";
        }
        wayPointsFileStr += getLatLongStr(currentMissionPlan[0].lat) + "\t";
        wayPointsFileStr += getLatLongStr(currentMissionPlan[0].long) + "\t";
        wayPointsFileStr += 0 + "\t";

        wayPointsFileStr += 1 + "\n";

        currentMissionPlan.forEach((item, index) => {
            // if (index === 0) {
            //     wayPointsFileStr += index + '\t' + '1\t' + '0\t';
            //     wayPointsFileStr += commandMacroNumbers[item.cmd] + "\t"
            //     for (let i = 1; i < 8; i++) {
            //         wayPointsFileStr += item[waypointFormat[i]] + "\t";
            //     }
            //     wayPointsFileStr += 1 + "\n";
            // }
            // else {
            wayPointsFileStr += (index + 1) + '\t' + '0\t' + `${item.frame}\t`;
            wayPointsFileStr += commandMacroNumbers[item.cmd] + "\t"
            for (let i = 1; i < 8; i++) {
                if (i === 5 || i === 6) {
                    wayPointsFileStr += getLatLongStr(item[waypointFormat[i]]) + "\t";
                }
                else
                    wayPointsFileStr += item[waypointFormat[i]] + "\t";
            }
            wayPointsFileStr += 1 + "\n";
            // }

        })
        console.log(wayPointsFileStr)
        return wayPointsFileStr
    }

    const navIcons = {
        FPC: { black: "./monitor-drone.png", white: "./monitor-drone-white.png" },
        MP: { black: "./missionPlanIcon.png", white: "./missionPlanIconwhite.png" }
    }

    const [zoomLevel, setZoomLevel] = useState(50)

    const handleCalibrateAirspeedNBarometer = () => {
        socket.emit("client:calibrate-airspeed-baro", true)
        setCustomAlertVisibility(false);
    }

    return (
        <div className="home-page-container">
            {!isMissionPlaning && <div className="home-sidebar">
                <div className="home-sidebar__logodiv">
                    <img
                        className="home-sidebar__logodiv__logoimg"
                        src={themeGlobal.nameLogo}
                        alt="logo"
                        onClick={() => { navigate("/dashboard") }}
                    />
                    <button className="home-sidebar__logout"
                        onClick={() => {
                            setAlertBoxMessage({
                                message:
                                    "Are you sure you want Logout?",
                                func: () => { logoutAction(removeCookie, navigate, socket) }
                            });
                            setCustomAlertVisibility(true);
                        }}>
                        Logout
                    </button>
                </div>
                <div className="home-sidebar__logodiv-border"></div>
                <div className="home-sidebar__drone_status">
                    <div className="home-sidebar__drone_status__arm_status" style={{ color: droneInfo.arm_status ? "red" : "green", fontWeight: 600 }}>{droneInfo.arm_status ? "ARMED" : "DISARMED"}</div>
                </div>
                <div className="home-sidebar__DroneName">

                    <div className='home-sidebar__DroneName__droneInfo'>
                        <div className='home-sidebar__DroneName__droneInfo__droneid'>
                            <div className='home-sidebar__DroneName__droneInfo__droneid__title'>Drone Assigned</div>
                            <div className='home-sidebar__DroneName__droneInfo__droneid__value'>{assignedDroneId}</div>
                        </div>
                        <div className='home-sidebar__DroneName__droneInfo__flightid'>
                            <div className='home-sidebar__DroneName__droneInfo__flightid__title'>Flight ID</div>
                            <div className='home-sidebar__DroneName__droneInfo__flightid__value'>{droneFlightId !== null ? droneFlightId : "Null"}</div>
                        </div>
                    </div>
                </div>
                <div className="home-sidebar__infoContainer">
                    {InfoBoxDataArray.map((inf) => {
                        return (
                            <InfoIndicatorBox
                                key={inf.label}
                                infoLabel={inf.label}
                                infoValue={inf.value}
                                _width={"42%"}
                                _height={"42%"}
                            ></InfoIndicatorBox>
                        );
                    })}
                </div>
                <div className="home-sidebar__controlButtons">
                    <button
                        className="home-sidebar__controlButtons__RTL"
                        onClick={() => {
                            setAlertBoxMessage({
                                message:
                                    "Are you sure you want Drone to Return to Launch Location?",
                                func: handleRTLBtnClick,
                            });
                            setCustomAlertVisibility(true);

                        }}
                    >
                        RTL
                    </button>

                    <button
                        className="home-sidebar__controlButtons__RTL"
                        onClick={() => {
                            setAlertBoxMessage({
                                message: `Are you sure you want to ${droneInfo.arm_status ? "DISARM" : "ARM"} Drone?`,
                                func: droneInfo.arm_status ? handleDisarmDroneClick : handleArmDroneClick,
                            });
                            setCustomAlertVisibility(true);

                        }}
                    >
                        ARM/DISARM
                    </button>
                </div>
                <div className="home-sidebar__controlButtons">
                    <button
                        className="home-sidebar__controlButtons__RTL"
                        onClick={() => {
                            if (droneFlightId !== null) {
                                setAlertBoxMessage({
                                    message: "Are you sure you want Launch Mission?",
                                    func: () => {
                                        clearInterval(abortTimerID.timer_id);
                                        setAbortBoxVisibility(true);
                                        setIsAborted(false);
                                        setCustomAlertVisibility(false);
                                    },
                                });
                                setCustomAlertVisibility(true);
                            }
                            else {
                                handleUpdateAlertNotifyMsg(
                                    "alert",
                                    `No Fight Entry Present. Create Flight entry for this drone first`,
                                    "failed",
                                    () => { }
                                );
                            }
                        }}
                    >
                        Launch Mission
                    </button>
                    <button
                        className="home-sidebar__controlButtons__RTL"
                        onClick={() => {
                            if (droneInfo?.arm_status) {
                                handleUpdateAlertNotifyMsg(
                                    "alert",
                                    `Calibration can not be done during flight and when Drone is Armed`,
                                    "failed",
                                    () => { }
                                );
                            }
                            else {
                                setAlertBoxMessage({
                                    message: "Are you sure you want to Do Preflight Calibration?",
                                    func: handleCalibrateAirspeedNBarometer,
                                });
                                setCustomAlertVisibility(true);
                            }
                        }}
                    >
                        Pre-Flight Calibrate
                    </button>
                </div>

                <AttitudeInstrument rol={droneInfo.roll} pit={droneInfo.pitch} />
                <button className="home-sidebar__markFightCompletedInvisible" style={{ width: "20px", height: "20px" }}
                    onClick={() => {
                        setAlertBoxMessage({
                            message: "Are you sure you want to Mark Mission Completed?",
                            func: handleMarkMIssionCompleted,
                        });
                        setCustomAlertVisibility(true);
                    }}>
                </button>
            </div>}
            {isMissionPlaning && <div className="home-sidebar">
                <div className="home-sidebar__logodiv">
                    <img
                        className="home-sidebar__logodiv__logoimg"
                        src={themeGlobal.nameLogo}
                        alt="logo"
                        onClick={() => { navigate("/dashboard") }}
                    />
                    <button className="home-sidebar__logout"
                        onClick={() => {
                            setAlertBoxMessage({
                                message:
                                    "Are you sure you want Logout?",
                                func: () => { logoutAction(removeCookie, navigate, socket) }
                            });
                            setCustomAlertVisibility(true);
                        }}>
                        Logout
                    </button>

                </div>
                <div className="home-sidebar__logodiv-border"></div>
                <div className="home-sidebar__drone_status">
                    <div className="home-sidebar__drone_status__arm_status" style={{ color: droneInfo.arm_status ? "red" : "green", fontWeight: 600 }}>{droneInfo.arm_status ? "ARMED" : "DISARMED"}</div>
                </div>
                <div className="home-sidebar__DroneName">
                    <div>VERTIPLANE X3</div>
                    <ImportExportBtn getWPFileData={getWPFileData} readMissionFile={readMissionFile}></ImportExportBtn>
                </div>
                <div className="home-sidebar__standard-data-container">
                    <div className="home-sidebar__standard-data-container__home-location">
                        <div className="home-sidebar__standard-data-container__home-location__title">Home location</div>
                        <div className="home-sidebar__standard-data-container__home-location__latlng">
                            lat:
                            <input style={{ color: "black" }} className="home-sidebar__standard-data-container__home-location__latlng__input" readOnly type={"number"}
                                value={droneInfo.lat !== undefined ? droneInfo.lat : 0}
                            ></input></div>
                        <div className="home-sidebar__standard-data-container__home-location__latlng">
                            lng:<input style={{ color: "black" }} className="home-sidebar__standard-data-container__home-location__latlng__input" readOnly type={"number"}
                                value={droneInfo.long !== undefined ? droneInfo.long : 0}
                            ></input></div>
                    </div>
                    <div className="home-sidebar__standard-data-container__defaults-cont">
                        <div className="home-sidebar__standard-data-container__defaults-cont__title">Default Parameters</div>
                        <div className="home-sidebar__standard-data-container__defaults-cont__allparameters">
                            <div className="home-sidebar__standard-data-container__defaults-cont__alt">
                                Default Alt : <input style={{ color: "black" }} type={"number"} min="0" max="120"
                                    value={defaultAlt}
                                    onChange={(e) => {
                                        setDefaultAlt(parseInt(e.target.value))
                                    }}
                                ></input>

                            </div>
                            <div className="home-sidebar__standard-data-container__defaults-cont__alt">
                                WP Radius : <input style={{ color: "black" }} className="home-sidebar__standard-data-container__defaults-cont__input" type={"number"} min="50"
                                    value={waypointRadius}
                                    onChange={(e) => {
                                        setWaypointRadius(parseInt(e.target.value))
                                    }}
                                ></input>

                            </div>
                            <div className="home-sidebar__standard-data-container__defaults-cont__alt">
                                Loiter Radius : <input style={{ color: "black" }} className="home-sidebar__standard-data-container__defaults-cont__input" type={"number"} min="50"
                                    value={loiterRadius}
                                    onChange={(e) => {
                                        setLoiterRadius(parseInt(e.target.value))
                                    }}
                                ></input>

                            </div>
                            <div className="home-sidebar__standard-data-container__defaults-cont__alt">
                                Frame : <select style={{ color: "black" }}
                                    defaultValue={defaultFrame}
                                    onChange={(e) => {
                                        setDefaultFrame(parseInt(e.target.value))
                                    }}>
                                    {["Absolute", "Relative"].map((item) => {
                                        return <option key={`${item}wpframes`} value={WPFrames[item]}>{item}</option>
                                    })}

                                </select>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-sidebar__MissionPoints">
                    <div className="home-sidebar__MissionPoints__title"> Mission Points</div>

                    <MissionPointsViewer currentMissionPlan={currentMissionPlan} missionPlanner={{
                        deletePoint: deleteMissionPoint,
                        updatePoint: updateMissionPoint,
                        movePointUp: moveMissionPointUp,
                        movePointDown: moveMissionPointDown,
                        setSelectedWayPoint: setSelectedMissionWayPoint,
                        selectedWayPoint: selectedWayPoint,
                        defaultAlt: defaultAlt,
                    }} />
                </div>
            </div>}


            <div className="home-main-map">
                <div className="home-main-map__topInfobar">

                    <div className="home-main-map__topInfobar__connectBtn-container">
                        <button
                            className="home-main-map__topInfobar__connectBtn-container__connectBtn"
                            onClick={handleConnectClick}
                        // style={{backgroundColor:"#e82222"}}
                        >
                            {ConnectBtnString}
                        </button>
                        <div>VERTIPLANE X3</div>
                    </div>
                    <div className="home-main-map__topInfobar__instuctionNStatus">
                        <div
                            className="home-main-map__topInfobar__instuctionNStatus__message"
                        // ref={RefLogBox}
                        >
                            {gcsLog.length !== 0 &&
                                gcsLog.map((msg, i) => {
                                    return <div key={`gcsLog${i}`}>
                                        <span style={{ color: "#FD6125" }}><strong>{msg[0]}</strong></span>
                                        <span>: {msg[1]}</span>
                                    </div>;
                                })}
                            <div ref={RefLogBox}></div>
                        </div>
                    </div>

                    {!isMissionPlaning && <div className="home-main-map__topInfobar__SelectLocations-container">
                        <div className="home-main-map__topInfobar__SelectLocations-container__locations">
                            <LocationDropDown
                                key={`loc-origin`}
                                AllLocations={allLocations}
                                setLocation={setStartLocation}
                                location={startLocation}
                                label={"Origin : "}
                            />
                            <LocationDropDown
                                key={`loc-desti`}
                                AllLocations={allLocations}
                                setLocation={setEndLocation}
                                location={endLocation}
                                label={"Destination : "}
                            />
                        </div>
                        <div className="home-main-map__topInfobar__SelectLocations-container__buttons">
                            <button
                                className="home-main-map__topInfobar__SelectLocations-container__buttons__btn"
                                onClick={handleSetLocationsClick}
                            >
                                Set Locations
                            </button>
                            <button
                                className="home-main-map__topInfobar__SelectLocations-container__buttons__btn"
                                onClick={handleReadMissionClick}
                            >
                                Read Mission
                            </button>
                        </div>
                    </div>}
                    {isMissionPlaning && <div className="home-main-map__topInfobar__SelectLocations-container">
                        <div className="home-main-map__topInfobar__SelectLocations-container__buttons">
                            <button
                                className="home-main-map__topInfobar__SelectLocations-container__buttons__missionbtn"
                                // onClick={handleUploadMissionClick}
                                onClick={() => {
                                    setAlertBoxMessage({
                                        message: "Are you sure you want to Upload Mission?",
                                        func: handleUploadMissionClick,
                                    });
                                    setCustomAlertVisibility(true);

                                }}
                            >
                                Upload Mission
                            </button>
                            <button
                                className="home-main-map__topInfobar__SelectLocations-container__buttons__missionbtn"
                                onClick={handleReadMissionFileClick}
                            >
                                Read Mission
                            </button>
                        </div>
                    </div>}

                    <div className="home-main-map__topInfobar__user">
                        {/* <div className="home-main-map__topInfobar__user__proImg"
                            onClick={() => {
                                socket.off();
                                socket.close();
                                navigate("/adminpanel")
                            }}>{userInitial}</div> */}
                             <div className='home-main-map__topInfobar__user__proImg'>
                        <Dropdown renderToggle={renderToggle}
                        // style={{ position: 'absolute', top: '0px', right: '10px' }}
                        placement="bottomEnd" 
                        >
                            <Dropdown.Item panel style={{ padding: 0, width: 100 }}>
                                {/* <p>Signed in as</p> */}
                                {/* <strong>{userCookie.username}</strong> */}
                            </Dropdown.Item>
                            {/* <Dropdown.Separator /> */}
                            <Dropdown.Item>{`Your profile`}</Dropdown.Item>
                            {/* <Dropdown.Item>Your stars</Dropdown.Item>
                        <Dropdown.Item>Your Gists</Dropdown.Item> */}
                           <Dropdown.Item
                           onClick={() => {
                            setAlertBoxMessage({
                                message:
                                    "Are you sure you want to navigate to the Clearsky Training Portal?",
                                func: () => {
                                    setCustomAlertVisibility(false);
                                    // window.location.href = "https://training.clearsky.techeagle.org/";
                                    window.open("https://training.clearsky.techeagle.org/", "_blank");
                                    }
                            });
                            setCustomAlertVisibility(true);
                        }}
                           >Get Training</Dropdown.Item>
                            <Dropdown.Separator />
                            <Dropdown.Item><a href="https://forms.gle/PdXdQM5Lfnxv5Bc3A" target='blank' className='supportlink'>Support</a></Dropdown.Item>
                            {/* <Dropdown.Item>Settings</Dropdown.Item> */}
                            <Dropdown.Item onClick={() => {
                                setAlertBoxMessage({
                                    message:
                                        "Are you sure you want Logout?",
                                    func: () => { logoutAction(removeCookie, navigate, socket) }
                                });
                                setCustomAlertVisibility(true);
                            }}>Log Out</Dropdown.Item>
                        </Dropdown>
                        {/* <div className='DashboardPage-container__header__userIcon__username'>{userCookie.username}</div> */}
                    </div>
                    </div>
                </div>
                <div className="home-main-map__GoogleMapContainer">
                    <GMapMissionPlanning
                        zoomLevel={zoomLevel}
                        setZoomLevel={setZoomLevel}
                        routePoints={routePoints}
                        droneLat={droneInfo.lat}
                        droneLong={droneInfo.long}
                        heading={droneInfo.heading}
                        centerToDrone={centerToDrone}
                        fitRoute={fitRoute}
                        hubNodeLocations={hubNodeLocations}
                        SetCenterToDrone={SetCenterToDrone}
                        PageOpen={{ name: "misson-plan", cmdFunc: droneCommandExecute }}
                        isConnected={isConnected}
                        isMissionPlaning={isMissionPlaning}
                        missionPlanner={{
                            mission: currentMissionPlan,
                            deletePoint: deleteMissionPoint,
                            addPoint: addMissionPoint,
                            updatePoint: updateMissionPoint,
                            setSelectedWayPoint: setSelectedMissionWayPoint,
                            addPointAt: addMissionPointAt,
                            selectedWayPoint: selectedWayPoint,
                            defaultAlt: defaultAlt,
                            defaultFrame: defaultFrame,
                            loiterRadius: loiterRadius,
                            waypointRadius: waypointRadius
                        }}
                        missionRoutePoints={currentMissionPlanRoutePoints}
                        elevationGraphVisibility={elevationGraphVisibility}
                        setelevationGraphVisibility={setelevationGraphVisibility}
                    />
                    <div className="home-main-map__GoogleMapContainer__navbar">
                        <MonitorDroneIcon width={60} height={60} state={isMissionPlaning} setState={() => { setIsMissionPlaning(false) }} />
                        <MissionPlanIcon width={60} height={60} state={isMissionPlaning} setState={() => { setIsMissionPlaning(true) }} />

                    </div>

                    <button
                        className="home-main-map__GoogleMapContainer__followDroneBtn"
                        onClick={() => {
                            SetCenterToDrone(true);
                        }}
                    >
                        Follow Drone
                    </button>
                    <button
                        className="home-main-map__GoogleMapContainer__ZoomtoRoute"
                        onClick={() => {
                            setFitRoute(prev => !fitRoute);
                        }}
                    >
                        Fit Route
                    </button>
                    {isCameraFeedNeeded && <div className='VideoPlayer-container'
                    >
                        <img className="VideoPlayer-container__refreshIcon-img" src={`${backEndIP}/refreshIcon.png`} alt="refreshIcon"
                            onClick={() => {
                                connectWatcher()
                            }}></img>
                        <video className='VideoPlayer-container__player' autoPlay muted ref={videoRef}></video>
                    </div>}

                    {droneFlightId !== null && flightData.isPreFlightChecklistCompleted === false && (
                        <div className="home-main-map__GoogleMapContainer__checklist">
                            <div className="home-main-map__GoogleMapContainer__checklist__title">
                                <div>Pre-Flight Checklist</div>
                                {isPreChecklistChanged ? <button className="home-main-map__GoogleMapContainer__checklist__title__saveBtn"
                                    onClick={() => { handlePreChecklistSaveClick("Pre-Flight") }}>
                                    Save
                                </button> : null}
                            </div>
                            <div className="home-main-map__GoogleMapContainer__checklist__innerbox">
                                <ChecklistInputForm socket={socket}
                                    checklistItems={preFlightChecklistItems}
                                    url={"client:preFlightUpdate"}
                                    setIsChanged={setIsPreChecklistChanged}
                                    checklistUpdateObj={preChecklistUpdateObj}
                                    isEditable={true} />
                            </div>
                            {showSubmitPreChecklistDone ?
                                <button className="home-main-map__GoogleMapContainer__checklist__completeBtn"
                                    onClick={() => { handleMarkChecklistDone("Pre-Flight") }}>
                                    Mark Checklist Completed</button> : null}
                        </div>
                    )}
                    {droneFlightId !== null && flightData.isCompleted && flightData.isPostFlightChecklistCompleted === false && (
                        <div className="home-main-map__GoogleMapContainer__checklist">
                            <div className="home-main-map__GoogleMapContainer__checklist__title">
                                <div>Post-Flight Checklist</div>
                                {isPostChecklistChanged ? <button className="home-main-map__GoogleMapContainer__checklist__title__saveBtn"
                                    onClick={() => { handlePreChecklistSaveClick("Post-Flight") }}>
                                    Save
                                </button> : null}
                            </div>
                            <div className="home-main-map__GoogleMapContainer__checklist__innerbox">
                                <ChecklistInputForm socket={socket}
                                    checklistItems={postFlightChecklistItems}
                                    url={"client:postFlightUpdate"}
                                    setIsChanged={setIsPostChecklistChanged}
                                    checklistUpdateObj={postChecklistUpdateObj}
                                    isEditable={true} />
                            </div>
                            {showSubmitPostChecklistDone ?
                                <button className="home-main-map__GoogleMapContainer__checklist__completeBtn"
                                    onClick={() => { handleMarkChecklistDone("Post-Flight") }}>
                                    Mark Checklist Completed</button> : null}
                        </div>
                    )}

                    {/* <div className="home-main-map__GoogleMapContainer__mapZoomSliderTrack"
                    // ref={sliderTrackRef}
                    // onMouseMove={(e) => {
                    //     // const y = e.target.offsetTop
                    //     if (isSliderCLicked) {
                    //         const d = e.target.getBoundingClientRect()
                    //         // console.log(e.target.offsetTop, d, e.clientY, isSliderCLicked)
                    //         const thumbHeight = sliderTrackRef.current.getBoundingClientRect().height;
                    //         if (e.clientY - d.top > d.height - thumbHeight) {
                    //             setSliderPosi(d.height - thumbHeight)
                    //             setZoomLevel(100)
                    //         }
                    //         else {
                    //             setSliderPosi(e.clientY - d.top)
                    //             setZoomLevel(((e.clientY - d.top) / (d.height - thumbHeight)) * 100)
                    //         }
                    //     }
                    // }}
                    >
                        <div className="home-main-map__GoogleMapContainer__mapZoomSliderThumb"
                            ref={sliderTrackRef}
                            style={{ position: "relative", top: `${sliderPosi}px` }}
                            onMouseDown={() => { setIsSliderCLicked(true) }}
                        ></div>

                        
                    </div> */}
                    <ReactSlider
                        className="vertical-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        defaultValue={50}
                        renderThumb={(props, state) => <div {...props}></div>}
                        orientation="vertical"
                        invert
                        pearling
                        minDistance={10}
                        value={zoomLevel}
                        onChange={(val) => { setZoomLevel(val) }}
                        step={0.25}
                    />

                </div>
                <div className="home-main-map__footer-container">
                    <div className="home-main-map__footer-container__copyright">
                        <img
                            className="home-main-map__footer-container__copyright__company-logo"
                            src={themeGlobal.EagleLogo}
                        ></img>
                        <div className="home-main-map__footer-container__copyright__title">
                            {copyRightTitle}
                        </div>
                    </div>
                    {/* <div className='home-main-map__footer-container__themeSwitch'
                        onClick={(e) => {
                            setTheme((prev) => { return prev * (-1) })
                            MovingRingRef.current.style.transform = `translateX(${-10 * theme}px)`
                            // console.log(e.currentTarget.style.tra)
                        }}
                    >
                        <div className='home-main-map__footer-container__themeSwitch__circle'
                            ref={MovingRingRef}
                        >
                        </div>
                    </div> */}
                </div>
            </div>
            {
                customAlertVisibility && (
                    <CustomAlertBox
                        setOpenModal={setCustomAlertVisibility}
                        messageToDisplay={alertBoxMesage}
                        setIsAborted={setIsAborted}
                    />
                )
            }
            {
                abortBoxVisibility && (
                    <div onClick={e => {
                        if (modalRef.current === e.target) {
                            // setAbortBoxVisibility(false);
                            // setIsAborted(true);
                        }
                    }} ref={modalRef} className="modalalertBackground">
                        <div className="home-main-map__GoogleMapContainer__AbortBox">
                            <div className="home-main-map__GoogleMapContainer__AbortBox__AbortMsg">
                                You can abort mission in <span
                                    className="home-main-map__GoogleMapContainer__AbortBox__AbortMsg__count"
                                >{timerCount}</span> sec
                            </div>
                            <button
                                className="home-main-map__GoogleMapContainer__AbortBox__AbortBtn"
                                onClick={() => {
                                    setIsAborted(true);
                                }}
                            >
                                Abort
                            </button>
                        </div>
                    </div>
                )
            }
            {notificationVisibility && (<CustomNotification setOpenModal={setNotificationVisibility} messageToDisplay={messageToDisplay} />)}
            {loadingVisibility && <LoadingScreen />}
        </div >
    );
};

export default HomePage;
