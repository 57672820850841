import axios from "axios";
import { React, useEffect, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { update } from "../../redux/userSlice";
// import { PersonIcon } from '@mui/icons-material';

import "./Login.scss"
import { backEndIP } from "../MyConfiguration";
import { useState } from "react";
import CustomNotification from "../CustomNotification/CustomNotification";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const Login = (props) => {

    // const user = useSelector((state)=>state.user);
    // const userDispatch = useDispatch();
    const { setuserCookie, userCookie, removeCookie } = props;
    const userEmailElementRef = useRef();
    const passwordElementRef = useRef();
    const errorMessageElementRef = useRef();
    const navigate = useNavigate();
    const userdetail = {
        name: "",
        email: "",
    }

    const [notificationVisibility, setNotificationVisibility] = useState(false);
    const [alertVisibility, setAlertVisibility] = useState(false);
    const [messageToDisplay, setMessageToDisplay] = useState({
        type: "notify",
        message: "msg",
        msgtype: "success",
        func: () => { },
    });
    const [loadingVisibility, setLoadingVisibility] = useState(false);

    useEffect(() => {
        if (userCookie.token) {
            axios({
                url: backEndIP + "/admin/verifytoken",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then(async (loginData) => {
                await setuserCookie("token", loginData.data.token, { path: "/" })
                await setuserCookie("username", loginData.data.userName, { path: "/" })
                await setuserCookie("useremail", loginData.data.userEmail, { path: "/" })
                await setuserCookie("designation", loginData.data.accessRole, { path: "/" })
                userdetail.name = loginData.data.userName;
                userdetail.email = loginData.data.userEmail;
                if (loginData.data.userEmail.split("@")[1] === "techeagle.in") {
                    navigate("/dashboard")
                }
                else {
                    navigate("/view-flight")
                }
            }).catch(async (err) => {
                window.alert("login again")
                console.log(err);
                await removeCookie("token");
                await removeCookie("username");
                await removeCookie("useremail");
                await removeCookie("designation");
                await removeCookie("accessRole");
            })
        }
        else {

        }
    }, [])
    // console.log("userCookie",userCookie)

    const handleLoginBtnClick = () => {
        let userEmail = userEmailElementRef.current.value;
        let password = passwordElementRef.current.value;
        // console.log(userEmail, password)
        setLoadingVisibility(true)
        if (userEmail.length >= 6 && password.length >= 6) {
            errorMessageElementRef.current.style.opacity = 0;
            axios({
                url: backEndIP + "/admin/login",
                // url: backEndIP + "/user/login",
                method: "POST",
                headers: {
                },
                data: { useremail: userEmail, password: password }
            }).then(async (loginData) => {
                // console.log(loginData)

                // document.cookie = "testCookie=thisistext; path=/;";
                await setuserCookie("token", loginData.data.token, { path: "/" });
                await setuserCookie("username", loginData.data.userName, { path: "/" });
                await setuserCookie("useremail", loginData.data.userEmail, { path: "/" });
                await setuserCookie("designation", loginData.data.accessRole, { path: "/" });
                await setuserCookie("accessRole", loginData.data.accessRole, { path: "/" })
                userdetail.name = loginData.data.userName;
                userdetail.email = loginData.data.userEmail;
                // console.log(userCookie.token)
                // userDispatch(update(userdetail))
                // if (userCookie.token !== "") {
                if (userEmail.split("@")[1] === "techeagle.in") {
                    navigate("/dashboard")
                }
                else {
                    navigate("/view-flight")
                }
                // } else {
                //     navigate("/")
                // }

            }).catch((err) => {
                console.log(err)
                setLoadingVisibility(false)
                handleUpdateAlertNottifyMsg("alert", `${err.response.data.message}`, "failed", () => { })
            })
        }
        else {
            if (userEmail.length < 6) {
                setLoadingVisibility(false);
                errorMessageElementRef.current.innerHTML = "User name length should be more the 5"
                errorMessageElementRef.current.style.opacity = 1;
            }
            else {
                setLoadingVisibility(false);
                errorMessageElementRef.current.innerHTML = "password length should be more than or equal to 6"
                errorMessageElementRef.current.style.opacity = 1;
            }
        }
    }


    const handleUpdateAlertNottifyMsg = (type, message, msgtype, func) => {
        setMessageToDisplay({
            type: type,
            message: message,
            msgtype: msgtype,
            func: () => {
                setLoadingVisibility(false);
                func();
            },
        });
        if (type === "notify") {
            setNotificationVisibility(true);
        } else if (type === "alert") {
            setNotificationVisibility(true);
        }
    };

    return (
        <div className="login-page-body" style={{ height: "100%", width: "100%" }}>
            {/* <div className="login-page-main-container">
                <div className="login-page-usericon-container"></div>
                <div className="login-page-member-login-name">Member Login</div>
                <input ref={userEmailElementRef} className="login-page-input-username" type={"email"} placeholder={"User Email Id"}></input>
                <input ref={passwordElementRef} className="login-page-input-password" type={"password"} placeholder={"Password"}></input>
                <div onClick={handleLoginBtnClick} className="login-page-btn-login">LOGIN</div>
                <div onClick={() => { navigate("/register") }} className="login-page-register-link">Register</div>
                <div ref={errorMessageElementRef} className="login-page-error-message">message</div>
            </div> */}
            <div className="login-page-body__leftHalf">
                <div className="login-page-body__leftHalf__welcomeBox">
                    <img className="login-page-body__leftHalf__welcomeBox__logo"
                        src={`${backEndIP}/clear_sky _logo_mark.svg`} alt="logo"
                    >
                    </img>
                    <div className="login-page-body__leftHalf__welcomeBox__welcomeMsg">Welcome to ClearSky</div>
                    <div className="login-page-body__leftHalf__welcomeBox__register-message">To continue Sign In</div>
                </div>
                <div className="login-page-body__leftHalf__login-container">
                    <input ref={userEmailElementRef} className="login-page-body__leftHalf__login-container__input-email" type={"email"} style={{ "color": "#08030A" }} placeholder={"User Email Id"}></input>
                    <input ref={passwordElementRef} className="login-page-body__leftHalf__login-container__input-password" type={"password"} style={{ "color": "#08030A" }} placeholder={"Password"}></input>
                    <div className="login-page-body__leftHalf__login-container__forgotPassword"
                        onClick={() => { navigate("/forgot-password") }}
                    >Forgot password?</div>
                    <div onClick={handleLoginBtnClick}
                        className="login-page-body__leftHalf__login-container__btn-SignIn">Sign In</div>
                    {/* <div
                        className="login-page-body__leftHalf__login-container__register-msg1">
                        Don’t have an account?
                        <span
                            className="login-page-body__leftHalf__login-container__register-msg2"
                            onClick={() => { navigate("/register") }}
                        >Create New Account
                        </span>
                    </div> */}
                    <div ref={errorMessageElementRef}
                        className="login-page-body__leftHalf__login-container__error-msg"
                    >message</div>
                </div>
            </div>
            <div className="login-page-body__rightHalf">
                <img className="login-page-body__rightHalf__img"
                    src={`${backEndIP}/drone_in_ancient_cave_processed.png`} alt="img"></img>
            </div>
            {notificationVisibility && (
                <CustomNotification
                    setOpenModal={setNotificationVisibility}
                    messageToDisplay={messageToDisplay}
                />
            )}
            {loadingVisibility && <LoadingScreen />}

        </div>
    )
}


export default Login;