import React, { useEffect, useState } from 'react'

import "./CheckListItemTemplateRow.scss"
import { backEndIP } from './MyConfiguration'
const CheckListItemTemplateRow = ({ item, index, itemMoveUp, itemMoveDown, itemDelete, setIsChanged }) => {
    const [isActive, setIsActive] = useState(item.active ? item.active : false)
    const [updateSelf, setUpdateSelf] = useState(false)
    // useEffect(() => {
    //   item.active = isActive
    //   setIsChanged(true)
    // }, [isActive])
    const moveParameterUp = (index) => {
        const para1 = item.parameters[index];
        item.parameters[index] = item.parameters[index - 1]
        item.parameters[index - 1] = para1
        item.parameters = [...item.parameters]
        setUpdateSelf(prev => !prev)
        setIsChanged(true)
    }
    const moveParameterDown = (index) => {
        const para1 = item.parameters[index];
        item.parameters[index] = item.parameters[index + 1]
        item.parameters[index + 1] = para1
        item.parameters = [...item.parameters]
        setUpdateSelf(prev => !prev)
        setIsChanged(true)
    }


    return (
        <div className='PreChecklistItemRow'>
            <div className='PreChecklistItemRow__SrNo'>{index + 1}</div>
            <div className='PreChecklistItemRow__CheckBox'>
                <input type='checkbox' checked={item.active} onChange={(e) => { item.active = e.target.checked; setIsChanged(true); setUpdateSelf(prev => !prev) }}></input>
            </div>
            <div className='PreChecklistItemRow__Title'>{item.label}</div>
            <div className='PreChecklistItemRow__Parameters'>
                {item.parameters.length > 0 && <table
                    className='PreChecklistItemRow__Parameters__table'>
                    <thead className='PreChecklistItemRow__Parameters__table__thead'>
                        <tr className='PreChecklistItemRow__Parameters__table__index'>
                            <td className='PreChecklistItemRow__Parameters__table__SrNo'>S.No</td>
                            <td className='PreChecklistItemRow__Parameters__table__name'>Parameter Name</td>
                            <td className='PreChecklistItemRow__Parameters__table__type'>Type</td>
                            <td className='PreChecklistItemRow__Parameters__table__options'>Options</td>
                            <td className='PreChecklistItemRow__Parameters__table__actions'>Actions</td>
                        </tr>
                    </thead >
                    <tbody className='PreChecklistItemRow__Parameters__table__tbody' >
                        {item.parameters.map((para, i) => {
                            return (
                                <tr key={`${para.paraName}${para.paraType}${i}`} className='PreChecklistItemRow__Parameters__table__row'>
                                    <td className='PreChecklistItemRow__Parameters__table__SrNo'>{i + 1}</td>
                                    <td className='PreChecklistItemRow__Parameters__table__name'>{para.paraName}</td>
                                    <td className='PreChecklistItemRow__Parameters__table__type'>{para.paraType}</td>
                                    <td className='PreChecklistItemRow__Parameters__table__options'>
                                        {para.paraOptions.length ? para.paraOptions.map((opName) => {
                                            return <div key={`${para.paraName}${opName}`}> {opName}</div>
                                        }) : <div>-</div>}
                                    </td>
                                    <td className='PreChecklistItemRow__Parameters__table__actions'>
                                        <div className='MissionPlanPointRow-container__row1__buttons'>
                                            <img className='MissionPlanPointRow-container__row1__updownimg'
                                                src={`${backEndIP}/upArrowIcon.png`} alt="UP"
                                                onClick={() => {
                                                    if (i > 0)
                                                        moveParameterUp(i)
                                                }} />
                                            <img className='MissionPlanPointRow-container__row1__updownimg'
                                                src={`${backEndIP}/downArrowIcon.png`} alt="DN"
                                                onClick={() => {
                                                    if (i < item.parameters.length - 1)
                                                        moveParameterDown(i)
                                                }} />
                                            <img className='MissionPlanPointRow-container__row1__updownimg'
                                                src={`${backEndIP}/deleteIcon.png`} alt="DEL" onClick={() => {
                                                    const params = [...item.parameters.slice(0, i), ...item.parameters.slice(i + 1)];
                                                    item.parameters = params
                                                    setUpdateSelf(prev => !prev)
                                                    setIsChanged(true)
                                                }} />
                                        </div>
                                    </td>
                                </tr>)
                        })}
                    </tbody>
                </table>
                }
            </div>
            <div className='PreChecklistItemRow__Notes'></div>
            <div className='PreChecklistItemRow__actions'>

                <img className='MissionPlanPointRow-container__row1__updownimg'
                    src={`${backEndIP}/upArrowIcon.png`} alt="UP"
                    onClick={() => {
                        itemMoveUp(index)
                    }} />
                <img className='MissionPlanPointRow-container__row1__updownimg'
                    src={`${backEndIP}/downArrowIcon.png`} alt="DN"
                    onClick={() => {
                        itemMoveDown(index)
                    }} />
                <img className='MissionPlanPointRow-container__row1__updownimg'
                    src={`${backEndIP}/deleteIcon.png`} alt="DEL"
                    onClick={() => {
                        itemDelete(index)
                    }} />
            </div>
        </div>
    )
}

export default CheckListItemTemplateRow