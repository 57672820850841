import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

import './OrderManagement.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomAlertBox from '../CustomAlert/CustomAlertBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import io from 'socket.io-client';
import TabContent from '../TabContent/TabContent';
import OrdersTabContent from '../OrdersTabContent/OrdersTabContent';
import { backEndIP, copyRightTitle, InventorySocketIP } from '../MyConfiguration';
import CreateFlightTabContent from '../CreateFlightTabContent/CreateFlightTabContent';
import dayjs from 'dayjs';
import axios from 'axios';
import AllFlightsTabContent from '../AllFlightsTabContent/AllFlightsTabContent';
import EmployeesTabContent from '../EmployeesTabContent/EmployeesTabContent';
import CreateHubPanel from '../CreateHubPanel/CreateHubPanel';
import CreateLocationPanel from '../CreateLocationPanel/CreateLocationPanel';
import DroneModelTab from '../DronesModel/DronesModelTab';
import AllDronesTab from '../AllDrones/AllDrones';
import DroneManagementTab from '../DroneManagemnetPanel/DroneManagementTab';
import CustomNotification from '../CustomNotification/CustomNotification';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import HubTableTab from '../HubTable/HubTable';
import NodeTable from '../NodeTable/NodeTable';
import PreFlightChecklist from '../PreFlightChecklist/PreFlightChecklist';
import PostFlightCheckList from '../PostFlightCheckList/PostFlightCheckList';
import { getCookie, logoutAction } from '../utility';
import { useDispatch, useSelector } from 'react-redux';
import { selectordersFilterBy, setOrdersFilterBy } from '../OrdersTabContent/ordersFilterBySlice';
import store from '../../ReduxStore/store';
import orderTabDataStore from '../OrdersTabContent/orderTabDataStore'
import AllUsersTabel from '../AllUsersTabel/AllUsersTabel';
import { addNewOrderToAllOrders, selectAllOrders, selectAllOrdersData, selectOrdersDuration, selectOrdersFilterBy, selectOrdersScrollTop, setAllOrdersData, setOrdersScrollTop, updateSpecificOrder } from '../OrdersTabContent/ordersDataSlice';
import { Toast } from 'bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectHubNodeLocationsLoading, setHubNodeLocations } from '../../ReduxStore/HubNodeLocationsSlice';
import HelpSectionTabContent from '../SupportTabs/HelpSectionTabContent';
import { allForms } from '../AdminPanel/allForms';
import { Avatar, Dropdown } from 'rsuite';
import InventoryManagement from './Tabs/InventoryManagement';
import CreateManualOrder from './Tabs/CreateManualOrder';
import { addNewManualOrderToAllOrders, setAllManualOrdersData, updateSpecificManualOrder } from '../ManualOrdersTabContent/manualOrdersDataSlice';
import ManualOrdersTabContent from '../ManualOrdersTabContent/ManualOrdersTabContent';


const pathToSelectedTab = {
    "/ordermanagement/orders": "Orders",
    "/ordermanagement/manual-orders": "Manual Orders",
    "/ordermanagement/all-users": "All Users",
    "/ordermanagement/manage-inventory": "Manage Inventory",
    "/ordermanagement/create-manual-orders": "Create Manual Order"
    // "Help Content":"/help-content"
}

const TabBox = ({ tabName, selectedTab, setSelectedTab }) => {


    return (
        <div
            className='view-order-sidebar__tabs-container__tab'
            onClick={() => { setSelectedTab(tabName) }}
            style={{
                borderRight: selectedTab === tabName ? "none" : "2px solid #FD6125",
                backgroundColor: selectedTab === tabName ? "var(--primaryColor)" : "#716f79",
                color: selectedTab === tabName ? "var(--primaryTextColor)" : ""
            }}
            onMouseOver={(e) => { e.currentTarget.style.backgroundColor = selectedTab === tabName ? "var(--primaryColor)" : "#FD6125" }}
            onMouseOut={(e) => { e.currentTarget.style.backgroundColor = selectedTab === tabName ? "var(--primaryColor)" : "#716f79" }}
        >{tabName}</div>
    )
}


const DurationOptions = {
    "Today": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
    "Last 3 days": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "day"),
    "Last 1 week": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "week"),
    "Last 2 weeks": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "week"),
    "Last 1 Month": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "month"),
    "Last 3 Months": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(3, "month"),
};

const OrderManagement = ({ userCookie, setuserCookie, removeCookie, themeGlobal }) => {

    let urllocation = useLocation();
    const subContent = urllocation.pathname.split("ordermanagement")[1];
    console.log("subContent", subContent)
    const [socket, setSocket] = useState(io(InventorySocketIP, {
        autoConnect: false,
        transports: ["websocket"],
        auth: {
            token: userCookie.token
        }
    }))

    const [lastPong, setLastPong] = useState(null);

    const [isConnected, setIsConnected] = useState(false);
    const [ConnectBtnString, setConnectBtnString] = useState("Connect Server");
    const [customAlertVisibility, setCustomAlertVisibility] = useState(false);
    const [alertBoxMesage, setAlertBoxMessage] = useState({
        message: "",
        func: () => { },
    });

    // const [dimen, setDimen] = useState({ height: window.innerHeight, width: window.innerHeight })

    const [panelLog, setPanelLog] = useState([]);
    const [webCamImg, setWebCamImg] = useState("");
    const refreshOrdersPage = { isupdated: false, func: () => { }, manualFunc: () => { } }
    const registerfunc = (fn) => {
        // console.log("ordersTab function registered", refreshOrdersPage.isupdated)
        refreshOrdersPage.func = fn
        // console.log(refreshOrdersPage)
    }
    const registerManualOrderfunc = (fn) => {
        // console.log("ordersTab function registered", refreshOrdersPage.isupdated)
        refreshOrdersPage.manualFunc = fn
        // console.log(refreshOrdersPage)
    }
    const navigate = useNavigate();
    const RefLogBox = useRef();
    const adminTabs = {
        "CEO": ["Orders", "Manual Orders"],
        "Country Manager": ["Orders", "Manual Orders"],
        "Zone Manager": ["Orders", "Manual Orders"],
        "State Manager": ["Orders", "Manual Orders"],
        "Order Manager": ["Orders", "Manual Orders"],
        "Drone Pilot": ["Orders", "Manual Orders"],
        "Hub Manager": ["Orders", "Manual Orders", "All Users", "Manage Inventory", "Create Manual Order"],
        "System User": ["Orders", "Manual Orders", "All Users", "Manage Inventory", "Create Manual Order"],//"Help Content" 
    }
    // const pilotTabs=["orders","Create Flight","All Flights","Get Flight"]
    const allAdminPositions = {
        "CEO": "CEO",
        "Country Manager": "Country Manager",
        "Zone Manager": "Zone Manager",
        "State Manager": "State Manager",
        "Hub Manager": "Hub Manager",
        "Drone Pilot": "Drone Pilot",
        "Hub Manager": "Hub Manager",
        "System User": "System User",

    };


    const [adminLocation, setAdminLocation] = useState();
    const [adminAuthLocType, setAdminAuthLocType] = useState();
    const [adminDesignation, setAdminDesignation] = useState("Drone Pilot");
    const [currentAdminTabs, setCurrentAdminTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(subContent === "" || subContent === "/" ? "Orders" : pathToSelectedTab[urllocation.pathname]);
    const [subSelectedTab, setSubSelectedTab] = useState("Orders");


    const [filterBy, setFilterBy] = useState("ALL");
    const [flightFilterBy, setFlightFilterBy] = useState("ALL");
    const [sourceLocation, setSourceLocation] = useState("ALL")
    const ordersFilterBy = useSelector(selectordersFilterBy);
    const dispatch = useDispatch()
    const setOrdFilterBy = (val) => {
        dispatch(setOrdersFilterBy(val))
    }

    // const [allOrders, setAllOrders] = useState(orderTabDataStore.allOrders)
    const hubNodeLocationsLoadingState = useSelector(selectHubNodeLocationsLoading)



    const saveScrollTop = (value) => {
        dispatch(setOrdersScrollTop(value))
    }
    const setAllOrders = (data) => {
        dispatch(setAllOrdersData(data))
    }
    const updateOrderNo = (order) => {
        // refreshOrdersPage.func();
        dispatch(updateSpecificOrder(order))
    }

    const addNewOrder = (order) => {
        // saveScrollTop(0);
        refreshOrdersPage.func();
        // const tempOrders = [order,...allOrders];
        dispatch(addNewOrderToAllOrders(order))
        // dispatch(setAllOrdersData(tempOrders))
    }


    const setAllManualOrders = (data) => {
        dispatch(setAllManualOrdersData(data))
    }

    const updateManualOrderNo = (order) => {
        // refreshOrdersPage.func();
        dispatch(updateSpecificManualOrder(order))
    }

    const addNewManualOrder = (order) => {
        // saveScrollTop(0);
        refreshOrdersPage.manualFunc();
        // const tempOrders = [order,...allOrders];
        dispatch(addNewManualOrderToAllOrders(order))
    }


    const [duration, setDuration] = useState({ d: "Today", details: { from: dayjs(new Date()).format("DD-MM-YYYY"), to: dayjs(new Date()).format("DD-MM-YYYY") } })

    const [userInitial, setUserInitial] = useState("")
    const contentTabsLinks = {
        "Orders": () => { socket.emit('client:get_all_orders', { filterBy: ordersFilterBy, duration: DurationOptions[duration.d] }) },
        "Manual Orders": () => { socket.emit('client:get_all_manual_orders', { filterBy: ordersFilterBy, duration: DurationOptions[duration.d] }); },
    }

    const [deployLocations, setDeployLocations] = useState([]);
    const [refreshDeployLocations, setRefreshDeployLocations] = useState(false);
    const [notificationVisibility, setNotificationVisibility] = useState(false);
    const [loadingVisibility, SetLoadingVisibility] = useState(false);
    const [sortSetting, setSortSetting] = useState(["", "order_no", "dsc"]);
    const [messageToDisplay, setMessageToDisplay] = useState({
        type: "notify",
        message: "msg",
        msgtype: "success",
        func: () => { },
    });

    const allNewPanelLog = (message) => {
        panelLog.unshift(
            [new Date().toTimeString().split("(")[0], message]
            // `${new Date().toTimeString().split("(")[0]}: ${"cconnect dronnected to server"}`
        );
        // setPanelLog([...panelLog.reverse()])
    }

    const notifyToast = (msg) => toast.success(msg);

    useEffect(() => {
        if (userCookie.token) {
            axios({
                url: backEndIP + "/admin/verifytoken",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then(async (loginData) => {
                console.log("loginData", loginData)
                await setuserCookie("token", loginData.data.token, { path: "/" })
                await setuserCookie("username", loginData.data.userName, { path: "/" })
                await setuserCookie("useremail", loginData.data.userEmail, { path: "/" })
                await setuserCookie("designation", loginData.data.designation, { path: "/" })
                await setuserCookie("accessRole", loginData.data.accessRole, { path: "/" })
                // setAdminPosition(loginData.data.accessRole)
                // console.log("usersLocation", loginData)
                setAdminLocation(loginData.data.usersLocation)
                setAdminDesignation(loginData.data.accessRole);
                setAdminAuthLocType(loginData.data.usersAuthLocType)
                setUserInitial(loginData.data.userName[0].toLocaleUpperCase())
                // setSourceLocation(loginData.data.usersLocation)
                if (loginData.data.userEmail.split("@")[1] === "techeagle.in") {
                    // navigate("/monitor")
                }
                else {
                    socket.off();
                    socket.close();
                    navigate("/view-flight")
                }
            }).catch(async (err) => {
                // window.alert("login again")
                console.log(err);
                socket.off();
                socket.close();
                await removeCookie("token");
                await removeCookie("username");
                await removeCookie("useremail");
                await removeCookie("designation");
                await removeCookie("accessRole");
                navigate("/");
            })


        }
        else {
            socket.off();
            socket.close();
            navigate("/")
        }



    }, [userCookie.token])

    useEffect(() => {
        if (hubNodeLocationsLoadingState === "idle") {
            axios({
                url: backEndIP + "/location/hub-node-all-locations",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((respHubNodeLocations) => {
                // console.log("respHubNodeLocations=", respHubNodeLocations.data)
                dispatch(setHubNodeLocations(respHubNodeLocations.data.data))
            }).catch((err) => {
                console.log(err);

            })
        }
    }, [hubNodeLocationsLoadingState])


    useEffect(() => {
        const getAllLocations = async () => {
            const allLocations = await axios({
                url: backEndIP + "/admin/get_all_locations",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).catch((err) => {
                console.log(err);
            })
            console.log("getAllLocations", allLocations.data.data)
            setDeployLocations(allLocations.data.data);
        }
        getAllLocations();
    }, [refreshDeployLocations])

    useEffect(() => {
        // orderTabDataStore.allOrders = [...orderTabDataStore.allOrders]
        // setAllOrders(orderTabDataStore.allOrders);
        // console.log(selectedTab, ordersFilterBy, duration);
        // console.log(selectedTab, ordersFilterBy, DurationOptions[duration.d]);
        if (contentTabsLinks[selectedTab] !== undefined && selectedTab === "Orders" && isConnected) {
            socket.emit('client:get_all_orders', { filterBy: ordersFilterBy, duration: DurationOptions[duration.d] });
        }
        if (contentTabsLinks[selectedTab] !== undefined && selectedTab === "Manual Orders" && isConnected) {
            socket.emit('client:get_all_manual_orders', { filterBy: ordersFilterBy, duration: DurationOptions[duration.d] });
        }
    }, [duration.d, ordersFilterBy])

    useEffect(() => {
        // console.log(flightFilterBy, "All Flights", contentTabsLinks[selectedTab])
        if (contentTabsLinks[selectedTab] !== undefined && isConnected) {
            contentTabsLinks[selectedTab]();
        }
    }, [selectedTab])

    useEffect(() => {
        // RefLogBox.current.scrollIntoView({ behavior: "smooth" });
        RefLogBox.current.scrollTop = 0;
    }, [panelLog])
    useEffect(() => {
        // console.log("isconnected", isConnected)
        // RefLogBox.current.scrollIntoView({ behavior: "smooth" });
        RefLogBox.current.scrollTop = 0;
        if (isConnected) {
            SetLoadingVisibility(true)
            setTimeout(() => {
                // console.log("contentTabsLinks[selectedTab] executed", selectedTab)
                if (contentTabsLinks[selectedTab]) {
                    contentTabsLinks[selectedTab]();
                }
                SetLoadingVisibility(false)
            }, 200);
        }

    }, [isConnected])

    useEffect(() => {
        // console.log("ordersFilterBy =0", ordersFilterBy);
        if (contentTabsLinks[selectedTab] !== undefined && isConnected) {
            socket.emit(contentTabsLinks[selectedTab], { filterBy: selectordersFilterBy(store.getState()), duration: DurationOptions[duration.d] })
        }
    }, [ordersFilterBy])

    useEffect(() => {
        setCurrentAdminTabs(adminTabs[allAdminPositions[adminDesignation]])
        // console.log(adminTabs[allAdminPositions[adminDesignation]])
    }, [adminDesignation, selectedTab])



    // console.log("AdminLoc", adminLocation)
    const getCurrentDruration = () => {
        return DurationOptions[duration.d]
    }

    useEffect(() => {
        socket.connect();
        socket.on('connect', () => {
            setIsConnected((prev) => true);
            socket.emit("upgrade");
            setConnectBtnString("Disconnect")
            // console.log(duration)
            panelLog.unshift(
                [new Date().toTimeString().split("(")[0], "Connected to inventory server"]);
            setPanelLog([...panelLog])
            notifyToast("Connected to inventory server")

        });

        socket.on('disconnect', () => {
            setIsConnected(false);
            setConnectBtnString("Connect Server")
            panelLog.unshift(
                [new Date().toTimeString().split("(")[0], "Disconnected from inventory server"]
                // `${new Date().toTimeString().split("(")[0]}: ${"cconnect dronnected to server"}`
            );
            setPanelLog([...panelLog])
        });

        socket.on('server:pong', (data) => {
            setLastPong(new Date().toISOString());
            console.log("server:pong =", data)
        });

        socket.on('server:panel_log', (data) => {
            panelLog.unshift(
                [new Date().toTimeString().split("(")[0], data]
                // `${new Date().toTimeString().split("(")[0]}: ${"cconnect dronnected to server"}`
            );
            setPanelLog([...panelLog])
        });
        socket.on('server:update_status', (data) => {
            panelLog.unshift(
                [new Date().toTimeString().split("(")[0], data.message + ` to "${data.order.current_status.name}"`]
            );
            setPanelLog([...panelLog])
            updateOrderNo(data.order)
        });
        socket.on("server:new-order", (data) => {
            panelLog.unshift([new Date().toTimeString().split("(")[0], data.message]);
            setPanelLog([...panelLog])
            notifyToast(data.message)
            addNewOrder(data.order);
        });
        socket.on('server:sending_all_orders', (data) => {
            // console.log(data);
            setAllOrders(data)
            // orderTabDataStore.allOrders = data
        });


        socket.on('server:update_manual_order_status', (data) => {
            panelLog.unshift(
                [new Date().toTimeString().split("(")[0], data.message + ` to "${data.order.current_status.name}"`]
            );
            setPanelLog([...panelLog])
            updateManualOrderNo(data.order)
        });
        socket.on("server:new_manual_order", (data) => {
            panelLog.unshift([new Date().toTimeString().split("(")[0], data.message]);
            setPanelLog([...panelLog])
            notifyToast(data.message)
            addNewManualOrder(data.order);
        });
        socket.on('server:sending_all_manual_orders', (data) => {
            setAllManualOrders(data)
            console.log("sending_all_manual_orders", data)
            // orderTabDataStore.allOrders = data
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('server:pong');
            socket.off('server:update_status');
            socket.off('server:panel_log');
            socket.off('server:sending_all_orders');
            socket.off("server:new-order");
            socket.off('server:update_manual_order_status');
            socket.off('server:sending_all_manual_orders');
            socket.off("server:new_manual_order");
            // socket.off('server:webcam');
            socket.disconnect();
            socket.close();
        };
    }, [socket]);


    const handleConnectClick = (e) => {
        if (isConnected) {
            // setConnectBtnString("Dro");
            socket.disconnect();
        }
        else {
            setConnectBtnString("Connecting");
            socket.connect();
        }
    }
    const handleUpdateAlertNottifyMsg = (type, message, msgtype, func) => {
        setMessageToDisplay({
            type: type,
            message: message,
            msgtype: msgtype,
            func: () => {
                SetLoadingVisibility(false);
                func();
            },
        });
        if (type === "notify") {
            setNotificationVisibility(true);
        } else if (type === "alert") {
            setNotificationVisibility(true);
        }
    };

    const navLink = {
        "Orders": "/ordermanagement/orders",
        "Manual Orders": "/ordermanagement/manual-orders",
        "All Users": "/ordermanagement/all-users",
        "Manage Inventory": "/ordermanagement/manage-inventory",
        "Create Manual Order": "/ordermanagement/create-manual-orders",
        // "Help Content":"/help-content"
    }


    useEffect(() => {
        // setSubSelectedTab(selectedTab)
        navigate(navLink[selectedTab])
    }, [selectedTab]);

    // useEffect(() => {
    //     // const tab = urllocation.pathname.split("ordermanagement/")[1];
    //     // console.log("tab",tab)
    //     setSelectedTab(pathToSelectedTab[urllocation.pathname])
    // }, []);
    const allTabsContentPage = {
        // "Create Flight": <CreateFlightTabContent
        //     userCookie={userCookie}
        //     content={allForms[selectedTab]}
        //     setSelectedTab={setSelectedTab}
        //     allLocations={deployLocations.allNames} />,

        "Orders": <OrdersTabContent
            socket={socket}
            isConnected={isConnected}
            userCookie={userCookie}
            content={allForms[selectedTab]}
            // allOrders={allOrders}
            // setAllOrders={setAllOrders}
            registerfunc={registerfunc}
            allNewPanelLog={allNewPanelLog}
            handleNotifyAlert={handleUpdateAlertNottifyMsg}
            SetLoadingVisibility={SetLoadingVisibility}
            filterBy={ordersFilterBy}
            setFilterBy={setOrdFilterBy}
            duration={duration}
            etDuration={setDuration}
            sortSetting={sortSetting}
            setSortSetting={setSortSetting} />,

        "Manual Orders": <ManualOrdersTabContent
            socket={socket}
            isConnected={isConnected}
            userCookie={userCookie}
            content={allForms[selectedTab]}
            // allOrders={allOrders}
            // setAllOrders={setAllOrders}
            registerfunc={registerManualOrderfunc}
            allNewPanelLog={allNewPanelLog}
            handleNotifyAlert={handleUpdateAlertNottifyMsg}
            SetLoadingVisibility={SetLoadingVisibility}
            filterBy={ordersFilterBy}
            setFilterBy={setOrdFilterBy}
            duration={duration}
            etDuration={setDuration}
            sortSetting={sortSetting}
            setSortSetting={setSortSetting} />,
        "All Users": <AllUsersTabel
            userCookie={userCookie}
            locations={deployLocations.allNames}
            allHubLocations={deployLocations.hubNames}
            socket={socket}
            setSelectedTab={setSelectedTab}
            handleNotifyAlert={handleUpdateAlertNottifyMsg}
            SetLoadingVisibility={SetLoadingVisibility} />,
        "Manage Inventory": <InventoryManagement userCookie={userCookie} />,
        "Create Manual Order": <CreateManualOrder userCookie={userCookie} />,
        "Help Content": <HelpSectionTabContent
            userCookie={userCookie}
            socket={socket}
            setSelectedTab={setSelectedTab} />

    }

    const renderToggle = props => (
        <Avatar circle {...props} src="" style={{ backgroundColor: "#FD6125" }} />
    );


    return (
        <div className='view-order-page-container'>
            <div className='view-order-sidebar'>
                <div className='view-order-sidebar__logodiv'>
                    <img className='view-order-sidebar__logodiv__logoimg'
                        src={themeGlobal.nameLogo}
                        alt='logo'
                        onClick={() => { navigate("/dashboard") }}
                    />
                </div>
                <div className='view-order-sidebar__logodiv-border'></div>
                <div className='view-order-sidebar__container'>
                    <div className='view-order-sidebar__tabs-container'>

                        {currentAdminTabs.map((tabName) => {
                            return <TabBox key={tabName} tabName={tabName} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                        })}

                    </div>
                    <div className='view-order-map__topInfobar__designation-container'>
                        {/* <div className='view-order-map__topInfobar__designation-container__title'>Designation</div>
                    <div className='view-order-map__topInfobar__designation-container__value'>{adminDesignation}</div> */}
                        <div className='view-main-map__topInfobar__designation-container__value'><a href='https://forms.gle/AxNPLGbvnpEoxHcq6' target='blank' className='ReportbugsandErrors'>Report bugs / Error</a></div>
                    </div>
                </div>

                <button className="view-order-sidebar__logout"
                    onClick={() => {
                        setAlertBoxMessage({
                            message:
                                "Are you sure you want Logout?",
                            func: () => { logoutAction(removeCookie, navigate, socket) }
                        });
                        setCustomAlertVisibility(true);
                    }}>
                    {/* <img
                            src="left_arrow_icon.svg"
                            alt={""}
                            style={{ height: "20px", width: "20px" }}
                        /> */}
                    Logout
                </button>
            </div>
            <div className='view-order-map'>
                <div className='view-order-map__topInfobar'>
                    {/* <div className='view-order-map__search-container'>
                        <img className='view-order-map__search-container__img' src='searchIcon.svg' alt="" />
                        <input className='view-order-map__search-container__search'
                            type={"text"} placeholder={"Search"}></input>
                    </div> */}

                    {/* <div className='view-order-sidebar__DroneName'>VERTIPLANE X3</div> */}
                    {/* <div className='view-order-map__topInfobar__designation-container'>
                        <div className='view-order-map__topInfobar__designation-container__title'>Designation</div>
                        <div className='view-order-map__topInfobar__designation-container__value'>{adminDesignation}</div>
                    </div> */}
                    {/* <div className='view-order-map__topInfobar__connectBtn'
                        onClick={handleConnectClick}
                    // style={{backgroundColor:"#e82222"}}
                    >
                        {ConnectBtnString}
                    </div> */}
                    {/* <div className='view-order-map__topInfobar__instuctionNStatus'> */}
                    {/* {ConnectingSpinner && <Spinner animation="border" role="status" variant='danger'>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>} */}

                    <div className='view-order-map__topInfobar__instuctionNStatus__message' ref={RefLogBox}>
                        {panelLog.length !== 0 &&
                            panelLog.map((msg, i) => {
                                return <div key={`panelLog${i}`}>
                                    <span style={{ color: "#FD6125" }}><strong>{msg[0]}</strong></span>
                                    <span>: {msg[1]}</span>
                                </div>;
                            })}
                        <div
                            ref={RefLogBox}></div>
                    </div>
                    {/* <DropdownButton style={{width:"200px"}}>
                            <DropdownItem>test</DropdownItem>
                        </DropdownButton> */}
                    {/* </div > */}

                    {/* <div className='view-order-map__topInfobar__user'>

                        <div className='view-order-map__topInfobar__user__proImg'
                            onClick={() => {
                                socket.off();
                                socket.close();
                                navigate("/monitor")
                            }}>{userInitial}
                        </div> */}
                    {/* <div className='view-order-map__topInfobar__user__username'>{userCookie.username}</div> */}
                    {/* <img src='clear_sky_logo_with_text.svg'
                            style={{
                                height: "80%", backgroundColor: "#FD6125", padding: "5px",
                                borderRadius: "5px"
                            }} /> */}

                    {/* </div> */}
                    <div className='view-main-map__topInfobar__header'>
                        <div className='view-main-map__topInfobar__header__userIcon'>
                            <Dropdown renderToggle={renderToggle}>
                                <Dropdown.Item panel style={{ padding: 0, width: 100 }}>
                                    {/* <p>Signed in as</p> */}
                                    {/* <strong>{userCookie.username}</strong> */}
                                </Dropdown.Item>
                                {/* <Dropdown.Separator /> */}
                                <Dropdown.Item>{`Your profile`}</Dropdown.Item>
                                {/* <Dropdown.Item>Your stars</Dropdown.Item>
                        <Dropdown.Item>Your Gists</Dropdown.Item> */}
                                <Dropdown.Item
                                    onClick={() => {
                                        setAlertBoxMessage({
                                            message:
                                                "Are you sure you want to navigate to the Clearsky Training Portal?",
                                            func: () => {
                                                setCustomAlertVisibility(false);
                                                // window.location.href = "https://training.clearsky.techeagle.org/";
                                                window.open("https://training.clearsky.techeagle.org/", "_blank");
                                            }
                                        });
                                        setCustomAlertVisibility(true);
                                    }}
                                >Get Training</Dropdown.Item>
                                <Dropdown.Separator />
                                <Dropdown.Item><a href="https://forms.gle/PdXdQM5Lfnxv5Bc3A" target='blank' className='supportlink'>Support</a></Dropdown.Item>
                                {/* <Dropdown.Item>Settings</Dropdown.Item> */}
                                <Dropdown.Item onClick={() => {
                                    setAlertBoxMessage({
                                        message:
                                            "Are you sure you want Logout?",
                                        func: () => { logoutAction(removeCookie, navigate, socket) }
                                    });
                                    setCustomAlertVisibility(true);
                                }}>Log Out</Dropdown.Item>
                            </Dropdown>
                            <div className='view-main-map__topInfobar__header__userIcon__username'>{userCookie.username}</div>
                        </div>
                    </div>

                </div>
                <div className='view-order-map__forms-container'>
                    {allTabsContentPage[selectedTab]}
                </div>
                <div className='view-order-map__footer-container'>
                    <div className='view-order-map__footer-container__copyright'>
                        <img className='view-order-map__footer-container__copyright__company-logo'
                            src={themeGlobal.EagleLogo}
                        ></img>
                        <div className='view-order-map__footer-container__copyright__title'>
                            {copyRightTitle}
                        </div>

                    </div>
                </div>
            </div>
            <ToastContainer style={{ fontSize: "calc(20px * var(--heightmultiplier))", width: "calc(400px * var(--widthmultiplier))" }} autoClose={2500} />
            {loadingVisibility && <LoadingScreen />}
            {notificationVisibility && (<CustomNotification setOpenModal={setNotificationVisibility} messageToDisplay={messageToDisplay} />)}
            {customAlertVisibility && <CustomAlertBox setOpenModal={setCustomAlertVisibility} messageToDisplay={alertBoxMesage} />}
        </div>
    )
}

export default OrderManagement;