// import React, { useEffect, useRef } from "react";
// import "./FlightRescheduleHistory.scss";

// const FlightRescheduleHistory = ({ flight }) => {
//   const lineRef = useRef(null);
//   const circleRefs = useRef([]);
//   useEffect(() => {
//     if (circleRefs.current.length && lineRef.current) {
//       const firstCircle = circleRefs.current[0];
//       const lastCircle = circleRefs.current[circleRefs.current.length - 1];

//       if (firstCircle && lastCircle) {
//         const startOffset = firstCircle.offsetTop;
//         const endOffset = lastCircle.offsetTop;

//         lineRef.current.style.top = `${startOffset + firstCircle.offsetHeight / 2}px`;
//         lineRef.current.style.height = `${endOffset - startOffset}px`;
//       }
//     }
//   }, [flight]);

//   return (
//     <div className="FlightRescheduleHistory">
//       <div className="FlightRescheduleHistory__container">
//         {flight.scheduleLog ? (
//           <div className="FlightRescheduleHistory__modal">
//             <h2 className="FlightRescheduleHistory__heading">Tracking Status</h2>
//             <h4 className="FlightRescheduleHistory__trackingId">
//               Drone Id: {flight.drone_id}
//             </h4>

//             {flight.scheduleLog.length > 0 ? (
//               <div className="FlightRescheduleHistory__statusWrapper">
//                 <div
//                   ref={lineRef}
//                   className="FlightRescheduleHistory__line"
//                 ></div>

//                 {flight.scheduleLog.map((flightRescheduleLog, index) => (
//                   <div
//                     key={index}
//                     className="FlightRescheduleHistory__statusItem"
//                     ref={(el) => {
//                       circleRefs.current[index] = el;
//                     }}
//                   >
//                     <div className="FlightRescheduleHistory__circleWrapper">
//                       <div className="FlightRescheduleHistory__circle"></div>
//                     </div>

//                     <div className="FlightRescheduleHistory__statusContent">
//                       <div className="FlightRescheduleHistory__statusDateTime">
//                         {new Date(flightRescheduleLog.date).toLocaleDateString()} 
//                       </div>
//                       <div className="FlightRescheduleHistory__statusTitle">
//                         {flightRescheduleLog.reason}
//                       </div>
//                       <div className="FlightRescheduleHistory__statusLocation">
//                         <strong>DoneBy:</strong> {flightRescheduleLog.doneBy}
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             ) : (
//               <p className="FlightRescheduleHistory__noData">
//                 No tracking details available.
//               </p>
//             )}
//           </div>
//         ) : (
//           <div className="FlightRescheduleHistory__noData">No data found.</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default FlightRescheduleHistory;

import React, { useEffect, useRef } from "react";
import "./FlightRescheduleHistory.scss";
import dayjs from "dayjs";

const FlightRescheduleHistory = ({ flight }) => {

    return (
        <div class="container">
            <div class="experience">
                {flight.scheduleLog.slice().reverse().map((log, index) => <div class="item">
                    <p class="done-by"><span style={{ fontWeight: "500" }}>Reason:</span> {log.reason}</p>

                    <div class="job-info">
                        {/* <div className="title">{log.date}</div> */}
                        <div className="date">{dayjs(log.createdAt).format("YYYY-MM-DD")}</div>
                        <div className="time" >{dayjs(log.createdAt).format("HH:mm A")}</div>
                    </div>
                    <div>
                        <ul class="fa-ul">
                            <li><span style={{ fontWeight: "500" }}>Done By:</span> {log.doneBy}</li>
                            <li>
                                <span style={{ fontWeight: "500" }}>Date:</span> {dayjs(log.date).format("DD MMMM YYYY, h:mm A")}
                            </li>

                        </ul>
                    </div>
                </div>)}
            </div>
        </div>
    )
}
export default FlightRescheduleHistory;
