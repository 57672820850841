import axios from "axios";
import { backEndIP } from "../components/MyConfiguration";

export async function fetchOrderDeliverySlots(hubId, userCookie, setAvailableOrderSlots, handleNotifyAlert, noOfFlights = 2) {
   console.log("noOfFlights", noOfFlights);
    if (userCookie.accessRole === "Hub Manager") {

        try {
            // Validate parameters
            if (!userCookie || !userCookie.token) {
                handleNotifyAlert("notify", "Invalid user credentials", "failed", () => { });
                return;
            }
            // console.log("Fetching order delivery slots", userCookie);

            const resp = await axios({
                url: `${backEndIP}/book/slots-available/admin/${hubId}/${noOfFlights}`,
                method: "GET",
                headers: {
                    authorization: userCookie.token,
                },
            });
            console.log("Fetching order delivery slots:", resp.data);
            setAvailableOrderSlots(resp.data.data.availableDays);
        } catch (err) {
            const errorMessage = err?.response?.data?.message || "Some error occurred";
            handleNotifyAlert("notify", errorMessage, "failed", () => { });
        }
    }
}
