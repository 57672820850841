import React, { useEffect, useState } from 'react';
import "./HubTable.scss"
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import CreateHubPanel from '../CreateHubPanel/CreateHubPanel';
import HubLocationDetails from '../HubLocationDetails/HubLocationDetails';


const HubTableTab = ({ userCookie, setOpenModal, setSelectedTab, allLocations, SetLoadingVisibility, handleNotifyAlert}) => {
    const [addHubForm, setAddHubForm] = useState(false)
    const [detailsPopUp, setDetailsPopUp] = useState(false)
    const [selectedHub, setSelectedHub] = useState({})
    const [allHub, setAllHub] = useState([]);
    const [adminData, setAdminData] = useState({})

    const handleAddHubButton = () => {
        setAddHubForm(true)
    }
    useEffect(() => {
         axios({
            url: backEndIP + "/hub/gethub",
            // url: backEndIP + "/user/login",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((hubResp)=>{
            // console.log(hubResp.data)
            setAllHub(hubResp.data.data);
            //console.log(allHub)
        }).catch((err) => {
            console.log(err);
        })
        // console.log(employeesResp.data.data);
       
    }, [])
    useEffect(() => {
        axios({
          url: backEndIP + "/admin/admin-details",
          // url: backEndIP + "/user/login",
          method: "GET",
          headers: {
            authorization: userCookie.token
          },
        }).then((adminResp) => {
          // console.log(adminResp.data)
          setAdminData(adminResp.data.data);
          //console.log(allHub)
        }).catch((err) => {
          console.log(err);
        })
      }, [])


    return (
        <div className='MainHubTab'>
            <div className='HubTabContent-container'>
                
                <div className='HubTabContent-datacontainer' >
                <div className='HubTabContent-container__index'>
                    <div className='HubTabContent-container__index__SNo HubTabContent-container__index__term'>
                        S.No
                    </div>
                    <div className='HubTabContent-container__index__locName HubTabContent-container__index__term'>
                        Location Name
                    </div>
                    <div className='HubTabContent-container__index__latitude HubTabContent-container__index__term' >
                        Latitude
                    </div>
                    <div className='HubTabContent-container__index__longitude HubTabContent-container__index__term'>
                        Longitude
                    </div>
                    <div className='HubTabContent-container__index__state HubTabContent-container__index__term'>
                        State
                    </div>
                    <div className='HubTabContent-container__index__address HubTabContent-container__index__term' >
                        Address
                    </div>
                    <div className='HubTabContent-container__index__pinCode HubTabContent-container__index__term'>
                        Pin Code
                    </div>
                    <div className='HubTabContent-container__index__noOfEmp HubTabContent-container__index__term'>
                        No of Employees
                    </div>
                    <div className='HubTabContent-container__index__noOfNodes HubTabContent-container__index__term'>
                      Nodes Present
                    </div>
                    {/* <div className='ModelTabContent-container__index__dateTime ModelTabContent-container__index__term' >
                        Date & Time
                    </div> */}

                </div>
                    {allHub.map((hub, i) => {
                       // console.log(allHub)
                        return <div key={hub._id} className='HubTabContent-datacontainer__row'>
                            <div className='HubTabContent-datacontainer__row__SNo HubTabContent-datacontainer__row__term'>
                                {i + 1}
                            </div>
                            <div className='HubTabContent-datacontainer__row__locName HubTabContent-datacontainer__row__term'>
                                {hub.location_name}
                            </div>
                            <div className='HubTabContent-datacontainer__row__latitude HubTabContent-datacontainer__row__term'>
                                {hub.latitude}
                            </div>
                            <div className='HubTabContent-datacontainer__row__longitude HubTabContent-datacontainer__row__term'>
                                {hub.longitude }
                            </div>
                            <div className='HubTabContent-datacontainer__row__state HubTabContent-datacontainer__row__term'>
                                {hub.state}
                            </div>
                            <div className='HubTabContent-datacontainer__row__address HubTabContent-datacontainer__row__term' title={hub.address}>
                                {hub.address}
                            </div>
                            <div className='HubTabContent-datacontainer__row__pinCode HubTabContent-datacontainer__row__term'>
                                {hub.pin_code}
                            </div>
                            <div className='HubTabContent-datacontainer__row__noOfEmp HubTabContent-datacontainer__row__term'>
                                {hub.employee.length}
                            </div>
                            <div className='HubTabContent-datacontainer__row__noOfNodes HubTabContent-datacontainer__row__term'>
                               {hub.locationArray.length}
                                <button className="HubTabContent-datacontainer__row__noOfNodes__options" onClick={()=>{setDetailsPopUp(true); setSelectedHub(hub.locationArray)}}>Details</button>
                                {/* <button className="ModelTabContent-datacontainer__row__noOfNodes__options" onClick={()=>{setDetailsPopUp(true); setSelectedHub(hub.locationArray)}}>Details</button> */}
                            </div>
                            {/* <div className='ModelTabContent-datacontainer__row__dateTime ModelTabContent-datacontainer__row__term'>
                                {dayjs(model.date).format("YYYY-MM-DD h:mm:ss").toString()}
                            </div> */}
                        </div>
                    })}
                </div>
            </div>
            <div className='AddNewHubButton'><button className='AddNewHubButton__Btn' onClick={() => { handleAddHubButton() }}>Add New Hub</button></div>
            {addHubForm ? <CreateHubPanel setFormPopup={setAddHubForm} setAllHub={setAllHub} allHub={allHub} allLocations={allLocations} userCookie={userCookie} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} adminData={adminData}
            setSelectedTab={setSelectedTab}/> : null}
            {detailsPopUp && <HubLocationDetails setDetailsPopUp={() => {setSelectedTab("Hubs"); setDetailsPopUp(false) }}  hubDetails={selectedHub} />}

        </div>
    )
}

export default HubTableTab