import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import FlightDetailsPopup from '../FlightDetailsPopup/FlightDetailsPopup';
import "./AllFlightsTabContent.scss"
import ChecklistInputForm from '../ChecklistInputForm/ChecklistInputForm';
import CustomAlertBox from '../CustomAlert/CustomAlertBox';
import CustomPopUpTemplete from '../CustomPopUpTemplete/CustomPopUpTemplete';
import { useSelector } from 'react-redux';
import { selectHubNodeLocations } from '../../ReduxStore/HubNodeLocationsSlice';
import axios from 'axios';
import { backEndIP, InventoryBackEndIP } from '../MyConfiguration';
import PaginationComponent from '../../Pagination/Pagination.tsx';
import ReschedulePopup from '../ReschedulePopup/ReschedulePopup.js';
import OrderDetailsTab from '../OrderDetailsTab/OrderDetailsTab.js';


const AllFlightsTabContent = ({ socket,
    isConnected,
    filterBy,
    setFilterBy,
    duration,
    setDuration,
    userCookie,
    sourceLocation,
    setSourceLocation,
    adminLocation,
    allLocations,
    handleNotifyAlert,
    SetLoadingVisibility }) => {
    const [ChartView, setchartView] = useState(false)
    const [allFlights, setAllFlights] = useState([]);
    const [flightDetailsVisibility, setFlightDetailsVisibility] = useState(false);
    const [flightDetails, setFlightDetails] = useState({});
    const [checkListDetailsPopup, setCheckListDetailsPopup] = useState(false)
    const [CheckListDetails, setCheckListDetails] = useState([])
    const [pageTitle, setPageTitle] = useState()
    const [searchValue, setSearchValue] = useState("")
    const locations = useSelector(selectHubNodeLocations)
    const [filterPopup, setFilterPopup] = useState(false)
    const [nodeLocation, setNodeLocation] = useState([])
    const [page, setCurrentPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(20)
    const [totalItems, setTotalItems] = useState(0)
    const [specificOrder, setSpecificOrder] = useState({});
        const [isPaylaodChanged, setIsPayLoadChanged]=useState(false)
    const [valueToFilter, setValueToFilter] = useState({
        filter: filterBy,
        duration: duration,
        sourceLocation: sourceLocation
    })
    const [isOrderDetailsPopup, setIsOrderDetailsPopup] = useState(false);
    const [orderDetailPopup, setOrderDetailPopup] = useState(false);
    const [selectedFlight, setSelectedFlight] = useState({});
    const [flightsGroupedByBookingId, setFlightsGroupedByBookingId] = useState(new Map());
    const [searchedFlights, setSearchedFlights] = useState([])
    const [timeOutId, setTimeOutId] = useState(null)
    const DurationOptions = {
        "Today": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
        "Last 3 days": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "day"),
        "Last 1 week": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "week"),
        "Last 2 weeks": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "week"),
        "Last 1 Month": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "month"),
        "Last 3 Months": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(3, "month"),
    };

    const flightType = [
        "ALL",
        "Order Flight",
        "Test Flight",
        "Forward Flight",
        "Return Flight"
    ]


    useEffect(() => {
        SetLoadingVisibility(true)
        console.log("valueToFilter.duration", valueToFilter.duration)
        const filterParameter = {
            filterBy: valueToFilter.filter,
            duration: valueToFilter.duration.d !== "custom" ? DurationOptions[valueToFilter.duration.d] : valueToFilter.duration,
            sourceLocation: valueToFilter.sourceLocation,
            page: page,
            itemPerPage: itemPerPage
        }
        console.log({
            "valueToFilter.duration": valueToFilter.duration,
            "filterBy": valueToFilter.filter,
            "duration": valueToFilter.duration.d !== "custom" ? DurationOptions[valueToFilter.duration.d] : valueToFilter.duration,
            "sourceLocation": valueToFilter.sourceLocation,
            "page": page,
            "itemPerPage": itemPerPage
        });
        axios({
            url: backEndIP + "/flight/get_all_flights",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: { ...filterParameter }
        }).then((resp) => {
            console.log("resp 123", resp.data)
            SetLoadingVisibility(false)
            setTotalItems(resp.data.length)
            setAllFlights(resp.data.data)
            setSearchedFlights(resp.data.data)
        }).catch((err) => {
            console.log(err)
            SetLoadingVisibility(false);
            // handleNotifyAlert(
            //     "notify",
            //     `${err.response.data.message}`,
            //     "failed",
            //     () => { }
            // );
        })

    }, [page, isPaylaodChanged, isOrderDetailsPopup]);

    function handleRescheduelPopup(completeFlight) {
        setOrderDetailPopup(true);
        setSelectedFlight(completeFlight)
    }

    useEffect(() => {
        axios({
            url: backEndIP + "/hub/hub-node-all-locations",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((hubResp) => {
            // console.log(hubResp.data)
            setNodeLocation(hubResp.data.data);
            //console.log(allHub)
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const handelSearchFlight = () => {
        // console.log("handelSearchFlight searchValue", searchValue)
        if (searchValue === "") {
            handleFilterChange()
        } else {
            const searchId = new RegExp(`.*${searchValue}.*`, 'i')
            const filteredDroneArray = allFlights.filter((item) => {
                return searchId.test(item.drone_id)
            })
            setTotalItems(filteredDroneArray.length)
            setSearchedFlights(filteredDroneArray)
        }
        setTimeOutId(null)
    }

    const debounceHandler = async () => {
        if (timeOutId !== null) {
            clearTimeout(timeOutId)
        }

        const timeOut = setTimeout(() => {
            handelSearchFlight()
        }, 500)
        setTimeOutId(timeOut)
    }

    async function getOrder(orderId, orderType) {
        try {
            SetLoadingVisibility(true);

            const response = await axios({
                url: InventoryBackEndIP + "/order/get-order-admin",
                method: "POST",
                headers: {
                    authorization: userCookie.token,
                },
                data: { orderId, orderType }
            });
            console.log("Response Data:", response.data);
            setSpecificOrder((prev) => response.data.data);
        } catch (err) {
            const errorMessage = err.response?.data?.message || "An error occurred";
            handleNotifyAlert("notify", errorMessage, "failed", () => { });
        } finally {
            SetLoadingVisibility(false);
        }
    }
   
    const handleFilterChange = () => {
        // console.log("valueToFilter", valueToFilter)
        // console.log("duration", DurationOptions[valueToFilter.duration.d])
        SetLoadingVisibility(true);
        const filterParameter = {
            filterBy: valueToFilter.filter,
            duration: valueToFilter.duration.d !== "custom" ? DurationOptions[valueToFilter.duration.d] : valueToFilter.duration,
            sourceLocation: valueToFilter.sourceLocation,
            page: page,
            itemPerPage: itemPerPage
        }
        // console.log("filterParameter", filterParameter)
        axios({
            url: backEndIP + "/flight/get_all_flights",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: { ...filterParameter }
        }).then((resp) => {
            console.log("resp 456", resp.data)
            setTotalItems(resp.data.length)
            setAllFlights(resp.data.data)
            setSearchedFlights(resp.data.data)
            SetLoadingVisibility(false);
        }).catch((err) => {
            // console.log(err)
            SetLoadingVisibility(false);
            handleNotifyAlert(
                "notify",
                `${err.response.data.message}`,
                "failed",
                () => { }
            );
            setSearchedFlights([])
            setAllFlights([])
            setTotalItems([])

        })
    }

    // useEffect(() => {

    //     const uniqueFlightsGroupedByBookingId = new Map();
    //     // const filterFlights = new Map();

    //     searchedFlights.forEach((flight) => {
    //         // console.log("Processing flight:", flight);

    //         const dronebookingId = flight.dronebookingId;

    //         if (!uniqueFlightsGroupedByBookingId.has(dronebookingId)) {
    //             uniqueFlightsGroupedByBookingId.set(dronebookingId, {
    //                 bookingId: dronebookingId,
    //                 orderId: flight.order_id,
    //                 orderNumber: flight.order_no,
    //                 droneId: flight.drone_id,
    //                 orderType: flight.order_type,
    //                 flights: [],
    //             });

    //             // if (!flight.isAborted) {
    //             //     filterFlights.set(dronebookingId, {
    //             //         bookingId: dronebookingId,
    //             //         orderId: flight.order_id,
    //             //         orderNumber: flight.order_no,
    //             //         droneId: flight.drone_id,
    //             //         orderType: flight.order_type,
    //             //         flights: [],
    //             //     });
    //             // }
    //         }

    //         const currentGroup = uniqueFlightsGroupedByBookingId.get(dronebookingId);
    //         // const filterCurrentGroup = filterFlights.get(dronebookingId);

    //         const isDuplicate = currentGroup.flights.some((existingFlight) => existingFlight._id === flight._id);
    //         if (!isDuplicate) {
    //             currentGroup.flights.push(flight);
    //         }

    //         // if (filterCurrentGroup) {
    //         //     const isFilteredDuplicate = filterCurrentGroup.flights.some((existingFlight) => existingFlight._id === flight._id);
    //         //     if (!isFilteredDuplicate) {
    //         //         filterCurrentGroup.flights.push(flight);
    //         //     }
    //         // }
    //     });

    //     // const droneBookingIds = Array.from(filterFlights.keys());
    //     // console.log("droneBookingIds:", droneBookingIds);

    //     // setBookingIds(droneBookingIds);
    //     // setShowReschedule(false);
    //     setFlightsGroupedByBookingId(uniqueFlightsGroupedByBookingId);
    //     // setCopyFlightsGroupedByBookingId(filterFlights);

    //     // console.log("flightsGroupedByBookingId:", uniqueFlightsGroupedByBookingId);
    //     // console.log("Filtered Booking Ids:", uniqueFlightsGroupedByBookingId);
    //     // console.log("copyFlightsGroupedByBookingId:", filterFlights);
    // }, [searchedFlights]);

    useEffect(() => {
        const uniqueFlightsGroupedByBookingId = new Map();
    
        searchedFlights.forEach((flight) => {
            // Use `dronebookingId` as the key; if null, fallback to `flight._id`
            const groupKey = flight.dronebookingId || flight._id;
    
            if (!uniqueFlightsGroupedByBookingId.has(groupKey)) {
                uniqueFlightsGroupedByBookingId.set(groupKey, {
                    bookingId: flight.dronebookingId, // Keep the original `dronebookingId` (even if null)
                    orderId: flight.order_id,
                    orderNumber: flight.order_no,
                    droneId: flight.drone_id,
                    orderType: flight.order_type,
                    flights: [],
                });
            }
    
            const currentGroup = uniqueFlightsGroupedByBookingId.get(groupKey);
    
            // Avoid adding duplicate flights
            const isDuplicate = currentGroup.flights.some((existingFlight) => existingFlight._id === flight._id);
            if (!isDuplicate) {
                currentGroup.flights.push(flight);
            }
        });
    
        setFlightsGroupedByBookingId(uniqueFlightsGroupedByBookingId);
    }, [searchedFlights]);
    
    const handelPageChange = (page) => {
        setCurrentPage(page)
    }
    useEffect(() => {
        // console.log("searchValue", searchValue)
        debounceHandler()
    }, [searchValue])

    console.log("valueToFilter", valueToFilter.duration)

    return (
        <div className='AllFlightsTabContent-container'>

            <div className='AllFlightsTabContent-container__filter'>
                <div className='AllFlightsTabContent-serach'>
                    <div className='AllFlightsTabContent-serach__box'>
                        <input className='AllFlightsTabContent-serach__box__input'
                            placeholder='Enter Drone Id'
                            onChange={(e) => { setSearchValue(e.target.value) }}
                            value={searchValue}
                        />
                        <img style={{ cursor: "pointer" }} src={`${backEndIP}/search.svg`} alt={"search"} />
                    </div>
                </div>
                <div className='AllFlightsTabContent-serach__dispalyParams'>
                    <div className='AllFlightsTabContent-serach__dispalyParams__title'>Flight Type:</div>
                    <select className='AllFlightsTabContent-serach__dispalyParams__select'
                        defaultValue={valueToFilter.filter}
                        onChange={(e) => {
                            valueToFilter.filter = e.currentTarget.value
                            setValueToFilter({ ...valueToFilter, ["filter"]: valueToFilter.filter })
                            setFilterBy(valueToFilter.filter)
                        }}
                    >
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>{valueToFilter.filter}</option>
                        {flightType.map((type, i) => {
                            if (type === valueToFilter.filter) {
                                return
                            }
                            return <option value={type} className='AllFlightsTabContent-serach__dispalyParams__select__option' key={i + 1}>{type}</option>
                        })}
                    </select>
                </div >
                <div className={valueToFilter.duration.d === "custom" ? 'AllFlightsTabContent-serach__dispalyParams__container' : 'AllFlightsTabContent-serach__dispalyParams'}>
                    <div className='AllFlightsTabContent-serach__dispalyParams__title'>Duration:</div>
                    {/* <div className={valueToFilter.duration.d === "custom" &&'AllFlightsTabContent-serach__dispalyParams__container'}> */}
                    <select className='AllFlightsTabContent-serach__dispalyParams__select'
                        defaultValue={valueToFilter.duration.d}
                        onChange={(e) => {
                            if (e.currentTarget.value === "custom") {
                                // setCustomDuration(true)
                                valueToFilter.duration.d = e.currentTarget.value
                                duration.d = e.currentTarget.value
                                setValueToFilter({ ...valueToFilter, ["duration"]: valueToFilter.duration })
                            } else {
                                // setCustomDuration(false)
                                valueToFilter.duration.d = e.currentTarget.value
                                duration.d = e.currentTarget.value
                                setValueToFilter({ ...valueToFilter, ["duration"]: valueToFilter.duration })
                                setDuration(duration)
                            }
                        }}
                    >
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Today</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 3 days</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 1 week</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 2 weeks</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 1 Month</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>Last 3 Months</option>
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option' value="custom">Custom Range</option>
                    </select>
                    {valueToFilter.duration.d === 'custom' && (
                        <div className='AllFlightsTabContent-serach__dispalyParams__input'>
                            <div className='AllFlightsTabContent-serach__dispalyParams__input__InputBox'>
                                <label className='AllFlightsTabContent-serach__dispalyParams__title'>From:</label>
                                <input
                                    type="date"
                                    value={valueToFilter.duration.details.from}
                                    onChange={(e) => {
                                        valueToFilter.duration.details.from = e.currentTarget.value
                                        duration.details.to = e.currentTarget.value
                                        setValueToFilter({ ...valueToFilter, ["duration.from"]: valueToFilter.duration.details.from })
                                        setDuration(duration)
                                    }}
                                    className='AllFlightsTabContent-serach__dispalyParams__selectFrom'
                                />
                            </div>
                            <div className='AllFlightsTabContent-serach__dispalyParams__input__InputBox'>
                                <label className='AllFlightsTabContent-serach__dispalyParams__title'>To:</label>
                                <input
                                    type="date"
                                    value={valueToFilter.duration.details.to}
                                    onChange={(e) => {
                                        valueToFilter.duration.details.to = e.currentTarget.value
                                        duration.details.to = e.currentTarget.value
                                        setValueToFilter({ ...valueToFilter, ["duration.to"]: valueToFilter.duration.details.to })
                                        setDuration(duration)
                                    }}
                                    className='AllFlightsTabContent-serach__dispalyParams__selectTo'
                                />
                            </div>
                        </div>
                    )}
                    {/* </div> */}
                </div>

                <div className='AllFlightsTabContent-serach__dispalyParams'>
                    <div className='AllFlightsTabContent-serach__dispalyParams__title'>Source Location:</div>
                    <select className='AllFlightsTabContent-serach__dispalyParams__select'
                        defaultValue={valueToFilter.sourceLocation === "ALL" || valueToFilter.sourceLocation === adminLocation ? "ALL" : locations[valueToFilter.sourceLocation].name}
                        // value={"All"}
                        onChange={(e) => {
                            // console.log("E.target.value", e.currentTarget.value)
                            valueToFilter.sourceLocation = e.currentTarget.value
                            setValueToFilter({ ...valueToFilter, ["sourceLocation"]: valueToFilter.sourceLocation })
                            setSourceLocation(e.currentTarget.value)
                        }}
                    >
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option'>{valueToFilter.sourceLocation === "ALL" || valueToFilter.sourceLocation === adminLocation ? "ALL" : locations[valueToFilter.sourceLocation].name}</option>
                        {nodeLocation.map((item, i) => {
                            // console.log("item", item)
                            return <option className='AllFlightsTabContent-serach__dispalyParams__select__option' value={item._id} key={i + 1}>{item.name}</option>
                        })}
                        <option className='AllFlightsTabContent-serach__dispalyParams__select__option' value="ALL" key={0}>ALL</option>
                    </select>
                </div>
                <div className='AllFlightsTabContent-serach__filterBox'>
                    <div className='AllFlightsTabContent-serach__filterBox__button' onClick={handleFilterChange}>Apply</div>
                </div>
                {/* <div className="AllFlightsTabContentserach__selectView">
                    <div className="AllFlightsTabContentserach__selectView__Update__options-container">
                        <input type="radio" name="approval" id="chartViewRadio" value={"Chart View"} checked={ChartView} onClick={(e) => { setchartView(true) }} />
                        <label className="AllFlightsTabContentserach__selectView__Update__options-container__inputLabel" for="chartViewRadio">Chart View</label>
                    </div>
                    <div className="AllFlightsTabContentserach__selectView__Update__options-container">
                        <input type="radio" name="approval" id="tableViewRadio" value={"Table View"} checked={!ChartView} onClick={(e) => { setchartView(false) }} />
                        <label className="AllFlightsTabContentserach__selectView__Update__options-container__inputLabel" for="tableViewRadio">Table View</label>
                    </div>
                </div> */}
            </div>
            <div className='AllFlightsTabContent-datacontainer'>
                <div className='AllFlightsTabContent-container__index'>
                    <div className='AllFlightsTabContent-container__index__start-time AllFlightsTabContent-container__index__term'>
                        Order Id
                    </div>
                    <div className='AllFlightsTabContent-container__index__flight-type AllFlightsTabContent-container__index__term'>
                        Type
                    </div>
                    <div className='AllFlightsTabContent-container__index__drone AllFlightsTabContent-container__index__term'>
                        Drone Id
                    </div>
                    <div className='AllFlightsTabContent-container__index__source AllFlightsTabContent-container__index__term'>
                        Source
                    </div>
                    <div className='AllFlightsTabContent-container__index__destination AllFlightsTabContent-container__index__term'>
                        Destination
                    </div>
                    <div className='AllFlightsTabContent-container__index__payload AllFlightsTabContent-container__index__term'>
                        Payload
                    </div>
                    <div className='AllFlightsTabContent-container__index__distance AllFlightsTabContent-container__index__term'>
                        Distance
                    </div>
                    <div className='AllFlightsTabContent-container__index__flight-date AllFlightsTabContent-container__index__term'>
                        Scheduled Date
                    </div>

                    <div className='AllFlightsTabContent-container__index__time-taken AllFlightsTabContent-container__index__term'>
                        Time Taken
                    </div>
                    <div className='AllFlightsTabContent-container__index__order-details AllFlightsTabContent-container__index__term'>
                        Flight Actions
                    </div>

                </div>
                {flightsGroupedByBookingId.size <= 0 ? "No Flight Found" : <>
                    {[...flightsGroupedByBookingId.values()].map((groupedFlight, index) => {
                        return (<div className='AllFlightsTabContent-datacontainer__row-container'>
                            <div className='AllFlightsTabContent-datacontainer__row-container__p1 AllFlightsTabContent-datacontainer__row-container__term'
                           style={{
                            cursor: groupedFlight.orderId !== "null" ? "pointer" : "default",
                            ...(groupedFlight.orderId !== "null"
                              ? { transition: "color 0.3s", ":hover": { color: "#FD6125" } }
                              : {}),
                          }}
                                onClick={async () => {
                                    if(groupedFlight.orderId !=="null" ){
                                    await getOrder(groupedFlight.orderId, groupedFlight.orderType)
                                    setIsOrderDetailsPopup(true);
                                    }
                                    // handleRescheduelPopup(groupedFlight) 
                                }}>
                                {groupedFlight.orderId !=="null" ? groupedFlight.orderId : "-"}
                            </div>
                            <div className='AllFlightsTabContent-datacontainer__row-container__p2'>
                                {groupedFlight.flights.map(flight =>
                                (<div key={flight._id} className={`${flight.isCompleted ? "AllFlightsTabContent-datacontainer__row-containerGreen" : flight.isAborted ? "AllFlightsTabContent-datacontainer__row-containerRed" : "AllFlightsTabContent-datacontainer__row-container__p2__flight"}`}>

                                    <div className='AllFlightsTabContent-datacontainer__row-container__p2__flight-type AllFlightsTabContent-datacontainer__row-container__p2__term'>
                                        {flight.flight_type}
                                    </div>
                                    <div className='AllFlightsTabContent-datacontainer__row-container__p2__drone AllFlightsTabContent-datacontainer__row-container__p2__term'>
                                        {flight.drone_id}
                                    </div>
                                    <div className='AllFlightsTabContent-datacontainer__row-container__p2__source AllFlightsTabContent-datacontainer__row-container__p2__term'>
                                        {locations[flight.start_location]?.name}
                                    </div>
                                    <div className='AllFlightsTabContent-datacontainer__row-container__p2__destination AllFlightsTabContent-datacontainer__row-container__p2__term'>
                                        {locations[flight.end_location]?.name}
                                    </div>
                                    <div className='AllFlightsTabContent-datacontainer__row-container__p2__payload AllFlightsTabContent-datacontainer__row-container__p2__term'>
                                        {flight.payload}g
                                    </div>
                                    <div className='AllFlightsTabContent-datacontainer__row-container__p2__distance AllFlightsTabContent-datacontainer__row-container__p2__term'>
                                        {(flight.distance_coverved / 1000).toFixed(2)}Km
                                    </div>
                                    <div className='AllFlightsTabContent-datacontainer__row-container__p2__flight-date AllFlightsTabContent-datacontainer__row-container__p2__term'>
                                        {dayjs(flight.scheduleDetails.date).format("YYYY-MM-DD HH:mm").toString()}
                                    </div>

                                    <div className='AllFlightsTabContent-datacontainer__row-container__p2__time-taken AllFlightsTabContent-datacontainer__row-container__p2__term'>
                                        {dayjs().set('hour', 0).set('minute', 0).set('second', 0).add(flight.time_taken, 'second').format('HH:mm:ss')}
                                    </div>
                                    <div className='AllFlightsTabContent-datacontainer__row-container__p2__order-details AllFlightsTabContent-datacontainer__row-container__p2__term'>
                                        <button className='AllFlightsTabContent-datacontainer__row-container__p2__order-details__btn'
                                            onClick={() => { setFlightDetails(flight); setFlightDetailsVisibility(true) }}
                                        >Details</button>
                                    </div>

                                </div>)

                                )} </div> </div>)
                    })}</>
                }
            </div>
            <div className='AllFlightsTabContent-container__pagination'>
                <PaginationComponent
                    totalItems={totalItems}
                    itemsPerPage={itemPerPage}
                    onPageChange={handelPageChange}
                    currentPage={page}
                /></div>

            {orderDetailPopup && <ReschedulePopup
                setHandelReschedulePopup={setOrderDetailPopup}
                locations={locations}
                selectedFlight={selectedFlight}
                handleNotifyAlert={handleNotifyAlert}
                userCookie={userCookie}
                SetLoadingVisibility={SetLoadingVisibility}
            />}
            {flightDetailsVisibility && <FlightDetailsPopup
                setOpenModal={setFlightDetailsVisibility}
                setFlightDetails={setFlightDetails}
                userCookie={userCookie}
                flight={flightDetails}
                setCheckListDetailsPopup={setCheckListDetailsPopup}
                setCheckListDetails={setCheckListDetails}
                setPageTitle={setPageTitle}
                handleNotifyAlert={handleNotifyAlert}
                allLocations={locations}
                checkListDetailsPopup={checkListDetailsPopup}
                pageTitle={pageTitle}
                CheckListDetails={CheckListDetails}
                socket={socket}
                SetLoadingVisibility={SetLoadingVisibility}
                setIsPayLoadChanged={setIsPayLoadChanged} 
                />}
            {isOrderDetailsPopup  &&
                //  <CustomPopUpTemplete setOpenModal={setOrderDetailsVisibility} content={
                <OrderDetailsTab
                    setShowPopup={setIsOrderDetailsPopup}
                    item={specificOrder}
                    SetLoadingVisibility={SetLoadingVisibility}
                    // cancelStatusUpdate={() => { socket.emit('client:cancel-order', orderDetails) }}
                    handleNotifyAlert={handleNotifyAlert}
                    userCookie={userCookie}
                // allNewPanelLog={allNewPanelLog}
                // updateOrdersPage={updateOrdersPageData} 
                />
                // }/>
            }

        </div>
    )
}

export default AllFlightsTabContent