import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "./ViewFlightsDashboard.scss";
import axios from 'axios';
import { backEndIP, InventorySocketIP } from '../../components/MyConfiguration';
import { Avatar, AvatarGroup, Dropdown, Tree } from 'rsuite';
import { Cookies } from 'react-cookie';
import CustomAlertBox from '../../components/CustomAlert/CustomAlertBox';
// import { customSorting } from './designationBasedModules';
import { io } from 'socket.io-client';
import { logoutAction } from '../../components/utility';
import { customSorting } from '../../Pages/DashboardPage/designationBasedModules';

// const allPagesData = {
//     "Monitor Drone": { pageUrl: "monitor", imgUrl: "./MonitorDroneCard.png" },
//     "View All Drones": { pageUrl: "view-all-drones", imgUrl: "./ViewAllDronesCard.png" },
//     "Monitor All Drones": { pageUrl: "monitor-all-drones", imgUrl: "./MonitorAllDronesCard.png" },
//     "View Flight": { pageUrl: "view-flight", imgUrl: "./ViewFlightCard.png" },
//     "Admin Panel": { pageUrl: "adminpanel", imgUrl: "./AdminPanelCard.png" },
//     "Airspace": { pageUrl: "airspace", imgUrl: "./airspace.png" },
// }
const allPagesData = {
    "VIEW FLIGHT": { pageUrl: "view-flight", imgUrl: `${backEndIP}/ViewFlightCard.png` },
    "VIEW ALL DRONE": { pageUrl: "view-all-drones", imgUrl: `${backEndIP}/ViewAllDronesCard.png` },
    "MONITOR ALL DRONE": { pageUrl: "monitor-all-drones", imgUrl: `${backEndIP}/MonitorAllDronesCard.png` }

}

const panelUrlMap = {
    "VIEW ALL DRONE": "view-all-drones",
    "VIEW FLIGHT": "view-flight",
    // "ORDER MANAGEMENT": "ordermanagement",
    // "MONITOR ALL DRONE": "monitor-all-drones",
    // "MONITOR DRONE": "monitor",
    // "FLEET MANAGEMENT": "fleetmanagement",
}
// const allPages = [
//     "Monitor Drone",
//     "Monitor All Drones",
//     "Admin Panel",
//     "View All Drones",
//     "View Flight",
//     "Airspace"
// ]
const allPages = [
    "OPERATIONS MANAGEMENT",
    "ORDER MANAGEMENT",
    "FLEET MANAGEMENT",
    "DRONE VIEW & TRACKING",
    // "AIRSPACE"
]
const moduleIndex = {
    // "FLEET MANAGEMENT": 0,
    "OPERATIONS MANAGEMENT": 0,
    "ORDER MANAGEMENT": 1,
    "FLEET MANAGEMENT": 2,
    "DRONE VIEW & TRACKING": 3,
    // "AIRSPACE"
}
const dashboardTree = [

    {
        label: "VIEW FLIGHT",
        children: [],
    },
    {
        label: "VIEW ALL DRONE",
        children: [],
    },
    {
        label: "MONITOR ALL DRONE",
        children: [],
    }
]


const DashboardPageCard = ({ title, imgUrl, navigateTo, onClick, activeModel, setActiveModule }) => {
    const navigate = useNavigate();

    console.log("title", title)
    return (
        <div className={`${imgUrl ? "DashboardPage-PageCard-containerWithImage" : "DashboardPage-PageCard-container"} ${activeModel === title ? "DashboardPage-PageCard-container__active" : ""}`}
            onClick={() => {
                if (navigateTo) {
                    navigate("/" + navigateTo)
                    // setActiveModule(title)
                } else if (onClick) {
                    onClick()
                    // setActiveModule(title)
                }
                setActiveModule(title)
            }} >
            {imgUrl ? <img className='DashboardPage-PageCard-container__image' src={imgUrl} alt={title} /> : null}
            {imgUrl ? null : <div className='DashboardPage-PageCard-container__title'>{title ? title : null}</div>}
        </div>
    )
}
const ViewFlightDashboard = ({ userCookie }) => {
    const navigate = useNavigate();
    const [userAvailableModules, setUserAvailableModules] = useState([])
    const [socket, setSocket] = useState(io(InventorySocketIP, {
        autoConnect: false,
        transports: ["websocket"],
        auth: {
            token: userCookie.token
        }
    }))
    // const [userInitial, setUserInitial] = useState(userCookie.username[0].toLocaleUpperCase())
    const [activeModule, setActiveModule] = useState(null);
    // useEffect(() => {
    //     axios(`${backEndIP}/designation/get-my-modules`, {
    //         method: "GET",
    //         headers: { authorization: userCookie.token }
    //     }).then(designationsResp => {
    //         // console.log("designationsResp", designationsResp?.data)
    //         if (Array.isArray(designationsResp?.data?.data)) {
    //             const availabelTabs = customSorting(designationsResp?.data?.data, allPages, "label")
    //             setUserAvailableModules(availabelTabs);
    //             if (designationsResp?.data?.data.length === 1 && designationsResp?.data?.data) {
    //                 navigate("/" + allPagesData[designationsResp?.data?.data[0]].pageUrl)
    //             }
    //         }
    //     }).catch(error => {
    //         console.log(error?.response?.data)
    //     })
    // }, [userCookie.token])

    const renderToggle = props => (
        <Avatar circle {...props} src="" style={{ backgroundColor: "#FD6125" }} />
    );

    const handleBackToMain = () => {
        setActiveModule(null);
    };
    useEffect(() => {
        // const availabelTabs = customSorting(userAvailableModules, allPages, "label")
        // console.log("availabelTabs", availabelTabs)
        const availableTabs = [
            {
                label: "VIEW FLIGHT",
                children: [],
            },
            {
                label: "VIEW ALL DRONE",
                children: [],
            },
            {
                label: "MONITOR ALL DRONE",
                children: [],
            }
        ]
        setUserAvailableModules(availableTabs)
    }, [])
    console.log("userAvailableModules", userAvailableModules)
    return (
        <div className='ViewFlightsDashboard-container'>
            <div className='ViewFlightsDashboard-container__RightContainer'>
                <div className='ViewFlightsDashboard-container__midCont'>

                    {/* {allPages.map((page, i) => {
                      const pageData = allPagesData[page] 
                            return <ViewFlightsDashboardCard key={page + i} title={page} navigateTo={pageData.pageUrl} imgUrl={pageData.imgUrl} subModules={pageData.subPages}/>
                        
                    })} */}
                    {activeModule ? (
                        <div className='ViewFlightsDashboard-container__SubModulesView'>
                            {/* <div className='ViewFlightsDashboard-container__SubModulesView__header'>
                                <button
                                    className='ViewFlightsDashboard-container__BackButton'
                                    onClick={handleBackToMain}>
                                    <svg width="25" height="20" viewBox="0 0 15 9" fill="var(--primaryTextColor)" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L5.94777 7.08957C6.74816 8.07465 8.25185 8.07465 9.05223 7.08957L14 1" stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <h2>{activeModule}</h2>
                            </div> */}
                            <div className='ViewFlightsDashboard-container__SubModulesView__body'>
                                <div className='ViewFlightsDashboard-container__CardContainer'>
                                    {userAvailableModules[moduleIndex[activeModule]].children.map(
                                        (page, i) => {
                                            console.log("userAvailableModules[moduleIndex[activeModule]].children", i, page, allPagesData[activeModule]["subPages"])
                                            return <DashboardPageCard
                                                activeModel={activeModule}
                                                setActiveModule={setActiveModule}
                                                key={`${page.label}-${i}`}
                                                title={page.label}
                                                navigateTo={allPagesData[activeModule]["subPages"][page.label].pageUrl}
                                                imgUrl={allPagesData[activeModule]["subPages"][page.label].imgUrl}
                                            />
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : <div className='ViewFlightsDashboard-container__CardContainer'>

                        {userAvailableModules.map((page, i) => {
                            console.log("page new", page)
                            const pageData = allPagesData[page.label];
                            return (
                                <DashboardPageCard
                                    activeModel={activeModule}
                                    setActiveModule={setActiveModule}
                                    key={`${page.label}-${i}`}
                                    title={page.label}
                                    imgUrl={pageData.imgUrl}
                                    navigateTo={pageData.pageUrl}
                                    onClick={
                                        pageData.subPages
                                            ? () => setActiveModule(page.label)
                                            : null
                                    }
                                />
                            );
                        })}
                    </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default ViewFlightDashboard