// import dayjs from 'dayjs';
// import React, { useEffect, useRef, useState } from 'react';
// import FlightDetailsPopup from '../FlightDetailsPopup/FlightDetailsPopup';
// import "./DailyPlans.scss"
// import ChecklistInputForm from '../ChecklistInputForm/ChecklistInputForm';
// import CustomAlertBox from '../CustomAlert/CustomAlertBox';
// import CustomPopUpTemplete from '../CustomPopUpTemplete/CustomPopUpTemplete';
// import { Dropdown, DatePicker, InputGroup, Table } from 'rsuite';
// import { useSelector } from 'react-redux';
// import { selectHubNodeLocations } from '../../ReduxStore/HubNodeLocationsSlice';
// import axios from 'axios';
// import { backEndIP, InventoryBackEndIP } from '../MyConfiguration';
// import PaginationComponent from '../../Pagination/Pagination.tsx';
// import ReschedulePopup from '../ReschedulePopup/ReschedulePopup.js';
// import { fetchOrderDeliverySlots } from '../../utility/fetchOrderDeliverySlots.js';
// import { useNavigate } from 'react-router-dom';
// import UrgentOrders from '../UrgentOrders/UrgentOrders.js';
// import OrderDetailsTab, { SchedulePopup } from '../OrderDetailsTab/OrderDetailsTab.js';


// const DailyPlans = ({ socket,
//     isConnected,
//     userCookie,
//     sourceLocation,
//     setSourceLocation,
//     adminLocation,
//     allLocations,
//     handleNotifyAlert,
//     SetLoadingVisibility, user }) => {

//     // const [allFlights, setAllFlights] = useState([]);
//     const [flightDetailsVisibility, setFlightDetailsVisibility] = useState(false);
//     const [flightDetails, setFlightDetails] = useState({});
//     const [checkListDetailsPopup, setCheckListDetailsPopup] = useState(false)
//     const [CheckListDetails, setCheckListDetails] = useState([])
//     const [pageTitle, setPageTitle] = useState()
//     const [isOrderDetailsPopup, setIsOrderDetailsPopup] = useState(false);
//     const [flightSort, setFlightSort] = useState("Scheduled Date");

//     // const [searchValue, setSearchValue] = useState("")
//     const locations = useSelector(selectHubNodeLocations)
//     const [isUrgentOrders, setIsUrgentOrders] = useState(true)
//     // const [filterPopup, setFilterPopup] = useState(false)
//     const [nodeLocation, setNodeLocation] = useState([])
//     const [page, setCurrentPage] = useState(1)
//     const [itemPerPage, setItemPerPage] = useState(20)
//     const [totalItems, setTotalItems] = useState(0)
//     const [selectedCondition, setSelectedCondition] = useState("Today");
//     const [duration, setDuration] = useState(dayjs().set('hour', 0).set('minute', 0).set('second', 0).millisecond(0))
//     const [customDate, setCustomDate] = useState("")
//     const [flightsGroupedByBookingId, setFlightsGroupedByBookingId] = useState(new Map());
//     const [copyFlightsGroupedByBookingId, setCopyFlightsGroupedByBookingId] = useState(new Map());
//     const [searchedFlights, setSearchedFlights] = useState([])
//     const [bookingIds, setBookingIds] = useState([]);
//     const [showReshedule, setShowReschedule] = useState(false);
//     const [handelReschedulePopup, setHandelReschedulePopup] = useState(false);
//     const [selectedFlight, setSelectedFlight] = useState({});
//     const [available, setAvailableOrderSlots] = useState([])
//     const [selectedOrder, setSelectedOrder] = useState({});
//     const rescheduleModalRef = useRef();
//     // const [isUrgentOrderNeedToShow, setIsUrgentOrdersNeedTohow]=useState(true);
//     const [schedulePopup, setSchedulePopup] = useState(false)
//     const [urgentOrders, setUrgentOrders] = useState([]);
//     const filters = ["Scheduled Date", "DroneId"]
//     const closeScheduleModal = e => {
//         if (rescheduleModalRef.current === e.target) {
//             setSchedulePopup(false);
//         }
//     };
//     function closeSchedulePopup() {
//         setSchedulePopup(false);
//     }
//     function handleSchedulePopup() {
//         setSchedulePopup(true)
//     }
//     const [orders, setOrders] = useState([])

//     const DurationOptions = {
//         "Today": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
//         "Last 3 days": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "day"),
//         "Last 1 week": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "week"),
//         "Last 2 weeks": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "week"),
//         "Last 1 Month": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "month"),
//         "Last 3 Months": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(3, "month"),
//     };
//     // const today = dayjs().startOf('day');
//     const [isAborted, setIsAborted] = useState(false);
//     const navigate = useNavigate();
//     // console.log(userCookie)
//     const handleCheckboxChange = (e) => {
//         setIsAborted(e.target.checked);
//         // console.log("Aborted Flight:", e.target.checked);
//     };

//     // useEffect(()=>{

//     // fetchOrderDeliverySlots(userCookie, setAvailableOrderSlots, handleNotifyAlert)

//     // }, [duration])
//     function getMyFlights() {
//         SetLoadingVisibility(true)
//         if (customDate || duration) {
//             axios({
//                 url: backEndIP + "/flight/getMyFlights",
//                 method: "POST",
//                 headers: {
//                     authorization: userCookie.token,
//                 },
//                 data: { date: selectedCondition === "Datepicker" ? customDate : duration }
//             }).then((resp) => {
//                 // console.log("resp", resp.data)
//                 SetLoadingVisibility(false)
//                 setTotalItems(resp.data.length)
//                 setSearchedFlights(resp.data.data)
//             }).catch((err) => {
//                 console.log(err)
//                 SetLoadingVisibility(false);
//                 // console.log("err.response.data.message", err.response.data.message);
//                 // handleNotifyAlert(
//                 //     "notify",
//                 //     `${err.response.data.message}`,
//                 //     "failed",
//                 //     () => { }
//                 // );
//                 setSearchedFlights([])
//             })
//         } else {
//             SetLoadingVisibility(false);
//         }
//     }
//     useEffect(() => {
//         getMyFlights()
//     }, [page, duration, customDate])

//     useEffect(() => {
//         // console.log("function is working weferwe");
//         if (userCookie.accessRole === "Hub Manager") {
//             getMyFlights()
//         }
//     }, [handelReschedulePopup, schedulePopup])
//     useEffect(() => {
//         axios({
//             url: backEndIP + "/hub/hub-node-all-locations",
//             method: "GET",
//             headers: {
//                 authorization: userCookie.token
//             },
//         }).then((hubResp) => {
//             // console.log(hubResp.data)
//             setNodeLocation(hubResp.data.data);
//             //console.log(allHub)
//         }).catch((err) => {
//             console.log(err);
//         })
//     }, [])

//     function handleApplyReschedule() {
//         // const droneBookingIds = Array.from(copyFlightsGroupedByBookingId.keys());
//         // const droneBookingIds = allBookingIds.map(([key]) => key);
//         const sortedFilteredFlights = filterDroneBookingIds();
//         const droneBookingIds = Array.from(sortedFilteredFlights.keys());
//         console.log("droneBookingIds", droneBookingIds);
//         SetLoadingVisibility(true)
//         if (userCookie.accessRole === "Hub Manager") {
//             axios({
//                 url: backEndIP + "/flight/update-flight-bookings",
//                 method: "POST",
//                 headers: {
//                     authorization: userCookie.token,
//                 },
//                 data: { allBookingIds: droneBookingIds }
//             }).then((resp) => {
//                 // console.log("resp", resp.data)
//                 SetLoadingVisibility(false)
//                 setTotalItems(resp.data.length)
//                 setSearchedFlights(resp.data.data)
//             }).catch((err) => {
//                 console.log(err)
//                 SetLoadingVisibility(false);
//                 handleNotifyAlert(
//                     "notify",
//                     `${err.response.data.message}`,
//                     "failed",
//                     () => { }
//                 );
//                 // setSearchedFlights([])
//             })
//         }
//     }
//     function handleStart() {
//         navigate("/monitor");
//     }
//     function handleChangeDateSelection(value) {
//         setSelectedCondition("Datepicker")
//         setCustomDate(value);
//         setDuration("");
//     }
//     function handleDayChange(day) {
//         setCustomDate("");
//         if (day === "Today") {
//             setDuration(dayjs().set('hour', 0).set('minute', 0).set('second', 0).millisecond(0))
//         } else if (day === "Tommorrow") {
//             setDuration(dayjs().add(1, "day").set('hour', 0).set('minute', 0).set('second', 0).millisecond(0))
//         }
//         setSelectedCondition(day)
//     }
//     function getAllOrder() {

//         if(userCookie.accessRole!=="Drone Pilot"){
//         axios({
//             url: InventoryBackEndIP + "/order/get-all-order",
//             method: "POST",
//             headers: {
//                 authorization: userCookie.token,
//             },
//             data: { date: DurationOptions["Last 1 week"] }
//         }).then((resp) => {
//             // console.log("reshdfgahrdfgrp", resp.data)
//             SetLoadingVisibility(false)
//             setOrders(resp.data.data)
//             const urgentOrders = resp.data.data.filter((order) => order.delivery_type === "Urgent" && !order.droneBookingId);
//             console.log("urgentOrders", urgentOrders);
//             setUrgentOrders(urgentOrders);
//         }).catch((err) => {
//             console.log(err)
//             SetLoadingVisibility(false);
//             // console.log("err.response.data.message", err?.response?.data?.message);
//             // handleNotifyAlert(
//             //     "notify",
//             //     `${err?.response?.data?.message ? err?.response?.data?.message : "OMS Server error"}`,
//             //     "failed",
//             //     () => { }
//             // );
//         })
//     }
//     }
//     useEffect(() => {
//         getAllOrder()
//     }, [schedulePopup])
//     useEffect(() => {
//         const uniqueFlightsGroupedByBookingId = new Map();
//         const filterFlights = new Map();

//         searchedFlights.forEach((flight) => {
//             // console.log("Processing flight:", flight);

//             const dronebookingId = flight.dronebookingId;


//             if (!uniqueFlightsGroupedByBookingId.has(dronebookingId)) {
//                 uniqueFlightsGroupedByBookingId.set(dronebookingId, {
//                     bookingId: dronebookingId,
//                     orderId: flight.order_id,
//                     orderNumber: flight.order_no,
//                     droneId: flight.drone_id,
//                     orderType: flight.order_type,
//                     flights: [],
//                 });

//                 if (!flight.isAborted) {
//                     filterFlights.set(dronebookingId, {
//                         bookingId: dronebookingId,
//                         orderId: flight.order_id,
//                         orderNumber: flight.order_no,
//                         droneId: flight.drone_id,
//                         orderType: flight.order_type,
//                         flights: [],
//                     });
//                 }
//             }

//             const currentGroup = uniqueFlightsGroupedByBookingId.get(dronebookingId);
//             const filterCurrentGroup = filterFlights.get(dronebookingId);

//             const isDuplicate = currentGroup.flights.some((existingFlight) => existingFlight._id === flight._id);
//             if (!isDuplicate) {
//                 currentGroup.flights.push(flight);
//             }

//             if (filterCurrentGroup) {
//                 const isFilteredDuplicate = filterCurrentGroup.flights.some((existingFlight) => existingFlight._id === flight._id);
//                 if (!isFilteredDuplicate) {
//                     filterCurrentGroup.flights.push(flight);
//                 }
//             }
//         });

//         const droneBookingIds = Array.from(filterFlights.keys());
//         // console.log("droneBookingIds:", droneBookingIds);

//         setBookingIds(droneBookingIds);
//         setShowReschedule(false);
//         setFlightsGroupedByBookingId(uniqueFlightsGroupedByBookingId);
//         setCopyFlightsGroupedByBookingId(filterFlights);

//         // console.log("flightsGroupedByBookingId:", uniqueFlightsGroupedByBookingId);
//         // console.log("Filtered Booking Ids:", uniqueFlightsGroupedByBookingId);
//         // console.log("copyFlightsGroupedByBookingId:", filterFlights);
//     }, [searchedFlights]);

//     function handleFilter(value) {
//         let sortedFilteredFlights;
//         if (value === "Scheduled Date") {
//             sortedFilteredFlights = sortFlightsByScheduleDate(copyFlightsGroupedByBookingId)
//         } else if (value === "DroneId") {
//             sortedFilteredFlights = sortFlightsByDroneId(copyFlightsGroupedByBookingId)
//         }
//         setCopyFlightsGroupedByBookingId(sortedFilteredFlights);
//     }

//     useEffect(() => {
//         if (isAborted) {
//             const sortedFilteredFlights = sortFlights(flightsGroupedByBookingId, isAborted)
//             setCopyFlightsGroupedByBookingId(sortedFilteredFlights);
//         }
//         handleFilter(flightSort)
//     }, [bookingIds]);

//     useEffect(() => {
//         if (!flightsGroupedByBookingId) return;
//         let sortedFilteredFlights = sortFlights(flightsGroupedByBookingId, isAborted)

//         if (flightSort === "Scheduled Date") {
//             sortedFilteredFlights = sortFlightsByScheduleDate(sortedFilteredFlights);
//         } else if (flightSort === "DroneId") {
//             sortedFilteredFlights = sortFlightsByDroneId(sortedFilteredFlights);
//         }
//         setCopyFlightsGroupedByBookingId(sortedFilteredFlights);
//         // console.log("Sorted filtered flights:", sortedFilteredFlights);
//     }, [isAborted]);


//     function filterDroneBookingIds() {
//         // if (!flightsGroupedByBookingId) return new Map();
//         if (!copyFlightsGroupedByBookingId) return new Map();

//         return new Map(
//             Array.from(copyFlightsGroupedByBookingId.entries())
//                 .filter(([id, group]) => {
//                     // Exclude groups where any flight is complete
//                     // console.log("group", group);
//                     const hasCompletedFlight = group.flights.some((flight) => flight.isCompleted
//                 );
//                     if (hasCompletedFlight) return false;

//                     // Filter out flights that are aborted
//                     const hasAbortedFlight = group.flights.some((flight) => flight.isAborted);
//                     if (hasAbortedFlight) return false;
//                     // const filteredFlights = group.flights.filter((flight) => !flight.isAborted);

//                     // Include the group only if there are remaining flights
//                     // return filteredFlights.length > 0;
//                     return true;
//                 })
//                 .map(([id, group]) => [
//                     id,
//                     { ...group, flights: group.flights.filter((flight) => !flight.isAborted) }
//                 ])
//         );
//     }

//     const sortFlights = (flightsGroupedByBookingId, isAborted) => {
//         if (!flightsGroupedByBookingId) return new Map();

//         return new Map(
//             Array.from(flightsGroupedByBookingId.entries())
//                 .map(([id, group]) => {
//                     const filteredFlights = isAborted
//                         ? group.flights
//                         : group.flights.filter((flight) => !flight.isAborted);

//                     return filteredFlights.length > 0
//                         ? [id, { ...group, flights: filteredFlights }]
//                         : null;
//                 })
//                 .filter(Boolean)
//                 .sort(([idA, groupA], [idB, groupB]) => {
//                     const groupAHasAborted = groupA.flights.some((flight) => flight.isAborted);
//                     const groupBHasAborted = groupB.flights.some((flight) => flight.isAborted);

//                     return groupAHasAborted === groupBHasAborted
//                         ? 0
//                         : groupAHasAborted
//                             ? 1
//                             : -1;
//                 })
//         );
//     };

//     const sortFlightsByScheduleDate = (flightsGroupedByBookingId, isAborted) => {
//         if (!flightsGroupedByBookingId) return new Map();

//         return new Map(
//             Array.from(flightsGroupedByBookingId.entries())
//                 .sort(([idA, groupA], [idB, groupB]) => {
//                     const groupAHasAborted = groupA.flights.some((flight) => flight.isAborted);
//                     const groupBHasAborted = groupB.flights.some((flight) => flight.isAborted);

//                     if (groupAHasAborted !== groupBHasAborted) {
//                         return groupAHasAborted ? 1 : -1;
//                     }

//                     const dateA = new Date(groupA.flights[0].scheduleDetails.date);
//                     const dateB = new Date(groupB.flights[0].scheduleDetails.date);

//                     return dateA - dateB;
//                 })
//         );
//     };

//     const sortFlightsByDroneId = (flightsGroupedByBookingId, isAborted) => {
//         if (!flightsGroupedByBookingId) return new Map();

//         return new Map(
//             Array.from(flightsGroupedByBookingId.entries())
//                 .sort(([idA, groupA], [idB, groupB]) => {

//                     const groupAHasAborted = groupA.flights.some((flight) => flight.isAborted);
//                     const groupBHasAborted = groupB.flights.some((flight) => flight.isAborted);

//                     if (groupAHasAborted !== groupBHasAborted) {
//                         return groupAHasAborted ? 1 : -1;
//                     }

//                     const droneIdA = groupA.droneId;
//                     const droneIdB = groupB.droneId;

//                     return droneIdA > droneIdB ? 1 : droneIdA < droneIdB ? -1 : 0;
//                 })
//         );
//     };


//     // const groupedFlightsArray = Array.from(flightsGroupedByBookingId.values());
//     const handleShuffleFLight = (dronebookingId, action) => {
//         const entries = Array.from(copyFlightsGroupedByBookingId.entries());
//         // console.log("entries", entries);
//         const index = entries.findIndex(([key]) => key === dronebookingId);


//         if (index === -1) {
//             // console.log('Invalid dronebookingId');
//             return;
//         }

//         if (action === "up" && index > 0) {
//             [entries[index], entries[index - 1]] = [entries[index - 1], entries[index]];
//         } else if (action === "down" && index < entries.length - 1) {
//             [entries[index], entries[index + 1]] = [entries[index + 1], entries[index]];
//         } else {
//             // console.log('Invalid action or out of bounds');
//             return;
//         }

//         const updatedMap = new Map(entries);
//         setShowReschedule(!showReshedule);
//         // setFlightsGroupedByBookingId(updatedMap);
//         setCopyFlightsGroupedByBookingId(updatedMap);
//         // console.log(`Updated group order:`, Array.from(updatedMap.keys()));
//     };

//     function handleRescheduelPopup(completeFlight) {
//         setHandelReschedulePopup(true);
//         setSelectedFlight(completeFlight)
//     }

//     useEffect(() => {
//         const droneBookingIds = Array.from(copyFlightsGroupedByBookingId.keys());
//         // const droneBookingIds = entries.map(([key]) => key);
//         let check = false;
//         // console.log("bookingIds", bookingIds, droneBookingIds)
//         check = bookingIds.some((bookingId, index) => bookingId !== droneBookingIds[index]);
//         // console.log(bookingIds, droneBookingIds, check)
//         if (check) {
//             setShowReschedule(true);
//         }
//     }, [showReshedule])
//     const handelPageChange = (page) => {
//         setCurrentPage(page)
//     }
//     // console.log("Users", user)
//     return (
//         <div className='DailyPlans-container'>
//             <div className='DailyPlans-container__filter'>
//                 <div className='DailyPlans-container__filter'>
//                     <button className={`DailyPlans-container__filter__today ${selectedCondition === "Today" && "DailyPlans-container__filter__today__active"}`}
//                         onClick={(e) => {
//                             handleDayChange("Today")
//                         }}>
//                         Today
//                     </button>
//                     <button className={`DailyPlans-container__filter__tommorrow ${selectedCondition === "Tommorrow" && "DailyPlans-container__filter__tommorrow__active"}`}
//                         onClick={(e) => {
//                             handleDayChange("Tommorrow")
//                         }}>
//                         tommorow
//                     </button>
//                     <div className='DailyPlans-container__filter__duration'>
//                         <div className='DailyPlans-container__filter__duration__label'>Custom</div>
//                         <div className='DailyPlans-container__filter__duration__label'>:</div>
//                         <DatePicker
//                             className={`DailyPlans-container__filter__datepicker ${selectedCondition === "Datepicker" ? "DailyPlans-container__filter__datepicker__active" : ""
//                                 }`}
//                             oneTap
//                             size="sm"
//                             format="dd-MMM-yyyy"
//                             style={{ width: 150 }}
//                             value={customDate ? dayjs(customDate).startOf("day").toDate() : null}
//                             onChange={(date) => handleChangeDateSelection(dayjs(date).startOf("day"))}
//                             shouldDisableDate={(date) => {
//                                 const today = dayjs().startOf('day');
//                                 const oneMonthLater = today.add(1, 'month').endOf('day');  // Set to the end of one month from today

//                                 // Compare: check if the selected date is within today and one month later
//                                 const selectedDate = dayjs(date).startOf('day');  // Start of the selected day

//                                 return selectedDate.isBefore(today) || selectedDate.isAfter(oneMonthLater);  // Disable if outside range
//                             }}
//                         />

//                     </div>
//                     <div className='DailyPlans-container__filter__checkBox'>
//                         <label className='DailyPlans-container__filter__checkBox__label'>
//                             <input
//                                 className='DailyPlans-container__filter__checkBox__input'
//                                 type="checkbox"
//                                 checked={isAborted}
//                                 onChange={handleCheckboxChange}
//                             />
//                             Aborted Flight
//                         </label>
//                     </div>
//                     {(urgentOrders.length > 0) && userCookie.accessRole === "Hub Manager" && <div className='DailyPlans-container__filter__checkBox'>
//                         <label className='DailyPlans-container__filter__checkBox__label'>
//                             <input
//                                 className='DailyPlans-container__filter__checkBox__input'
//                                 type="checkbox"
//                                 checked={isUrgentOrders}
//                                 onChange={() => setIsUrgentOrders(!isUrgentOrders)}
//                             />
//                             Plan Urgent Orders
//                         </label>
//                     </div>}
//                     <div className='DailyPlans-container__filter__sort'>
//                         <p className='DailyPlans-container__filter__sort__heading'>Sort: </p>
//                         <select className='DailyPlans-container__filter__sort__duration-select'
//                             defaultValue={filters[0]}
//                             onChange={(e) => {
//                                 handleFilter(e.target.value)
//                                 setFlightSort(e.target.value)
//                             }}>

//                             {filters.map((filter) => <option className='' value={filter}>{filter}</option>)
//                             }
//                         </select>
//                     </div>
//                     {/* <button className='DailyPlans-container__filter__toggle-btn'
//                         onClick={() => setIsUrgentOrders(!isUrgentOrders)}>
//                         {!isUrgentOrders ? "Plan Urgent Trips" : "Daily Trips"}
//                     </button> */}
//                 </div>

//                 {/* {showReshedule && userCookie.accessRole === "Hub Manager" &&  */}
//                 {/* // !dayjs(duration).startOf('day').isSame(today) && */}
//                 <button className='DailyPlans-container__filter__update-btn'
//                     style={{ visibility: (showReshedule && userCookie.accessRole === "Hub Manager") ? "visible" : "hidden" }}
//                     onClick={() => handleApplyReschedule()}>
//                     Apply Reschedule
//                 </button>
//                 {/* } */}
//             </div>
//             <div className='DailyPlans-container__all-data'>
//                 {isUrgentOrders && (urgentOrders.length > 0) && userCookie.accessRole === "Hub Manager" && <UrgentOrders
//                     // socket={socket}
//                     handleNotifyAlert={handleNotifyAlert}
//                     SetLoadingVisibility={SetLoadingVisibility}
//                     userCookie={userCookie}
//                     setSelectedOrder={setSelectedOrder}
//                     handleSchedulePopup={handleSchedulePopup}
//                     selectedOrder={selectedOrder}
//                     setIsOrderDetailsPopup={setIsOrderDetailsPopup}
//                     urgentOrders={urgentOrders}
//                 />}
//                 <div className='DailyPlans-datacontainer'>
//                     <div className='DailyPlans-container__index'>
//                         <div className='DailyPlans-container__index__flight-type DailyPlans-container__index__term'>
//                             Type
//                         </div>
//                         {/* <div className='DailyPlans-container__index__drone DailyPlans-container__index__term'>
//                         Drone Id
//                     </div> */}
//                         <div className='DailyPlans-container__index__source DailyPlans-container__index__term'>
//                             Source
//                         </div>
//                         <div className='DailyPlans-container__index__destination DailyPlans-container__index__term'>
//                             Destination
//                         </div>
//                         <div className='DailyPlans-container__index__payload DailyPlans-container__index__term'>
//                             Payload
//                         </div>
//                         {/* <div className='DailyPlans-container__index__distance DailyPlans-container__index__term'>
//                         Distance
//                     </div> */}
//                         <div className='DailyPlans-container__index__flight-date DailyPlans-container__index__term'>
//                             Scheduled Date
//                         </div>
//                         <div className='DailyPlans-container__index__start-time DailyPlans-container__index__term'>
//                             Start Time
//                         </div>
//                         <div className='DailyPlans-container__index__time-taken DailyPlans-container__index__term'>
//                             Time Taken
//                         </div>
//                         <div className='DailyPlans-container__index__order-details DailyPlans-container__index__term'>
//                             Flight Actions
//                         </div>
//                         <div className='DailyPlans-container__index__shuffle DailyPlans-container__index__term'>
//                             Action
//                         </div>
//                     </div>
//                     {copyFlightsGroupedByBookingId.size <= 0 ? "No Flight Found" : (
//                         <>
//                             {[...copyFlightsGroupedByBookingId.values()].filter((groupedFlight) => {
//                                 if (userCookie.accessRole === "Drone Pilot") {
//                                     return user.droneAssigned === groupedFlight.droneId;
//                                 }
//                                 return true;
//                             })
//                                 .map((groupedFlight, index) => {
//                                     // .map((groupedFlight, index) => {
//                                     return (
//                                         <div key={groupedFlight.bookingId} className='DailyPlans-container__complete-flight'>
//                                             <div className='DailyPlans-container__complete-flight__allData'>
//                                                 <div className='DailyPlans-container__complete-flight__allData__header'>
//                                                     <div className='DailyPlans-container__complete-flight__allData__header__orderId'>
//                                                         {locations[groupedFlight.flights[0].start_location]?.name}
//                                                         <img className='DailyPlans-container__complete-flight__allData__header__orderId__doubleHeadedArrowSvg' src={`${backEndIP}/double-arrow.svg`} />
//                                                         {locations[groupedFlight.flights[0].end_location]?.name}
//                                                     </div>
//                                                     <div className='DailyPlans-container__complete-flight__allData__header__droneId'>DroneID: {groupedFlight.droneId}</div>
//                                                     <div className='DailyPlans-container__complete-flight__allData__header__orderNumber'>OrderNo: {groupedFlight.orderNumber}</div>
//                                                     <div className='DailyPlans-container__complete-flight__allData__header__orderType'>OrderType: {groupedFlight.orderType}</div>
//                                                 </div>
//                                                 <div className='DailyPlans-container__complete-flight__allData__details'>
//                                                     {groupedFlight.flights.map(flight => (
//                                                         <div
//                                                             key={flight._id}
//                                                             className={`${flight.isCompleted ? "DailyPlans-datacontainer__rowGreen" : flight.isAborted ? "DailyPlans-datacontainer__rowRed" : "DailyPlans-datacontainer__row"}`}
//                                                         >
//                                                             <div className='DailyPlans-datacontainer__row__flight-type DailyPlans-datacontainer__row__term'>
//                                                                 {flight.flight_type}
//                                                             </div>
//                                                             <div className='DailyPlans-datacontainer__row__source DailyPlans-datacontainer__row__term'>
//                                                                 {locations[flight.start_location]?.name}
//                                                             </div>
//                                                             <div className='DailyPlans-datacontainer__row__destination DailyPlans-datacontainer__row__term'>
//                                                                 {locations[flight.end_location]?.name}
//                                                             </div>
//                                                             <div className='DailyPlans-datacontainer__row__payload DailyPlans-datacontainer__row__term'>
//                                                                 {flight.payload}g
//                                                             </div>
//                                                             <div className='DailyPlans-datacontainer__row__flight-date DailyPlans-datacontainer__row__term'>
//                                                                 {dayjs(flight.scheduleDetails.date).format("YYYY-MM-DD HH:mm").toString()}
//                                                             </div>
//                                                             <div className='DailyPlans-datacontainer__row__start-time DailyPlans-datacontainer__row__term'>
//                                                                 {flight.start_time ? dayjs(flight.start_time).format("YYYY-MM-DD HH:mm").toString() : "NA"}
//                                                             </div>
//                                                             <div className='DailyPlans-datacontainer__row__time-taken DailyPlans-datacontainer__row__term'>
//                                                                 {dayjs().set('hour', 0).set('minute', 0).set('second', 0).add(flight.time_taken, 'second').format('HH:mm:ss')}
//                                                             </div>
//                                                             <div className='DailyPlans-datacontainer__row__order-details DailyPlans-datacontainer__row__term'>
//                                                                 <button className='DailyPlans-datacontainer__row__order-details__btn'
//                                                                     onClick={() => { setFlightDetails(flight); setFlightDetailsVisibility(true) }}
//                                                                 >Details</button>
//                                                                 {(user.flightId === flight._id) && <button className='DailyPlans-datacontainer__row__order-details__start-btn'
//                                                                     onClick={() => handleStart()}
//                                                                 >Start</button>}
//                                                             </div>
//                                                         </div>
//                                                     ))}
//                                                 </div>
//                                             </div>
//                                             <div className='DailyPlans-datacontainer__row__shuffle DailyPlans-datacontainer__row__term'>
//                                                 {
//                                                     // !dayjs(duration).startOf('day').isSame(today) && 
//                                                     !groupedFlight.flights[0].isCompleted &&
//                                                     !groupedFlight.flights[0].isAborted &&
//                                                     userCookie.accessRole === "Hub Manager" && (
//                                                         <div className='DailyPlans-datacontainer__row__shuffle__btns'>
//                                                             {(index !== 0 && (index > 0 && ![...copyFlightsGroupedByBookingId.values()][index - 1]?.flights[0]?.isCompleted)) && (
//                                                                 <button
//                                                                     className='DailyPlans-datacontainer__row__shuffle__btns__up-btn'
//                                                                     onClick={() => handleShuffleFLight(groupedFlight.bookingId, "up")}
//                                                                 >
//                                                                     <img
//                                                                         style={{ height: "calc(30px * var(--heightmultiplier))" }}
//                                                                         src={`${backEndIP}/upArrowIcon.png`}
//                                                                     />
//                                                                 </button>
//                                                             )}
//                                                             {(index !== [...copyFlightsGroupedByBookingId.values()].length - 1 && (index < [...copyFlightsGroupedByBookingId.values()].length && ![...copyFlightsGroupedByBookingId.values()][index + 1]?.flights[0]?.isAborted)) && (index < [...copyFlightsGroupedByBookingId.values()].length &&![...copyFlightsGroupedByBookingId.values()][index + 1]?.flights[0]?.isCompleted) && (
//                                                                 <button
//                                                                     className='DailyPlans-datacontainer__row__shuffle__btns__down-btn'
//                                                                     onClick={() => handleShuffleFLight(groupedFlight.bookingId, "down")}
//                                                                 >
//                                                                     <img
//                                                                         style={{ height: "calc(30px * var(--heightmultiplier))" }}
//                                                                         src={`${backEndIP}/downArrowIcon.png`}
//                                                                     />
//                                                                 </button>
//                                                             )}
//                                                         </div>
//                                                     )
//                                                 }

//                                                 {(!groupedFlight.flights[0].isCompleted && !groupedFlight.flights[0].isAborted) ? <button className='DailyPlans-datacontainer__row__shuffle__cancel-btn'
//                                                     onClick={() => { handleRescheduelPopup(groupedFlight) }}>
//                                                     {userCookie?.accessRole === "Hub Manager" ? "Reschedule" : "Order Details"}
//                                                 </button> : <p>_</p>}
//                                             </div>
//                                         </div>
//                                     );
//                                 })}
//                         </>
//                     )}

//                 </div>
//             </div>
//             {/* <div className='DailyPlans-container__pagination'>
//                 <PaginationComponent
//                     totalItems={totalItems}
//                     itemsPerPage={itemPerPage}
//                     onPageChange={handelPageChange}
//                     currentPage={page}
//                 /></div> */}
//             {handelReschedulePopup && <ReschedulePopup
//                 setHandelReschedulePopup={setHandelReschedulePopup}
//                 locations={locations}
//                 selectedFlight={selectedFlight}
//                 handleNotifyAlert={handleNotifyAlert}
//                 userCookie={userCookie}
//                 SetLoadingVisibility={SetLoadingVisibility}
//             />}
//             {flightDetailsVisibility && <FlightDetailsPopup
//                 setOpenModal={setFlightDetailsVisibility}
//                 setFlightDetails={setFlightDetails}
//                 userCookie={userCookie}
//                 flight={flightDetails}
//                 setCheckListDetailsPopup={setCheckListDetailsPopup}
//                 setCheckListDetails={setCheckListDetails}
//                 setPageTitle={setPageTitle}
//                 handleNotifyAlert={handleNotifyAlert}
//                 allLocations={locations}
//                 SetLoadingVisibility={SetLoadingVisibility} />}
//             {checkListDetailsPopup && <CustomPopUpTemplete
//                 setOpenModal={setCheckListDetailsPopup} pageTitle={pageTitle} content={<ChecklistInputForm socket={socket}
//                     checklistItems={CheckListDetails}
//                     url={"client:preFlightUpdate"}
//                     setIsChanged={() => { }}
//                     checklistUpdateObj={{}}
//                     isEditable={false} />} />}

//             {schedulePopup &&
//                 <SchedulePopup
//                     closeSchedulePopup={closeSchedulePopup}
//                     closeScheduleModal={closeScheduleModal}
//                     rescheduleModalRef={rescheduleModalRef}
//                     userCookie={userCookie}
//                     item={selectedOrder}
//                     handleNotifyAlert={handleNotifyAlert}
//                     locations={locations}
//                     SetLoadingVisibility={SetLoadingVisibility}
//                     setSchedulePopup={setSchedulePopup}
//                 />
//             }
//             {isOrderDetailsPopup &&
//                 //  <CustomPopUpTemplete setOpenModal={setOrderDetailsVisibility} content={
//                 <OrderDetailsTab
//                     setShowPopup={setIsOrderDetailsPopup}
//                     item={selectedOrder}
//                     SetLoadingVisibility={SetLoadingVisibility}
//                     // cancelStatusUpdate={() => { socket.emit('client:cancel-order', orderDetails) }}
//                     handleNotifyAlert={handleNotifyAlert}
//                     userCookie={userCookie}
//                 // allNewPanelLog={allNewPanelLog}
//                 // updateOrdersPage={updateOrdersPageData} 
//                 />
//                 // }/>
//             }
//         </div>
//     )
// }

// export default DailyPlans;



import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import FlightDetailsPopup from '../FlightDetailsPopup/FlightDetailsPopup';
import "./DailyPlans.scss"
import ChecklistInputForm from '../ChecklistInputForm/ChecklistInputForm';
import CustomAlertBox from '../CustomAlert/CustomAlertBox';
import CustomPopUpTemplete from '../CustomPopUpTemplete/CustomPopUpTemplete';
import { Dropdown, DatePicker, InputGroup, Table } from 'rsuite';
import { useSelector } from 'react-redux';
import { selectHubNodeLocations } from '../../ReduxStore/HubNodeLocationsSlice';
import axios from 'axios';
import { backEndIP, InventoryBackEndIP } from '../MyConfiguration';
import PaginationComponent from '../../Pagination/Pagination.tsx';
import ReschedulePopup from '../ReschedulePopup/ReschedulePopup.js';
import { fetchOrderDeliverySlots } from '../../utility/fetchOrderDeliverySlots.js';
import { useNavigate } from 'react-router-dom';
import UrgentOrders from '../UrgentOrders/UrgentOrders.js';
import OrderDetailsTab, { SchedulePopup } from '../OrderDetailsTab/OrderDetailsTab.js';


const DailyPlans = ({ socket,
    isConnected,
    userCookie,
    sourceLocation,
    setSourceLocation,
    adminLocation,
    allLocations,
    handleNotifyAlert,
    SetLoadingVisibility, user }) => {

    // const [allFlights, setAllFlights] = useState([]);
    const [flightDetailsVisibility, setFlightDetailsVisibility] = useState(false);
    const [flightDetails, setFlightDetails] = useState({});
    const [checkListDetailsPopup, setCheckListDetailsPopup] = useState(false)
    const [CheckListDetails, setCheckListDetails] = useState([])
    const [pageTitle, setPageTitle] = useState()
    const [isOrderDetailsPopup, setIsOrderDetailsPopup] = useState(false);
    const [flightSort, setFlightSort] = useState("Scheduled Date");

    // const [searchValue, setSearchValue] = useState("")
    const locations = useSelector(selectHubNodeLocations)
    const [isUrgentOrders, setIsUrgentOrders] = useState(true)
    // const [filterPopup, setFilterPopup] = useState(false)
    const [nodeLocation, setNodeLocation] = useState([])
    const [page, setCurrentPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(20)
    const [totalItems, setTotalItems] = useState(0)
    const [selectedCondition, setSelectedCondition] = useState("Today");
    const [duration, setDuration] = useState(dayjs().set('hour', 0).set('minute', 0).set('second', 0).millisecond(0))
    const [customDate, setCustomDate] = useState("")
    const [flightsGroupedByBookingId, setFlightsGroupedByBookingId] = useState(new Map());
    const [copyFlightsGroupedByBookingId, setCopyFlightsGroupedByBookingId] = useState(new Map());
    const [searchedFlights, setSearchedFlights] = useState([])
    const [bookingIds, setBookingIds] = useState([]);
    const [showReshedule, setShowReschedule] = useState(false);
    const [handelReschedulePopup, setHandelReschedulePopup] = useState(false);
    const [selectedFlight, setSelectedFlight] = useState({});
    const [available, setAvailableOrderSlots] = useState([])
    const [selectedOrder, setSelectedOrder] = useState({});
    const rescheduleModalRef = useRef();
    const [animatedFlight, setAnimatedFlight] = useState(null);
    const [animatedFlightSecond, setAnimatedFlightSecond] = useState(null);
    const [animationDirection, setAnimationDirection] = useState(null);
    const [isSimilar, setIsSimilar] = useState(false);
    const [isPaylaodChanged, setIsPayLoadChanged] = useState(false)
    // const [isUrgentOrderNeedToShow, setIsUrgentOrdersNeedTohow]=useState(true);
    const [schedulePopup, setSchedulePopup] = useState(false)
    const [urgentOrders, setUrgentOrders] = useState([]);
    const filters = ["Scheduled Date", "DroneId"]
    const closeScheduleModal = e => {
        if (rescheduleModalRef.current === e.target) {
            setSchedulePopup(false);
        }
    };
    function closeSchedulePopup() {
        setSchedulePopup(false);
    }
    function handleSchedulePopup() {
        setSchedulePopup(true)
    }
    const [orders, setOrders] = useState([])

    const DurationOptions = {
        "Today": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
        "Last 3 days": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "day"),
        "Last 1 week": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "week"),
        "Last 2 weeks": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "week"),
        "Last 1 Month": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "month"),
        "Last 3 Months": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(3, "month"),
    };
    // const today = dayjs().startOf('day');
    const [isAborted, setIsAborted] = useState(false);
    const navigate = useNavigate();
    // console.log(userCookie)
    const handleCheckboxChange = (e) => {
        setIsAborted(e.target.checked);
        // console.log("Aborted Flight:", e.target.checked);
    };

    // useEffect(()=>{

    // fetchOrderDeliverySlots(userCookie, setAvailableOrderSlots, handleNotifyAlert)

    // }, [duration])
    function getMyFlights() {
        SetLoadingVisibility(true)
        if (customDate || duration) {
            axios({
                url: backEndIP + "/flight/getMyFlights",
                method: "POST",
                headers: {
                    authorization: userCookie.token,
                },
                data: { date: selectedCondition === "Datepicker" ? customDate : duration }
            }).then((resp) => {
                // console.log("resp", resp.data)
                SetLoadingVisibility(false)
                setTotalItems(resp.data.length)
                setSearchedFlights(resp.data.data)
            }).catch((err) => {
                console.log(err)
                SetLoadingVisibility(false);
                // console.log("err.response.data.message", err.response.data.message);
                // handleNotifyAlert(
                //     "notify",
                //     `${err.response.data.message}`,
                //     "failed",
                //     () => { }
                // );
                setSearchedFlights([])
            })
        } else {
            SetLoadingVisibility(false);
        }
    }
    useEffect(() => {
        getMyFlights()
    }, [page, duration, customDate, isPaylaodChanged])

    useEffect(() => {
        // console.log("function is working weferwe");
        if (userCookie.accessRole === "Hub Manager") {
            getMyFlights()
        }
    }, [handelReschedulePopup, schedulePopup])
    useEffect(() => {
        axios({
            url: backEndIP + "/hub/hub-node-all-locations",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((hubResp) => {
            // console.log(hubResp.data)
            setNodeLocation(hubResp.data.data);
            //console.log(allHub)
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    function handleApplyReschedule() {
        // const droneBookingIds = Array.from(copyFlightsGroupedByBookingId.keys());
        // const droneBookingIds = allBookingIds.map(([key]) => key);
        const sortedFilteredFlights = filterDroneBookingIds();
        const droneBookingIds = Array.from(sortedFilteredFlights.keys());
        console.log("droneBookingIds", droneBookingIds);
        SetLoadingVisibility(true)
        if (userCookie.accessRole === "Hub Manager") {
            axios({
                url: backEndIP + "/flight/update-flight-bookings",
                method: "POST",
                headers: {
                    authorization: userCookie.token,
                },
                data: { allBookingIds: droneBookingIds }
            }).then((resp) => {
                // console.log("resp", resp.data)
                SetLoadingVisibility(false)
                setTotalItems(resp.data.length)
                setSearchedFlights(resp.data.data)
            }).catch((err) => {
                console.log(err)
                SetLoadingVisibility(false);
                handleNotifyAlert(
                    "notify",
                    `${err.response.data.message}`,
                    "failed",
                    () => { }
                );
                // setSearchedFlights([])
            })
        }
    }
    function handleStart() {
        navigate("/monitor");
    }
    function handleChangeDateSelection(value) {
        setSelectedCondition("Datepicker")
        setCustomDate(value);
        setDuration("");
    }
    function handleDayChange(day) {
        setCustomDate("");
        if (day === "Today") {
            setDuration(dayjs().set('hour', 0).set('minute', 0).set('second', 0).millisecond(0))
        } else if (day === "Tommorrow") {
            setDuration(dayjs().add(1, "day").set('hour', 0).set('minute', 0).set('second', 0).millisecond(0))
        }
        setSelectedCondition(day)
    }
    function getAllOrder() {

        if (userCookie.accessRole !== "Drone Pilot") {
            axios({
                url: InventoryBackEndIP + "/order/get-all-order",
                method: "POST",
                headers: {
                    authorization: userCookie.token,
                },
                data: { date: DurationOptions["Last 1 week"] }
            }).then((resp) => {
                // console.log("reshdfgahrdfgrp", resp.data)
                SetLoadingVisibility(false)
                setOrders(resp.data.data)
                const urgentOrders = resp.data.data.filter((order) => order.delivery_type === "Urgent" && !order.droneBookingId);
                console.log("urgentOrders", urgentOrders);
                setUrgentOrders(urgentOrders);
            }).catch((err) => {
                console.log(err)
                SetLoadingVisibility(false);
                // console.log("err.response.data.message", err?.response?.data?.message);
                // handleNotifyAlert(
                //     "notify",
                //     `${err?.response?.data?.message ? err?.response?.data?.message : "OMS Server error"}`,
                //     "failed",
                //     () => { }
                // );
            })
        }
    }
    useEffect(() => {
        getAllOrder()
    }, [schedulePopup])
    useEffect(() => {
        const uniqueFlightsGroupedByBookingId = new Map();
        const filterFlights = new Map();

        searchedFlights.forEach((flight) => {
            // console.log("Processing flight:", flight);

            const dronebookingId = flight.dronebookingId;


            if (!uniqueFlightsGroupedByBookingId.has(dronebookingId)) {
                uniqueFlightsGroupedByBookingId.set(dronebookingId, {
                    bookingId: dronebookingId,
                    orderId: flight.order_id,
                    orderNumber: flight.order_no,
                    droneId: flight.drone_id,
                    orderType: flight.order_type,
                    flights: [],
                });

                if (!flight.isAborted) {
                    filterFlights.set(dronebookingId, {
                        bookingId: dronebookingId,
                        orderId: flight.order_id,
                        orderNumber: flight.order_no,
                        droneId: flight.drone_id,
                        orderType: flight.order_type,
                        flights: [],
                    });
                }
            }

            const currentGroup = uniqueFlightsGroupedByBookingId.get(dronebookingId);
            const filterCurrentGroup = filterFlights.get(dronebookingId);

            const isDuplicate = currentGroup.flights.some((existingFlight) => existingFlight._id === flight._id);
            if (!isDuplicate) {
                currentGroup.flights.push(flight);
            }

            if (filterCurrentGroup) {
                const isFilteredDuplicate = filterCurrentGroup.flights.some((existingFlight) => existingFlight._id === flight._id);
                if (!isFilteredDuplicate) {
                    filterCurrentGroup.flights.push(flight);
                }
            }
        });

        const droneBookingIds = Array.from(filterFlights.keys());
        // console.log("droneBookingIds:", droneBookingIds);

        setBookingIds(droneBookingIds);
        setShowReschedule(false);
        setFlightsGroupedByBookingId(uniqueFlightsGroupedByBookingId);
        setCopyFlightsGroupedByBookingId(filterFlights);

        // console.log("flightsGroupedByBookingId:", uniqueFlightsGroupedByBookingId);
        // console.log("Filtered Booking Ids:", uniqueFlightsGroupedByBookingId);
        // console.log("copyFlightsGroupedByBookingId:", filterFlights);
    }, [searchedFlights]);

    function handleFilter(value) {
        let sortedFilteredFlights;
        if (value === "Scheduled Date") {
            sortedFilteredFlights = sortFlightsByScheduleDate(copyFlightsGroupedByBookingId)
        } else if (value === "DroneId") {
            sortedFilteredFlights = sortFlightsByDroneId(copyFlightsGroupedByBookingId)
        }
        setCopyFlightsGroupedByBookingId(sortedFilteredFlights);
    }

    useEffect(() => {
        if (isAborted) {
            const sortedFilteredFlights = sortFlights(flightsGroupedByBookingId, isAborted)
            setCopyFlightsGroupedByBookingId(sortedFilteredFlights);
        }
        handleFilter(flightSort)
    }, [bookingIds]);

    useEffect(() => {
        if (!flightsGroupedByBookingId) return;
        let sortedFilteredFlights = sortFlights(flightsGroupedByBookingId, isAborted)

        if (flightSort === "Scheduled Date") {
            sortedFilteredFlights = sortFlightsByScheduleDate(sortedFilteredFlights);
        } else if (flightSort === "DroneId") {
            sortedFilteredFlights = sortFlightsByDroneId(sortedFilteredFlights);
        }
        setCopyFlightsGroupedByBookingId(sortedFilteredFlights);
        // console.log("Sorted filtered flights:", sortedFilteredFlights);
    }, [isAborted]);


    function filterDroneBookingIds() {
        // if (!flightsGroupedByBookingId) return new Map();
        if (!copyFlightsGroupedByBookingId) return new Map();

        return new Map(
            Array.from(copyFlightsGroupedByBookingId.entries())
                .filter(([id, group]) => {
                    // Exclude groups where any flight is complete
                    // console.log("group", group);
                    const hasCompletedFlight = group.flights.some((flight) => flight.isCompleted
                    );
                    if (hasCompletedFlight) return false;

                    // Filter out flights that are aborted
                    const hasAbortedFlight = group.flights.some((flight) => flight.isAborted);
                    if (hasAbortedFlight) return false;
                    // const filteredFlights = group.flights.filter((flight) => !flight.isAborted);

                    // Include the group only if there are remaining flights
                    // return filteredFlights.length > 0;
                    return true;
                })
                .map(([id, group]) => [
                    id,
                    { ...group, flights: group.flights.filter((flight) => !flight.isAborted) }
                ])
        );
    }

    const sortFlights = (flightsGroupedByBookingId, isAborted) => {
        if (!flightsGroupedByBookingId) return new Map();

        return new Map(
            Array.from(flightsGroupedByBookingId.entries())
                .map(([id, group]) => {
                    const filteredFlights = isAborted
                        ? group.flights
                        : group.flights.filter((flight) => !flight.isAborted);

                    return filteredFlights.length > 0
                        ? [id, { ...group, flights: filteredFlights }]
                        : null;
                })
                .filter(Boolean)
                .sort(([idA, groupA], [idB, groupB]) => {
                    const groupAHasAborted = groupA.flights.some((flight) => flight.isAborted);
                    const groupBHasAborted = groupB.flights.some((flight) => flight.isAborted);

                    return groupAHasAborted === groupBHasAborted
                        ? 0
                        : groupAHasAborted
                            ? 1
                            : -1;
                })
        );
    };

    const sortFlightsByScheduleDate = (flightsGroupedByBookingId, isAborted) => {
        if (!flightsGroupedByBookingId) return new Map();

        return new Map(
            Array.from(flightsGroupedByBookingId.entries())
                .sort(([idA, groupA], [idB, groupB]) => {
                    const groupAHasAborted = groupA.flights.some((flight) => flight.isAborted);
                    const groupBHasAborted = groupB.flights.some((flight) => flight.isAborted);

                    if (groupAHasAborted !== groupBHasAborted) {
                        return groupAHasAborted ? 1 : -1;
                    }

                    const dateA = new Date(groupA.flights[0].scheduleDetails.date);
                    const dateB = new Date(groupB.flights[0].scheduleDetails.date);

                    return dateA - dateB;
                })
        );
    };

    const sortFlightsByDroneId = (flightsGroupedByBookingId, isAborted) => {
        if (!flightsGroupedByBookingId) return new Map();

        return new Map(
            Array.from(flightsGroupedByBookingId.entries())
                .sort(([idA, groupA], [idB, groupB]) => {

                    const groupAHasAborted = groupA.flights.some((flight) => flight.isAborted);
                    const groupBHasAborted = groupB.flights.some((flight) => flight.isAborted);

                    if (groupAHasAborted !== groupBHasAborted) {
                        return groupAHasAborted ? 1 : -1;
                    }

                    const droneIdA = groupA.droneId;
                    const droneIdB = groupB.droneId;

                    return droneIdA > droneIdB ? 1 : droneIdA < droneIdB ? -1 : 0;
                })
        );
    };


    // const groupedFlightsArray = Array.from(flightsGroupedByBookingId.values());
    const handleShuffleFLight = (dronebookingId, action) => {
        const entries = Array.from(copyFlightsGroupedByBookingId.entries());

        const index = entries.findIndex(([key]) => key === dronebookingId);
        console.log("entries", entries, index);
        if (index === -1) return;

        setAnimationDirection(action);
        setAnimatedFlight(dronebookingId);
        setAnimatedFlightSecond(action === "up" ? entries[index - 1][0] : entries[index + 1][0]);
        console.log("1244", action === "up" ? entries[index - 1][0] : entries[index + 1][0])
        if (
            (entries[index][1].flights.length === 2 && action === "up" && entries[index - 1]?.[1]?.flights.length === 2) ||
            (entries[index][1].flights.length === 2 && action === "down" && entries[index + 1]?.[1]?.flights.length === 2) ||
            (entries[index][1].flights.length === 1 && action === "up" && entries[index - 1]?.[1]?.flights.length === 1) ||
            (entries[index][1].flights.length === 1 && action === "down" && entries[index + 1]?.[1]?.flights.length === 1)
        ) {
            setIsSimilar(true);
        }
        // Wait for animation to complete before updating the state
        setTimeout(() => {
            if (action === "up" && index > 0) {
                [entries[index], entries[index - 1]] = [entries[index - 1], entries[index]];
            } else if (action === "down" && index < entries.length - 1) {
                [entries[index], entries[index + 1]] = [entries[index + 1], entries[index]];
            }

            const updatedMap = new Map(entries);
            setShowReschedule(!showReshedule);
            // setFlightsGroupedByBookingId(updatedMap);
            setCopyFlightsGroupedByBookingId(updatedMap);

            // Reset animation states
            setAnimatedFlight(null);
            setAnimationDirection(null);
            setAnimatedFlightSecond(null);
            setIsSimilar(false)
        }, 300); // Match animation duration (500ms)
    };


    function handleRescheduelPopup(completeFlight) {
        setHandelReschedulePopup(true);
        setSelectedFlight(completeFlight)
    }

    useEffect(() => {
        const droneBookingIds = Array.from(copyFlightsGroupedByBookingId.keys());
        // const droneBookingIds = entries.map(([key]) => key);
        let check = false;
        // console.log("bookingIds", bookingIds, droneBookingIds)
        check = bookingIds.some((bookingId, index) => bookingId !== droneBookingIds[index]);
        // console.log(bookingIds, droneBookingIds, check)
        if (check) {
            setShowReschedule(true);
        }
    }, [showReshedule])
    const handelPageChange = (page) => {
        setCurrentPage(page)
    }
    // console.log("Users", user)
    return (
        <div className='DailyPlans-container'>
            <div className='DailyPlans-container__filter'>
                <div className='DailyPlans-container__filter'>
                    <button className={`DailyPlans-container__filter__today ${selectedCondition === "Today" && "DailyPlans-container__filter__today__active"}`}
                        onClick={(e) => {
                            handleDayChange("Today")
                        }}>
                        Today
                    </button>
                    <button className={`DailyPlans-container__filter__tommorrow ${selectedCondition === "Tommorrow" && "DailyPlans-container__filter__tommorrow__active"}`}
                        onClick={(e) => {
                            handleDayChange("Tommorrow")
                        }}>
                        tommorow
                    </button>
                    <div className='DailyPlans-container__filter__duration'>
                        <div className='DailyPlans-container__filter__duration__label'>Custom</div>
                        <div className='DailyPlans-container__filter__duration__label'>:</div>
                        <DatePicker
                            className={`DailyPlans-container__filter__datepicker ${selectedCondition === "Datepicker" ? "DailyPlans-container__filter__datepicker__active" : ""
                                }`}
                            oneTap
                            size="sm"
                            format="dd-MMM-yyyy"
                            style={{ width: 150 }}
                            value={customDate ? dayjs(customDate).startOf("day").toDate() : null}
                            onChange={(date) => handleChangeDateSelection(dayjs(date).startOf("day"))}
                            shouldDisableDate={(date) => {
                                const today = dayjs().startOf('day');
                                const oneMonthLater = today.add(1, 'month').endOf('day');  // Set to the end of one month from today

                                // Compare: check if the selected date is within today and one month later
                                const selectedDate = dayjs(date).startOf('day');  // Start of the selected day

                                return selectedDate.isBefore(today) || selectedDate.isAfter(oneMonthLater);  // Disable if outside range
                            }}
                        />

                    </div>
                    <div className='DailyPlans-container__filter__checkBox'>
                        <label className='DailyPlans-container__filter__checkBox__label'>
                            <input
                                className='DailyPlans-container__filter__checkBox__input'
                                type="checkbox"
                                checked={isAborted}
                                onChange={handleCheckboxChange}
                            />
                            Aborted Flight
                        </label>
                    </div>
                    {(urgentOrders.length > 0) && userCookie.accessRole === "Hub Manager" && <div className='DailyPlans-container__filter__checkBox'>
                        <label className='DailyPlans-container__filter__checkBox__label'>
                            <input
                                className='DailyPlans-container__filter__checkBox__input'
                                type="checkbox"
                                checked={isUrgentOrders}
                                onChange={() => setIsUrgentOrders(!isUrgentOrders)}
                            />
                            Plan Urgent Orders
                        </label>
                    </div>}
                    <div className='DailyPlans-container__filter__noOfFlights'>
                        Pending Flights : <span style={{fontWeight:"600"}}> {filterDroneBookingIds().size}</span>
                    </div>
                    <div className='DailyPlans-container__filter__sort'>
                        <p className='DailyPlans-container__filter__sort__heading'>Sort : </p>
                        <select className='DailyPlans-container__filter__sort__duration-select'
                            defaultValue={filters[0]}
                            onChange={(e) => {
                                handleFilter(e.target.value)
                                setFlightSort(e.target.value)
                            }}>

                            {filters.map((filter) => <option className='' value={filter}>{filter}</option>)
                            }
                        </select>
                    </div>

                    {/* <button className='DailyPlans-container__filter__toggle-btn'
                        onClick={() => setIsUrgentOrders(!isUrgentOrders)}>
                        {!isUrgentOrders ? "Plan Urgent Trips" : "Daily Trips"}
                    </button> */}
                </div>

                {/* {showReshedule && userCookie.accessRole === "Hub Manager" &&  */}
                {/* // !dayjs(duration).startOf('day').isSame(today) && */}
                <button className='DailyPlans-container__filter__update-btn'
                    style={{ visibility: (showReshedule && userCookie.accessRole === "Hub Manager") ? "visible" : "hidden" }}
                    onClick={() => handleApplyReschedule()}>
                    Apply Reschedule
                </button>
                {/* } */}
            </div>
            <div className='DailyPlans-container__all-data'>
                {isUrgentOrders && (urgentOrders.length > 0) && userCookie.accessRole === "Hub Manager" && <UrgentOrders
                    // socket={socket}
                    handleNotifyAlert={handleNotifyAlert}
                    SetLoadingVisibility={SetLoadingVisibility}
                    userCookie={userCookie}
                    setSelectedOrder={setSelectedOrder}
                    handleSchedulePopup={handleSchedulePopup}
                    selectedOrder={selectedOrder}
                    setIsOrderDetailsPopup={setIsOrderDetailsPopup}
                    urgentOrders={urgentOrders}
                />}
                <div className='DailyPlans-datacontainer'>
                    <div className='DailyPlans-container__index'>
                        <div className='DailyPlans-container__index__flight-type DailyPlans-container__index__term'>
                            Type
                        </div>
                        {/* <div className='DailyPlans-container__index__drone DailyPlans-container__index__term'>
                        Drone Id
                    </div> */}
                        <div className='DailyPlans-container__index__source DailyPlans-container__index__term'>
                            Source
                        </div>
                        <div className='DailyPlans-container__index__destination DailyPlans-container__index__term'>
                            Destination
                        </div>
                        <div className='DailyPlans-container__index__payload DailyPlans-container__index__term'>
                            Payload
                        </div>
                        {/* <div className='DailyPlans-container__index__distance DailyPlans-container__index__term'>
                        Distance
                    </div> */}
                        <div className='DailyPlans-container__index__flight-date DailyPlans-container__index__term'>
                            Scheduled Date
                        </div>
                        <div className='DailyPlans-container__index__start-time DailyPlans-container__index__term'>
                            Start Time
                        </div>
                        <div className='DailyPlans-container__index__time-taken DailyPlans-container__index__term'>
                            Time Taken
                        </div>
                        <div className='DailyPlans-container__index__order-details DailyPlans-container__index__term'>
                            Flight Actions
                        </div>
                        <div className='DailyPlans-container__index__shuffle DailyPlans-container__index__term'>
                            Action
                        </div>
                    </div>
                    {copyFlightsGroupedByBookingId.size <= 0 ? "No Flight Found" : (
                        <>
                            {[...copyFlightsGroupedByBookingId.values()].filter((groupedFlight) => {
                                if (userCookie.accessRole === "Drone Pilot") {
                                    return user.droneAssigned === groupedFlight.droneId;
                                }
                                return true;
                            })
                                .map((groupedFlight, index) => {
                                    // .map((groupedFlight, index) => {
                                    return (
                                        <div key={groupedFlight.bookingId}
                                            className={`DailyPlans-container__complete-flight
                                            ${animatedFlight === groupedFlight.bookingId && !isSimilar ? (
                                                    animationDirection === "up"
                                                        ? (groupedFlight.flights.length === 2
                                                            ? "DailyPlans-container__row--animate-up-double"
                                                            : "DailyPlans-container__row--animate-up-single")
                                                        : (groupedFlight.flights.length === 2
                                                            ? "DailyPlans-container__row--animate-down-double"
                                                            : "DailyPlans-container__row--animate-down-single")
                                                ) : animationDirection === "up" ? (animatedFlight === groupedFlight.bookingId && animationDirection !== null) ? "DailyPlans-container__row--animate-up" : "" : (animatedFlight === groupedFlight.bookingId && animationDirection !== null) ? "DailyPlans-container__row--animate-down" : ""} 
                                          
                                            ${animatedFlightSecond === groupedFlight.bookingId && !isSimilar ? (
                                                    animationDirection === "up"
                                                        ? (groupedFlight.flights.length === 2
                                                            ? "DailyPlans-container__row--animate-down-double"
                                                            : "DailyPlans-container__row--animate-down-single")
                                                        : (groupedFlight.flights.length === 2
                                                            ? "DailyPlans-container__row--animate-up-double"
                                                            : "DailyPlans-container__row--animate-up-single")
                                                ) : animationDirection === "up" ? (animatedFlightSecond === groupedFlight.bookingId && animationDirection !== null) ? "DailyPlans-container__row--animate-down" : "" : (animatedFlightSecond === groupedFlight.bookingId && animationDirection !== null) ? "DailyPlans-container__row--animate-up" : ""}`}

                                        >
                                            <div className='DailyPlans-container__complete-flight__allData'>
                                                <div className='DailyPlans-container__complete-flight__allData__header'>
                                                    <div className='DailyPlans-container__complete-flight__allData__header__orderId'>
                                                        {locations[groupedFlight.flights[0].start_location]?.name}
                                                        <img className='DailyPlans-container__complete-flight__allData__header__orderId__doubleHeadedArrowSvg' src={`${backEndIP}/double-arrow.svg`} />
                                                        {locations[groupedFlight.flights[0].end_location]?.name}
                                                    </div>
                                                    <div className='DailyPlans-container__complete-flight__allData__header__droneId'>DroneID: {groupedFlight.droneId}</div>
                                                    <div className='DailyPlans-container__complete-flight__allData__header__orderNumber'>OrderNo: {groupedFlight.orderNumber}</div>
                                                    <div className='DailyPlans-container__complete-flight__allData__header__orderType'>OrderType: {groupedFlight.orderType}</div>
                                                </div>
                                                <div className='DailyPlans-container__complete-flight__allData__details'>
                                                    {groupedFlight.flights.map(flight => (
                                                        <div
                                                            key={flight._id}
                                                            className={`${flight.isCompleted ? "DailyPlans-datacontainer__rowGreen" : flight.isAborted ? "DailyPlans-datacontainer__rowRed" : "DailyPlans-datacontainer__row"}`}
                                                        >
                                                            <div className='DailyPlans-datacontainer__row__flight-type DailyPlans-datacontainer__row__term'>
                                                                {flight.flight_type}
                                                            </div>
                                                            <div className='DailyPlans-datacontainer__row__source DailyPlans-datacontainer__row__term'>
                                                                {locations[flight.start_location]?.name}
                                                            </div>
                                                            <div className='DailyPlans-datacontainer__row__destination DailyPlans-datacontainer__row__term'>
                                                                {locations[flight.end_location]?.name}
                                                            </div>
                                                            <div className='DailyPlans-datacontainer__row__payload DailyPlans-datacontainer__row__term'>
                                                                {flight.payload}g
                                                            </div>
                                                            <div className='DailyPlans-datacontainer__row__flight-date DailyPlans-datacontainer__row__term'>
                                                                {dayjs(flight.scheduleDetails.date).format("YYYY-MM-DD HH:mm").toString()}
                                                            </div>
                                                            <div className='DailyPlans-datacontainer__row__start-time DailyPlans-datacontainer__row__term'>
                                                                {flight.start_time ? dayjs(flight.start_time).format("YYYY-MM-DD HH:mm").toString() : "NA"}
                                                            </div>
                                                            <div className='DailyPlans-datacontainer__row__time-taken DailyPlans-datacontainer__row__term'>
                                                                {dayjs().set('hour', 0).set('minute', 0).set('second', 0).add(flight.time_taken, 'second').format('HH:mm:ss')}
                                                            </div>
                                                            <div className='DailyPlans-datacontainer__row__order-details DailyPlans-datacontainer__row__term'>
                                                                <button className='DailyPlans-datacontainer__row__order-details__btn'
                                                                    onClick={() => { setFlightDetails(flight); setFlightDetailsVisibility(true) }}
                                                                >Details</button>
                                                                {(user.flightId === flight._id) && <button className='DailyPlans-datacontainer__row__order-details__start-btn'
                                                                    onClick={() => handleStart()}
                                                                >Start</button>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='DailyPlans-datacontainer__row__shuffle DailyPlans-datacontainer__row__term'>
                                                {
                                                    // !dayjs(duration).startOf('day').isSame(today) && 
                                                    !groupedFlight.flights[0].isCompleted &&
                                                    !groupedFlight.flights[0].isAborted &&
                                                    userCookie.accessRole === "Hub Manager" && (
                                                        <div className='DailyPlans-datacontainer__row__shuffle__btns'>
                                                            {(index !== 0 && (index > 0 && ![...copyFlightsGroupedByBookingId.values()][index - 1]?.flights[0]?.isCompleted)) && (
                                                                <button
                                                                    className='DailyPlans-datacontainer__row__shuffle__btns__up-btn'
                                                                    onClick={() => handleShuffleFLight(groupedFlight.bookingId, "up")}
                                                                >
                                                                    <img
                                                                        style={{ height: "calc(30px * var(--heightmultiplier))" }}
                                                                        src={`${backEndIP}/upArrowIcon.png`}
                                                                    />
                                                                </button>
                                                            )}
                                                            {(index !== [...copyFlightsGroupedByBookingId.values()].length - 1 && (index < [...copyFlightsGroupedByBookingId.values()].length && ![...copyFlightsGroupedByBookingId.values()][index + 1]?.flights[0]?.isAborted)) && (index < [...copyFlightsGroupedByBookingId.values()].length && ![...copyFlightsGroupedByBookingId.values()][index + 1]?.flights[0]?.isCompleted) && (
                                                                <button
                                                                    className='DailyPlans-datacontainer__row__shuffle__btns__down-btn'
                                                                    onClick={() => handleShuffleFLight(groupedFlight.bookingId, "down")}
                                                                >
                                                                    <img
                                                                        style={{ height: "calc(30px * var(--heightmultiplier))" }}
                                                                        src={`${backEndIP}/downArrowIcon.png`}
                                                                    />
                                                                </button>
                                                            )}
                                                        </div>
                                                    )
                                                }

                                                {(!groupedFlight.flights[0].isCompleted && !groupedFlight.flights[0].isAborted) ? <button className='DailyPlans-datacontainer__row__shuffle__cancel-btn'
                                                    onClick={() => { handleRescheduelPopup(groupedFlight) }}>
                                                    {userCookie?.accessRole === "Hub Manager" ? "Reschedule" : "Order Details"}
                                                </button> : <p>_</p>}
                                            </div>
                                        </div>
                                    );
                                })}
                        </>
                    )}

                </div>
            </div>
            {/* <div className='DailyPlans-container__pagination'>
                <PaginationComponent
                    totalItems={totalItems}
                    itemsPerPage={itemPerPage}
                    onPageChange={handelPageChange}
                    currentPage={page}
                /></div> */}
            {handelReschedulePopup && <ReschedulePopup
                setHandelReschedulePopup={setHandelReschedulePopup}
                locations={locations}
                selectedFlight={selectedFlight}
                handleNotifyAlert={handleNotifyAlert}
                userCookie={userCookie}
                SetLoadingVisibility={SetLoadingVisibility}
            />}
            {flightDetailsVisibility && <FlightDetailsPopup
                setOpenModal={setFlightDetailsVisibility}
                setFlightDetails={setFlightDetails}
                userCookie={userCookie}
                flight={flightDetails}
                setCheckListDetailsPopup={setCheckListDetailsPopup}
                setCheckListDetails={setCheckListDetails}
                setPageTitle={setPageTitle}
                handleNotifyAlert={handleNotifyAlert}
                allLocations={locations}
                SetLoadingVisibility={SetLoadingVisibility}
                setIsPayLoadChanged={setIsPayLoadChanged}
            />}
            {checkListDetailsPopup && <CustomPopUpTemplete
                setOpenModal={setCheckListDetailsPopup} pageTitle={pageTitle} content={<ChecklistInputForm socket={socket}
                    checklistItems={CheckListDetails}
                    url={"client:preFlightUpdate"}
                    setIsChanged={() => { }}
                    checklistUpdateObj={{}}
                    isEditable={false} />} />}

            {schedulePopup &&
                <SchedulePopup
                    closeSchedulePopup={closeSchedulePopup}
                    closeScheduleModal={closeScheduleModal}
                    rescheduleModalRef={rescheduleModalRef}
                    userCookie={userCookie}
                    item={selectedOrder}
                    handleNotifyAlert={handleNotifyAlert}
                    locations={locations}
                    SetLoadingVisibility={SetLoadingVisibility}
                    setSchedulePopup={setSchedulePopup}
                />
            }
            {isOrderDetailsPopup &&
                //  <CustomPopUpTemplete setOpenModal={setOrderDetailsVisibility} content={
                <OrderDetailsTab
                    setShowPopup={setIsOrderDetailsPopup}
                    item={selectedOrder}
                    SetLoadingVisibility={SetLoadingVisibility}
                    // cancelStatusUpdate={() => { socket.emit('client:cancel-order', orderDetails) }}
                    handleNotifyAlert={handleNotifyAlert}
                    userCookie={userCookie}
                // allNewPanelLog={allNewPanelLog}
                // updateOrdersPage={updateOrdersPageData} 
                />
                // }/>
            }
        </div>
    )
}

export default DailyPlans;



