import React from 'react'
import { InventoryBackEndIP } from '../../MyConfiguration'

const CreateManualOrder = ({userCookie}) => {
    const handleRedirectToOMSClick = () => {
        window.open(InventoryBackEndIP + `/user/oms-login/?token=${userCookie.token}&link=/create-order`, "_blank")
      }
    return (
        <div className='InventoryManagement-container'>
            <div className='InventoryManagement-container__ims' onClick={handleRedirectToOMSClick}>Create Manual Order</div>
            <div className='InventoryManagement-container__ims-note'>This feature is available on Techeagle OMS website. Click on above link</div>
        </div>
    )
}

export default CreateManualOrder