import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import "./UrgentOrders.scss"
import { useSelector } from 'react-redux';
import { selectHubNodeLocations } from '../../ReduxStore/HubNodeLocationsSlice';
import { selectAllOrders } from '../OrdersTabContent/ordersDataSlice';
import { backEndIP, InventoryBackEndIP } from '../MyConfiguration';
import axios from 'axios';
import OrderDetailsTab from '../OrderDetailsTab/OrderDetailsTab';
const UrgentOrders = ({ userCookie, handleNotifyAlert, SetLoadingVisibility, setSelectedOrder, handleSchedulePopup, setIsOrderDetailsPopup, isUrgentOrderNeedToShow, setIsUrgentOrdersNeedTohow , urgentOrders}) => {

    const allOrders = useSelector(selectAllOrders)
    // const [orders, setOrders] = useState([])
   
    const locations = useSelector(selectHubNodeLocations);

    // const DurationOptions = {
    //     "Today": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
    //     "Last 3 days": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "day"),
    //     "Last 1 week": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "week"),
    //     "Last 2 weeks": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(2, "week"),
    //     "Last 1 Month": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, "month"),
    //     "Last 3 Months": dayjs().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(3, "month"),
    // };
   

    console.log("ALL ORDERS", allOrders, locations)
    return (
        <div className='UrgentOrders-container'>
            <div className='UrgentOrders-container__index'
            >
                <div className='UrgentOrders-container__index__order-no UrgentOrders-container__index__term'
                    onClick={() => {

                    }}>
                    Order Id
                </div>
                <div className='UrgentOrders-container__index__username UrgentOrders-container__index__term'
                    onClick={() => {

                    }}>
                    Customer
                </div>
                <div className='UrgentOrders-container__index__source UrgentOrders-container__index__term'
                    onClick={() => {

                    }}>
                    Source
                </div>
                <div className='UrgentOrders-container__index__destination UrgentOrders-container__index__term'
                    onClick={() => {

                    }}>
                    Destination
                </div>
                <div className='UrgentOrders-container__index__payload UrgentOrders-container__index__term'
                    onClick={() => {

                    }}>
                    Payload
                </div>
                <div className='UrgentOrders-container__index__delivery-type UrgentOrders-container__index__term'
                    onClick={() => {

                    }}>
                    Delivery Type
                </div>
                <div className='UrgentOrders-container__index__delivery-time UrgentOrders-container__index__term'
                    onClick={() => {

                    }}>
                    Delivery Time
                </div>
                <div className='UrgentOrders-container__index__status-update UrgentOrders-container__index__term'
                    onClick={() => {

                    }}>
                    Actions
                </div>
                {/* <div className='UrgentOrders-container__index__order-details UrgentOrders-container__index__term'>
                    Order details
                </div> */}

            </div>
            {urgentOrders.length > 0 ? urgentOrders?.map((order, odIdx) => {
                return <div key={order._id} className='UrgentOrders-datacontainer__row'>
                    <div className='UrgentOrders-datacontainer__row__header'>
                        <div className='UrgentOrders-datacontainer__row__header__orderId'>
                            {locations[order.source_location]?.name}
                            <img
                                className='UrgentOrders-datacontainer__row__header__orderId__doubleHeadedArrowSvg'
                                src={`${backEndIP}/double-arrow.svg`}
                                alt="Double-headed arrow"
                            />
                            {locations[order.destination_location]?.name}
                        </div>
                        <div className='UrgentOrders-datacontainer__row__header__droneId'>Current Status: {order.delivery_type}</div>
                        <div className='UrgentOrders-datacontainer__row__header__orderNumber'>OrderNo: {order.order_no}</div>
                        <div className='UrgentOrders-datacontainer__row__header__orderType'>Urgent Reason: {order.urgentReason}</div>
                    </div>
                    <div key={order._id} className='UrgentOrders-datacontainer__row__orderData' >
                        <div className='UrgentOrders-datacontainer__row__orderData__order-no UrgentOrders-datacontainer__row__orderData__term'
                            onClick={() => {
                                setSelectedOrder(order)
                                setIsOrderDetailsPopup(true)
                            }}>
                            {order.order_id}
                        </div>
                        <div className='UrgentOrders-datacontainer__row__orderData__username UrgentOrders-datacontainer__row__orderData__term'>
                            {order.useremail.split("@")[0]}
                        </div>
                        <div className='UrgentOrders-datacontainer__row__orderData__source UrgentOrders-datacontainer__row__orderData__term'>
                            {locations[order.source_location]?.name}
                        </div>
                        <div className='UrgentOrders-datacontainer__row__orderData__destination UrgentOrders-datacontainer__row__orderData__term'>
                            {locations[order.destination_location]?.name}
                        </div>
                        <div className='UrgentOrders-datacontainer__row__orderData__payload UrgentOrders-datacontainer__row__orderData__term'>
                            {order.total_weight}g
                        </div>
                        <div className='UrgentOrders-datacontainer__row__orderData__delivery-type UrgentOrders-datacontainer__row__orderData__term'>
                            {order.delivery_type}
                        </div>
                        <div className='UrgentOrders-datacontainer__row__orderData__delivery-time UrgentOrders-datacontainer__row__orderData__term'>
                            {order.delivery_type === "Urgent" ? `${dayjs(order.delivery_date).format("YYYY-MM-DD HH:mm").toString()}` : dayjs(order.delivery_date).format("YYYY-MM-DD HH:mm").toString()}
                        </div>
                        <div className='UrgentOrders-datacontainer__row__orderData__status-update UrgentOrders-datacontainer__row__orderData__term'>
                            <button className='UrgentOrders-datacontainer__row__orderData__status-update__btn'
                                onClick={() => {
                                    setSelectedOrder(order)
                                    handleSchedulePopup()
                                }}>
                                Schedule
                            </button>
                            <button className='UrgentOrders-datacontainer__row__orderData__status-update__details-btn'
                                onClick={() => {
                                    setSelectedOrder(order)
                                    setIsOrderDetailsPopup(true)
                                }}>
                                Details
                            </button>
                        </div>
                        {/* <div className='UrgentOrders-datacontainer__row__orderData__order-details UrgentOrders-datacontainer__row__orderData__term'>
                            <button className='UrgentOrders-datacontainer__row__orderData__order-details__btn'
                                onClick={() => {
                                    // setSelectedOrder(odIdx); setOrderDetailsVisibility(true) 
                                }}
                            >Order details</button>
                        </div> */}

                    </div>
                </div>
            }) : <p className=''>"No Urgent Orders"</p>}

        </div>
    )
}

export default UrgentOrders