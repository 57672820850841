import { createSlice } from '@reduxjs/toolkit'
// const initialState= {
//     allOrders: [],
//     filterBy: "ALL",
//     duration: new Date()
// }
export const manualOrdersDataSlice = createSlice({
    name: 'allManualOrdersData',
    initialState:{
        allOrders: [],
        filterBy: "ALL",
        duration: "date",
        scrollTop:0
    },
    reducers: {
        setAllManualOrdersData: (state, action) => {
            // console.log("setAllOrdersData", action.payload)
            state.allOrders = action.payload
        },
        updateSpecificManualOrder: (state, action) => {
            // console.log("updateSpecificOrder", action.payload)
            const tempOrders = [...state.allOrders]
            for (let i = 0; i < tempOrders.length; i++) {
                // console.log(i)
                if (tempOrders[i].order_no === action.payload.order_no) {
                    tempOrders[i] = action.payload
                    // console.log(tempOrders)
                    break;
                }
            }
            state.allOrders = tempOrders
        },
        addNewManualOrderToAllOrders: (state, action) => {
            // console.log("addNewOrderToAllOrders", action.payload)
            const tempOrders = [action.payload,...state.allOrders];
            state.allOrders = tempOrders;
        },
        setManualOrderFilterBy: (state, action) => {
            // console.log("setOrdersFilterBy", action.payload)
            state.filterBy = action.payload;
        },
        setManualOrderDuration: (state, action) => {
            // console.log("setOrdersDuration", action.payload)
            state.duration = action.payload;
        },
        setManualOrdersScrollTop:(state, action) => {
            // console.log("setOrdersScrollTop", action.payload)
            state.scrollTop = action.payload;
        }

    }
})

export const { setAllManualOrdersData, setManualOrderFilterBy, setManualOrderDuration,updateSpecificManualOrder,setManualOrdersScrollTop,addNewManualOrderToAllOrders } = manualOrdersDataSlice.actions
// console.log(manualOrdersDataSlice)
export const selectAllManualOrdersData = (state) => state.allManualOrdersData
export const selectAllManualOrders = (state) => state.allManualOrdersData.allOrders
export const selectManualOrdersFilterBy= (state) => state.allManualOrdersData.filterBy
export const selectManualOrdersDuration = (state) => state.allManualOrdersData.duration
export const selectManualOrdersScrollTop = (state) => state.allManualOrdersData.scrollTop
export default manualOrdersDataSlice.reducer