import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "./DashboardPage.scss";
import axios from 'axios';
import { backEndIP, InventorySocketIP } from '../../components/MyConfiguration';
import { Avatar, AvatarGroup, Dropdown, Tree } from 'rsuite';
import { Cookies } from 'react-cookie';
import CustomAlertBox from '../../components/CustomAlert/CustomAlertBox';
import { customSorting } from './designationBasedModules';
import { io } from 'socket.io-client';
import { logoutAction } from '../../components/utility';

// const allPagesData = {
//     "Monitor Drone": { pageUrl: "monitor", imgUrl: "./MonitorDroneCard.png" },
//     "View All Drones": { pageUrl: "view-all-drones", imgUrl: "./ViewAllDronesCard.png" },
//     "Monitor All Drones": { pageUrl: "monitor-all-drones", imgUrl: "./MonitorAllDronesCard.png" },
//     "View Flight": { pageUrl: "view-flight", imgUrl: "./ViewFlightCard.png" },
//     "Admin Panel": { pageUrl: "adminpanel", imgUrl: "./AdminPanelCard.png" },
//     "Airspace": { pageUrl: "airspace", imgUrl: "./airspace.png" },
// }
const allPagesData = {
    "OPERATIONS MANAGEMENT": {
        pageUrl: "operation-management", imgUrl: ""
        // subPages:
        // {
        //     "MONITOR DRONE": { pageUrl: "monitor", imgUrl: "./MonitorDroneCard.png" },
        //     "MONITOR ALL DRONE": { pageUrl: "monitor-all-drones", imgUrl: "./MonitorAllDronesCard.png" }
        // }
    },
    "ORDER MANAGEMENT": { pageUrl: "ordermanagement", imgUrl: "" },
    "FLEET MANAGEMENT": { pageUrl: "fleetmanagement", imgUrl: "" },
    "DRONE VIEW & TRACKING": {
        subPages:
        {
            "VIEW FLIGHT": { pageUrl: "view-flight", imgUrl: "./ViewFlightCard.png" },
            "VIEW ALL DRONE": { pageUrl: "view-all-drones", imgUrl: "./ViewAllDronesCard.png" }
        }
    },
    // "AIRSPACE": { pageUrl: "airspace", imgUrl: "./airspace.png" },
}

const panelUrlMap = {
    "VIEW ALL DRONE": "view-all-drones",
    "VIEW FLIGHT": "view-flight",
    "ORDER MANAGEMENT": "ordermanagement",
    "OPERATIONS MANAGEMENT": "operationmanagement",
    // "MONITOR ALL DRONE": "monitor-all-drones",
    // "MONITOR DRONE": "monitor",
    "FLEET MANAGEMENT": "fleetmanagement",
}
// const allPages = [
//     "Monitor Drone",
//     "Monitor All Drones",
//     "Admin Panel",
//     "View All Drones",
//     "View Flight",
//     "Airspace"
// ]
const allPages = [
    "OPERATIONS MANAGEMENT",
    "ORDER MANAGEMENT",
    "FLEET MANAGEMENT",
    // "DRONE VIEW & TRACKING",
    // "AIRSPACE"
]
const moduleIndex = {
    // "FLEET MANAGEMENT": 0,
    "OPERATIONS MANAGEMENT": 0,
    "ORDER MANAGEMENT": 1,
    "FLEET MANAGEMENT": 2,
    "DRONE VIEW & TRACKING": 3,
    // "AIRSPACE"
}
const dashboardTree = [
    {
        label: "OPERATIONS MANAGEMENT",
        children: [
            // {
            //     label: "MONITOR DRONE",
            //     children: [],
            // },
            // {
            //     label: "MONITOR ALL DRONE",
            //     children: [],
            // }
        ],
    },
    {
        label: "ORDER MANAGEMENT",
        children: [],
    },
    {
        label: "FLEET MANAGEMENT",
        children: [],
    },
    {
        label: "DRONE VIEW & TRACKING",
        children: [
            {
                label: "VIEW FLIGHT",
                children: [],
            },
            {
                label: "VIEW ALL DRONE",
                children: [],
            }],
    }
]



const TreeNode = ({ node, activeModel, setActiveModule }) => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded((prev) => !prev);
        setActiveModule(node.label)
    };

    console.log("node", node)
    return (
        <div className={`DashboardTree ${activeModel === node.label ? 'active-sidebar-item' : ''}`}>
            {/* Parent Node */}
            <div
                // onClick={node.children?.length ? handleToggle : () => { navigate("/" + panelUrlMap[node.label]) }}
                onClick={() => { navigate("/" + panelUrlMap[node.label]) }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    fontWeight: node.children?.length ? 'bold' : 'bold',
                    marginBottom: 'calc(10px * var(--heightmultiplier))',
                    // activeModel
                }}
            >
                {/* {node.children?.length ? (
                    <span style={{ marginRight: 'calc(5px * var(--widthmultiplier))' }}>
                        {isExpanded ? '-' : '+'}
                    </span>
                ) :  */}
                <span style={{ marginRight: 'calc(15px * var(--widthmultiplier))' }} />
                {/* } */}
                {node.label}
            </div>
            {/* Children Nodes */}
            {/* {isExpanded && node.children?.map((child, index) => (
                <TreeNode key={index} node={child} />
            ))} */}
        </div>
    );
};

const DashboardPageCard = ({ title, imgUrl, navigateTo, onClick, activeModel, setActiveModule }) => {
    const navigate = useNavigate();

    console.log("title", title)
    return (
        <div className={`${imgUrl ? "DashboardPage-PageCard-containerWithImage" : "DashboardPage-PageCard-container"} ${activeModel === title ? "DashboardPage-PageCard-container__active" : ""}`}
            onClick={() => {
                if (navigateTo) {
                    navigate("/" + navigateTo)
                    // setActiveModule(title)
                } else if (onClick) {
                    onClick()
                    // setActiveModule(title)
                }
                setActiveModule(title)
            }} >
            {imgUrl ? <img className='DashboardPage-PageCard-container__image' src={imgUrl} alt={title} /> : null}
            {imgUrl ? null : <div className='DashboardPage-PageCard-container__title'>{title ? title : null}</div>}
        </div>
    )
}
const DashboardPage = ({ userCookie, themeGlobal, removeCookie }) => {
    const navigate = useNavigate();
    const [userAvailableModules, setUserAvailableModules] = useState([])
    const [socket, setSocket] = useState(io(InventorySocketIP, {
        autoConnect: false,
        transports: ["websocket"],
        auth: {
            token: userCookie.token
        }
    }))
    // const [userInitial, setUserInitial] = useState(userCookie.username[0].toLocaleUpperCase())
    const [alertBoxMesage, setAlertBoxMessage] = useState({
        message: "",
        func: () => { },
    });
    const [customAlertVisibility, setCustomAlertVisibility] = useState(false);
    const [activeModule, setActiveModule] = useState(null);
    useEffect(() => {
        axios(`${backEndIP}/designation/get-my-modules`, {
            method: "GET",
            headers: { authorization: userCookie.token }
        }).then(designationsResp => {
            // console.log("designationsResp", designationsResp?.data)
            if (Array.isArray(designationsResp?.data?.data)) {
                const availabelTabs = customSorting(designationsResp?.data?.data, allPages, "label")
                setUserAvailableModules(availabelTabs);
                if (designationsResp?.data?.data.length === 1 && designationsResp?.data?.data) {
                    navigate("/" + allPagesData[designationsResp?.data?.data[0]].pageUrl)
                }
            }
        }).catch(error => {
            console.log(error?.response?.data)
        })
    }, [userCookie.token])

    const renderToggle = props => (
        <Avatar circle {...props} src="" style={{ backgroundColor: "#FD6125" }} />
    );

    const handleBackToMain = () => {
        setActiveModule(null);
    };
    useEffect(() => {
        const availabelTabs = customSorting(userAvailableModules, allPages, "label")
        // console.log("availabelTabs", availabelTabs)
        setUserAvailableModules(availabelTabs)
    }, [userAvailableModules])
    console.log("userAvailableModules", userAvailableModules)
    return (
        <div className='DashboardPage-container'>
            <div className='DashboardPage-container__Sidebar'>
                <div className='DashboardPage-container__Sidebar__logodiv'>
                    <img className='DashboardPage-container__Sidebar__logodiv__logoimg'
                        src={themeGlobal.nameLogo}
                        alt='logo'
                        onClick={() => { setActiveModule(null) }}
                    />
                </div>
                <div className='DashboardPage-container__Sidebar__logodiv-border'></div>
                <div className='DashboardPage-container__Sidebar__container'>
                    <div className='DashboardPage-container__Sidebar__container__block'>
                        {userAvailableModules.map((node, index) => {
                            if (allPages.includes(node.label))
                                return <TreeNode key={index} node={node} activeModel={activeModule} setActiveModule={setActiveModule} />
                            return null
                        })}
                    </div>
                    {/* <div className='view-sidebar__tabs-container'>

                        {currentAdminTabs.map((tabName) => {
                            return <TabBox key={tabName} tabName={tabName} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                        })}


                    </div> */}
                </div>
                <div className='DashboardPage-container__Sidebar__designation-container'>
                    {/* <div className='view-order-map__topInfobar__designation-container__title'>Designation</div>
                    <div className='view-order-map__topInfobar__designation-container__value'>{adminDesignation}</div> */}
                    <div className='DashboardPage-container__Sidebar__designation-container__value'><a href='https://forms.gle/AxNPLGbvnpEoxHcq6' target='blank' className='ReportbugsandErrors'>Report bugs / Error</a></div>
                </div>
                <button className="DashboardPage-container__Sidebar__logout"
                    onClick={() => {
                        setAlertBoxMessage({
                            message:
                                "Are you sure you want Logout?",
                            func: () => { logoutAction(removeCookie, navigate, socket) }
                        });
                        setCustomAlertVisibility(true);
                    }}>
                    {/* <img
                            src="left_arrow_icon.svg"
                            alt={""}
                            style={{ height: "20px", width: "20px" }}
                        /> */}
                    Logout
                </button>
            </div>
            <div className='DashboardPage-container__RightContainer'>
                <div className='DashboardPage-container__header'>
                    <div className='DashboardPage-container__header__userIcon'>
                        <Dropdown renderToggle={renderToggle}>
                            <Dropdown.Item panel style={{ padding: 0, width: 100 }}>
                                {/* <p>Signed in as</p> */}
                                {/* <strong>{userCookie.username}</strong> */}
                            </Dropdown.Item>
                            {/* <Dropdown.Separator /> */}
                            <Dropdown.Item>{`Your profile`}</Dropdown.Item>
                            {/* <Dropdown.Item>Your stars</Dropdown.Item>
                        <Dropdown.Item>Your Gists</Dropdown.Item> */}
                           <Dropdown.Item
                           onClick={() => {
                            setAlertBoxMessage({
                                message:
                                    "Are you sure you want to navigate to the Clearsky Training Portal?",
                                func: () => {
                                    setCustomAlertVisibility(false);
                                    // window.location.href = "https://training.clearsky.techeagle.org/";
                                    window.open("https://training.clearsky.techeagle.org/", "_blank");
                                    }
                            });
                            setCustomAlertVisibility(true);
                        }}
                           >Get Training</Dropdown.Item>
                            <Dropdown.Separator />
                            <Dropdown.Item><a href="https://forms.gle/PdXdQM5Lfnxv5Bc3A" target='blank' className='supportlink'>Support</a></Dropdown.Item>
                            {/* <Dropdown.Item>Settings</Dropdown.Item> */}
                            <Dropdown.Item onClick={() => {
                                setAlertBoxMessage({
                                    message:
                                        "Are you sure you want Logout?",
                                    func: () => { logoutAction(removeCookie, navigate, socket) }
                                });
                                setCustomAlertVisibility(true);
                            }}>Log Out</Dropdown.Item>
                        </Dropdown>
                        <div className='DashboardPage-container__header__userIcon__username'>{userCookie.username}</div>
                    </div>
                </div>
                <div className='DashboardPage-container__midCont'>

                    {/* {allPages.map((page, i) => {
                      const pageData = allPagesData[page] 
                            return <DashboardPageCard key={page + i} title={page} navigateTo={pageData.pageUrl} imgUrl={pageData.imgUrl} subModules={pageData.subPages}/>
                        
                    })} */}
                    {activeModule ? (
                        <div className='DashboardPage-container__SubModulesView'>
                            <div className='DashboardPage-container__SubModulesView__header'>
                                <button
                                    className='DashboardPage-container__BackButton'
                                    onClick={handleBackToMain}>
                                    <svg width="25" height="20" viewBox="0 0 15 9" fill="var(--primaryTextColor)" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L5.94777 7.08957C6.74816 8.07465 8.25185 8.07465 9.05223 7.08957L14 1" stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                </button>
                                <h2>{activeModule}</h2>
                            </div>
                            {/* <div className='DashboardPage-container__SubModulesView__body'>
                                <div className='DashboardPage-container__CardContainer'>
                                    {userAvailableModules[moduleIndex[activeModule]].children.map(
                                        (page, i) => {
                                            console.log("userAvailableModules[moduleIndex[activeModule]].children", i, page, allPagesData[activeModule]["subPages"])
                                            return <DashboardPageCard
                                                activeModel={activeModule}
                                                setActiveModule={setActiveModule}
                                                key={`${page.label}-${i}`}
                                                title={page.label}
                                                navigateTo={allPagesData[activeModule]["subPages"][page.label].pageUrl}
                                                imgUrl={allPagesData[activeModule]["subPages"][page.label].imgUrl}
                                            />
                                        }
                                    )}
                                </div>
                            </div> */}
                        </div>
                    ) : <div className='DashboardPage-container__CardContainer'>

                        {userAvailableModules.map((page, i) => {
                            // console.log("page new", page)
                            const pageData = allPagesData[page.label];
                            if (allPages.includes(page.label))
                                return (
                                    <DashboardPageCard
                                        activeModel={activeModule}
                                        setActiveModule={setActiveModule}
                                        key={`${page.label}-${i}`}
                                        title={page.label}
                                        imgUrl={pageData.imgUrl}
                                        navigateTo={pageData.pageUrl}
                                        onClick={
                                            pageData.subPages
                                                ? () => setActiveModule(page.label)
                                                : null
                                        }
                                    />
                                );
                            return null
                        })}
                    </div>
                    }

                </div>
            </div>
            {customAlertVisibility && <CustomAlertBox setOpenModal={setCustomAlertVisibility} messageToDisplay={alertBoxMesage} />}
        </div>
    )
}

export default DashboardPage