import React, { useEffect, useState } from 'react';
import './Sitl.scss';
import { sitlIP } from '../MyConfiguration';
import axios from 'axios';

const Sitl = ({ userCookie,  handleNotifyAlert,
    SetLoadingVisibility }) => {

    const [sitlStatus, setSitlStatus] = useState("Not Started")
    const [userDetails, setUserDetails] = useState({ droneID: "", lat: "25.647599838681714", long: "90.32645678880044", asl: "610", heading: "60" })
    const [isSitlKillRequired, setIsSitlKillRequired]=useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value,
        });
    };
    
    // const handleGetStatusClick = async () => {
        
    //         SetLoadingVisibility(true)
    //         try {
    //             const getStatusResp = await axios({
    //                 url: `${sitlIP}/get_sitl_status?useremail=${userCookie.useremail}`,
    //                 method: "GET",
    //                 // headers: {
    //                 //     Authorization: `${userCookie.token}`
    //                 // }
    //                 data:{token: userCookie.token}
    //             });
    //             console.log("getStatusResp", getStatusResp.data)
    //             setSitlStatus(getStatusResp.data?.status)
    //             setUserDetails((prev) => ({
    //                 ...prev,
    //                 droneID: getStatusResp?.data?.droneId,
    //             }));
    //             // handleNotifyAlert(
    //             //     "notify",
    //             //     `${"Stutus fetched Successfully"}`,
    //             //     "success",
    //             //     () => { }
    //             // );
                 
    //             SetLoadingVisibility(false)
    //             setIsSitlKillRequired(true);
    //         }
    //         catch (err) {
    //             console.log(err)
    //             SetLoadingVisibility(false)
    //             // window.alert("Some error occered while getting sitl status")
    //             // handleNotifyAlert(
    //             //     "notify",
    //             //     `${err.response.data}`,
    //             //     "failed",
    //             //     () => { }
    //             // )
    //             setIsSitlKillRequired(false)

    //         }
       
    // }
    const handleGetStatusClick = async () => {
        SetLoadingVisibility(true);
    
        const source = axios.CancelToken.source();
        const timeout = setTimeout(() => {
            source.cancel("Server Not Responding");
            SetLoadingVisibility(false);
        }, 5000);
    
        try {
            const getStatusResp = await axios({
                url: `${sitlIP}/get_sitl_status?useremail=${userCookie.useremail}`,
                method: "GET",
                headers: {
                    Authorization: userCookie.token,
                },
                cancelToken: source.token, 
            });
    
            clearTimeout(timeout);
    
            console.log("getStatusResp", getStatusResp.data);
    
            setSitlStatus(getStatusResp.data?.status);
            setUserDetails((prev) => ({
                ...prev,
                droneID: getStatusResp?.data?.droneId,
            }));
    
            // handleNotifyAlert(
            //     "notify",
            //     "Status fetched Successfully",
            //     "success",
            //     () => {}
            // );
    
            setIsSitlKillRequired(true);
        } catch (err) {
            clearTimeout(timeout);
    
            if (axios.isCancel(err)) {
                console.error("Request canceled:", err.message);
            } else {
                console.error("Error fetching SITL status:", err);
            }
            // handleNotifyAlert(
            //     "notify",
            //     err.response?.data || "An error occurred while getting SITL status",
            //     "failed",
            //     () => {}
            // );
    
            setIsSitlKillRequired(false);
        } finally {
            clearTimeout(timeout);
            SetLoadingVisibility(false); 
        }
    };
    

    useEffect(() => {
        handleGetStatusClick();
    }, []);

    const handleStopSitlClick = async () => {
            try {
                const killSitlResp = await axios({
                    url: `${sitlIP}/kill?useremail=${userCookie.useremail}`,
                    method: "GET",
                    // headers: {
                    //     Authorization: `${userCookie.token}`
                    // }
                    data:{token: userCookie.token}
                });
                console.log("killSitlResp", killSitlResp.data)
                setUserDetails((prev) => ({
                    ...prev,
                    droneID:"",
                }));
                setSitlStatus(killSitlResp.data)
                setIsSitlKillRequired(false)
            }
            catch (err) {
                console.log(err)
                // window.alert("Some error occered while killing sitl instance")
                // window.alert(err.response.data)
                  handleNotifyAlert(
                    "notify",
                    `${err.response.data}`,
                    "failed",
                    () => { }
                )
            }
    }

    const handleStartSitlClick = async () => {
         console.log(sitlIP)
            SetLoadingVisibility(true)
            try {
                const startSitlResp = await axios({
                    url: `${sitlIP}/new_sitl_start`,
                    method: "POST",
                    // headers: {
                    //     Authorization: `Bearer ${userCookie.token}`
                    // },
                    data: { ...userDetails, token:userCookie.token }
                });
                console.log("startSitlResp", startSitlResp.data)
                setSitlStatus(startSitlResp?.data?.data?.sitl_status)
                userDetails.droneID = startSitlResp?.data?.data?.droneId
                setUserDetails((prev) => ({
                    ...prev,
                    droneID: startSitlResp?.data?.data?.droneId,
                }));
                
                SetLoadingVisibility(false)
                setIsSitlKillRequired(true)
                // handleGetStatusClick()
                handleNotifyAlert(
                    "notify",
                    `${"SITL started Successfully"}`,
                    "success",
                    () => { }
                );
               
            }
            catch (err) {
                console.log(err)
                // window.alert("Some error occered while killing sitl instance")
                // window.alert(err.response?.data?.message)
                SetLoadingVisibility(false)
                handleNotifyAlert(
                    "notify",
                    `${err.response.data}`,
                    "failed",
                    () => { }
                )
                
            }
           
    
    }

    // Simple validation function
    // const validate = () => {
    //     const newErrors = {};
    //     if (!userDetails.droneID) newErrors.droneId = 'Drone ID is required';
    //     if (!sitlStatus) newErrors.sitl_status = 'SITL Status is required';
    //     if (isNaN(userDetails.lat) || userDetails.lat === '') newErrors.latitude = 'Latitude must be a valid number';
    //     if (isNaN(userDetails.long) || userDetails.long === '') newErrors.longitude = 'Longitude must be a valid number';
    //     if (isNaN(userDetails.asl) || userDetails.asl === '') newErrors.altitude = 'Altitude must be a valid number';
    //     if (isNaN(userDetails.heading) || userDetails.heading === '') newErrors.heading = 'Heading must be a valid number';
      
    //     setErrors(newErrors);
    //     return Object.keys(newErrors).length === 0;
    // };

    return (
        <div className="SitlContainer">
            <h2 className="SitlContainer__heading">SITL</h2>
            <div className="SitlContainer__form">
                <div className="SitlContainer__form__inputContainer">
                    <div className='SitlContainer__form__inputContainer__inputinnerContainer'>
                        <label htmlFor="droneId">Drone Id</label>
                        <div className='SitlContainer__form__inputContainer__inputinnerContainer__colon'>:</div>
                        <input
                             className='SitlContainer__form__inputContainer__inputinnerContainer__droneId'
                            type="text"
                            id="droneId"
                            name="droneId"
                            value={userDetails.droneID}
                            onChange={handleChange}
                            placeholder="Drone ID"
                            readOnly
                        />
                    </div>
                    {/* <span className="SitlContainer__form__inputContainer__error" style={{ visibility: errors.droneId ? "visible" : "hidden", color: "red" }}>{errors.droneId ? errors.droneId : "test"}</span> */}
                </div>

                <div className="SitlContainer__form__inputContainer">
                    <div className='SitlContainer__form__inputContainer__inputinnerContainer'>
                        <label htmlFor="sitl_status">SITL Status</label>
                        <div className='SitlContainer__form__inputContainer__inputinnerContainer__colon'>:</div>
                        <input
                            type="text"
                            id="sitl_status"
                            name="sitl_status"
                            value={sitlStatus}
                            onChange={handleChange}
                            placeholder="Enter SITL Status"
                            readOnly
                        />
                        {/* <button className='SitlContainer__form__inputContainer__inputinnerContainer__getStatus' onClick={() => handleGetStatusClick()}>Get Status</button> */}
                    </div>
                    {/* <span className="SitlContainer__form__inputContainer__error" style={{ visibility: errors.sitl_status ? "visible" : "hidden", color: "red" }} >{errors.sitl_status ? errors.sitl_status : "test"}</span> */}
                </div>

                <div className="SitlContainer__form__inputContainer">
                    <div className='SitlContainer__form__inputContainer__inputinnerContainer'>
                        <label htmlFor="latitude">Latitude</label>
                        <div className='SitlContainer__form__inputContainer__inputinnerContainer__colon'>:</div>
                        <input
                            type="number"
                            id="latitude"
                            name="latitude"
                            value={userDetails.lat}
                            onChange={handleChange}
                            placeholder="Enter Latitude"
                        />
                    </div>
                    {/* <span className="SitlContainer__form__inputContainer__error" style={{ visibility: errors.latitude ? "visible" : "hidden", color: "red" }}>{errors.latitude ? errors.latitude : "test"}</span> */}
                </div>

                <div className="SitlContainer__form__inputContainer">
                    <div className='SitlContainer__form__inputContainer__inputinnerContainer'>
                        <label htmlFor="longitude">Longitude</label>
                        <div className='SitlContainer__form__inputContainer__inputinnerContainer__colon'>:</div>
                        <input
                            type="number"
                            id="longitude"
                            name="longitude"
                            value={userDetails.long}
                            onChange={handleChange}
                            placeholder="Enter Longitude"
                        />
                    </div>
                    {/* <span className="SitlContainer__form__inputContainer__error" style={{ visibility: errors.longitude ? "visible" : "hidden", color: "red" }}>{errors.longitude ? errors.longitude : "test"}</span> */}
                </div>

                <div className="SitlContainer__form__inputContainer">
                    <div className='SitlContainer__form__inputContainer__inputinnerContainer'>
                        <label htmlFor="altitude">ASL(alt[m])</label>
                        <div className='SitlContainer__form__inputContainer__inputinnerContainer__colon'>:</div>
                        <input
                            type="number"
                            id="altitude"
                            name="altitude"
                            value={userDetails.asl}
                            onChange={handleChange}
                            placeholder="Enter ASL(alt[m])"
                        />
                    </div>
                    {/* <span className="SitlContainer__form__inputContainer__error" style={{ visibility: errors.altitude ? "visible" : "hidden", color: "red" }}>{errors.altitude ? errors.altitude : "test"}</span> */}
                </div>

                <div className="SitlContainer__form__inputContainer">
                    <div className='SitlContainer__form__inputContainer__inputinnerContainer'>
                        <label htmlFor="heading">Heading</label>
                        <div className='SitlContainer__form__inputContainer__inputinnerContainer__colon'>:</div>
                        <input
                            type="number"
                            id="heading"
                            name="heading"
                            value={userDetails.heading}
                            onChange={handleChange}
                            placeholder="Enter Heading"
                        />
                    </div>
                    {/* <span className="SitlContainer__form__inputContainer__error" style={{ visibility: errors.heading ? "visible" : "hidden", color: "red" }}>{errors.heading ? errors.heading : "test"}</span> */}
                </div>
                <div className='SitlContainer__form__submit'>
                <button className='SitlContainer__form__submit__killBtn' onClick={() => handleGetStatusClick()}>Get Status</button>
                  { !isSitlKillRequired ? <button className='SitlContainer__form__submit__startBtn' type="submit" onClick={handleStartSitlClick}>Start SITL</button> :
                    <button className='SitlContainer__form__submit__killBtn' type="submit" onClick={handleStopSitlClick}>Kill SITL</button>}
                </div>
            </div>
        </div>
    );
};

export default Sitl;
