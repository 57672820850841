function setCookie(cname, cvalue) {
    const d = new Date();
    // d.setTime(d.getTime() + (exdays*24*60*60*1000));
    // let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + ";path=/";
}
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
const rad = function (x) {
    return x * Math.PI / 180;
};
const getDistanceBetweenPoints = function (p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat() - p1.lat());
    var dLong = rad(p2.lng() - p1.lng());
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
};

const getTotalDistance = (missionRoutePoints) => {
    let distance = 0
    missionRoutePoints.forEach((loc, i) => {
        if (i > 0) {
            distance += getDistanceBetweenPoints({
                lat: () => missionRoutePoints[i - 1].lat,
                lng: () => missionRoutePoints[i - 1].lng,
            }, {
                lat: () => missionRoutePoints[i].lat,
                lng: () => missionRoutePoints[i].lng,
            })
        }
    })
    return distance;
}
const logoutAction = async (removeCookie, navigate, socket) => {
    await removeCookie("token");
    await removeCookie("designation");
    await removeCookie("useremail");
    await removeCookie("username");
    await removeCookie("accessRole");
    await removeCookie("permission");
    // removeCookie("yourLocation");
    socket?.off();
    socket?.close()
    navigate("/")
}
export { setCookie, getCookie, rad, getDistanceBetweenPoints, getTotalDistance, logoutAction }