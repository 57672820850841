
import React from 'react';
import "./HubsFilter.scss";

function HubsFilter({ selectedHub, setSelectedHub, allHubLocations, userCookie }) {
    const allowedRoles = ["CEO", "Country Manager", "Zone Manager", "State Manager", "System User"];
    const hasAccessToDropdown = allowedRoles.includes(userCookie.accessRole);
    console.log(hasAccessToDropdown);

    return (
        <div className='hubs-filter-value'>
            {hasAccessToDropdown && (
                <div className="hubs-filter-value__container">
                   
                    <label htmlFor="hub-select" className="hubs-filter-value__container__label">
                        Select Hub:
                    </label>

                    <select
                        id="hub-select"
                        className="hubs-filter-value__container__select"
                        onChange={(e) => {
                            setSelectedHub(e.target.value);
                        }}
                        value={selectedHub}
                    >
                        <option className="hubs-filter-value__container__select__option" value={"All Hubs"}>
                            All Hubs
                        </option>
                        {Object.keys(allHubLocations).map((hubId, i) => (
                            <option
                                key={hubId}
                                className="hubs-filter-value__container__select__option"
                                value={hubId}
                            >
                                {allHubLocations[hubId]}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
}

export default HubsFilter;
