import React, { useState } from 'react'
import { Placeholder, Tabs } from 'rsuite'
import './InventoryManagement.scss'
import { InventoryBackEndIP } from '../../MyConfiguration';

const InventoryManagement = ({userCookie}) => {
    const handleRedirectToOMSClick = () => {
        window.open(InventoryBackEndIP + `/user/oms-login/?token=${userCookie.token}&link=/manage-inventory`, "_blank")
      }
    return (
        <div className='InventoryManagement-container' >
            <div className='InventoryManagement-container__ims' onClick={handleRedirectToOMSClick}>Inventory Management System</div>
            <div className='InventoryManagement-container__ims-note'>This feature is available on Techeagle OMS website. Click on above link</div>
        </div>
    )
}

export default InventoryManagement