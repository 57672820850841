import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react'
import { DatePicker } from 'rsuite';
import "./ReschedulePopup.scss"
import { use } from 'react';
import { fetchOrderDeliverySlots } from '../../utility/fetchOrderDeliverySlots';
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import CancellationOrderTemplateContent from '../OrderCancellationPopup/CancellationOrderTemplateContent';
import OrderCancellationPopup from '../OrderCancellationPopup/OrderCancellationPopup';
import { useLocation } from 'react-router-dom';
const ReschedulePopup = ({ setHandelReschedulePopup, locations, selectedFlight, handleNotifyAlert, userCookie, SetLoadingVisibility }) => {
    const modalRef = useRef();
    const rescheduleModalRef = useRef();
    const [scheduleDate, setScheduleDate] = useState("");
    const [availableOrderSlots, setAvailableOrderSlots] = useState([]);
    // const [orderCancelReason, setOrdercancellationReason] = useState("")
    const [cancellationPopup, setCancellationPopup] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [reschedulePopup, setReschedulePopup] = useState(false)
    const [rescheduleReason, setRescheduleReason] = useState("");
    const [errorRescheduleReason, setErrorRescheduleReason] = useState("");
    const closeModal = e => {
        if (modalRef.current === e.target) {
            setHandelReschedulePopup(false);
        }
    };
    
    const location =useLocation();
    // console.log("location", location);
    function closeReschedule() {
        setHandelReschedulePopup(false);
    }
    const closeRescheduleModal = e => {
        if (rescheduleModalRef.current === e.target) {
            setReschedulePopup(false);
        }
    };
    function closeReschedulePopup() {
        setReschedulePopup(false);
    }
    const handleOrderCancel = () => {
        //setShowPopup(false);
        //SetLoadingVisibility(false)
        setCancellationPopup(true)
    }
    useEffect(() => {
        let hubId = selectedFlight.flights[0].start_location;

        fetchOrderDeliverySlots(hubId, userCookie, setAvailableOrderSlots, handleNotifyAlert,selectedFlight.flights.length);
    }, [selectedFlight])




    const list = {
        "date_created": "Date",
        "start_time": "Date",
        "end_time": "Date",
        "start_location": "Location",
        "end_location": "Location",
        "hub_id": "Location",
        "time_taken": "time",
        "_id": "Flight ID",
        "localFlightId": "Flight ID",
        "order_no": "Order No"
    }
    const CompileContent = (data) => {
        if (list[data] === "Date") {
            return dayjs(selectedFlight.flights[0][data]).format("YYYY-MM-DD HH:mm").toString();
        }
        else if (list[data] === "Location") {
            // console.log("list[data]", selectedFlight.flights[0][data])
            // console.log("list[data]", locations[selectedFlight.flights[0][data]].name)
            return locations[selectedFlight.flights[0][data]].name
        }
        else if (list[data] === "time") {
            return dayjs().set('hour', 0).set('minute', 0).set('second', 0).add(selectedFlight.flights[0][data], 'second').format('HH [Hours] : mm [Minutes] : ss [Seconds]');
        } else if (list[data] === "Order No") {
            if (selectedFlight.flights[0][data] === 0 || !selectedFlight.flights[0][data]) {
                return "NA"
            }
        }
        return selectedFlight.flights[0][data];
    }
    const [localFlight, setLocalFlight] = useState([])
    const flight_Data = {
        // "_id": "Flight ID",
        // "localFlightId": "Flight ID",
        "order_type": "Order Type",
        "order_id": "Order Id",
        "order_no": "Order No",
        "drone_id": "Drone ID",
        "pilot_id1": "Pilot ID",
        "pilot_id2": "Co-Pilot ID",
        "hub_id": "Hub Location",
        "date_created": "Date Created",
        //   "mission_file_name": "Mission File Name",
        "start_time": "Start Time",
        "end_time": "End Time",
        //   "time_taken": "Time Taken(HH:mm:ss)",
        "start_location": "Start Location",
        "end_location": "End Location",
        //   "distance_coverved": "Distance Covered(m)",
        "payload": "Payload(grams)",
        //   "flight_type": "Flight Type",

    }

    function handleFlightReschedule(bookingId) {
        // console.log("rescheduleReason", rescheduleReason);
        if (rescheduleReason === "") {
            setErrorRescheduleReason("Please Enter The Valid Reason");
            return;
        }
        if (rescheduleReason.length < 10) {
            setErrorRescheduleReason("At Least 10 Characters Required");
            return;
        }
        if(scheduleDate === ""){
            setErrorRescheduleReason("Please Select The Date First");
            return ;
        }
        SetLoadingVisibility(true)
        if (scheduleDate !== "") {
            if (userCookie.accessRole === "Hub Manager") {
                axios({
                    url: backEndIP + "/book/reschedule-booking",
                    method: "POST",
                    headers: {
                        authorization: userCookie.token,
                    },
                    data: { bookingId: bookingId, bookingDate: scheduleDate, reason:rescheduleReason }
                }).then((resp) => {
                    console.log("resp", resp.data)
                    handleNotifyAlert(
                        "notify",
                        `${resp.data.message}`,
                        "success",
                        () => { }
                    );
                    setHandelReschedulePopup(false)
                    SetLoadingVisibility(false)
                }).catch((err) => {
                    console.log(err)
                    SetLoadingVisibility(false);
                    handleNotifyAlert(
                        "notify",
                        `${err.response.data.message}`,
                        "failed",
                        () => { }
                    );
                    setHandelReschedulePopup(false)
                    // setSearchedFlights([])
                })
            } else {
                handleNotifyAlert(
                    "notify",
                    "Unauthorized only hub manager have access",
                    "failed",
                    () => { }
                );
                SetLoadingVisibility(false)
            }
        } else {
            handleNotifyAlert(
                "notify",
                "Please selet the date first",
                "failed",
                () => { }
            );
            SetLoadingVisibility(false)
        }
    }
    function handleReschedulePopup() {
        setReschedulePopup(true)
    }
    useEffect(() => {
        const ownFlight = [];
        const keys = Object.keys(selectedFlight.flights[0]);
        keys.forEach(key => {
            if (flight_Data[key] !== undefined)
                ownFlight.push([flight_Data[key], CompileContent(key)])
        });
        //ownFlight.pop()
        // console.log("ownFlight", ownFlight)
        setLocalFlight(ownFlight)
        // calculateDate()

    }, [selectedFlight])
    // console.log("selectedFlight", selectedFlight)

    return (
        <div onClick={closeModal} ref={modalRef} className="ReschedulePopup-modalBackground">
            <div className="ReschedulePopup-modalContainer" >
                <div className='ReschedulePopup-modalContainer__header'>
                    <div className='ReschedulePopup-modalContainer__header__title'>Order Data</div>
                    <div className='ReschedulePopup-modalContainer__header__location'>
                        <div className='ReschedulePopup-modalContainer__header__location__start'>{locations[selectedFlight.flights[0].start_location]?.name}</div>
                        <img className='ReschedulePopup-modalContainer__header__location__doubleHeadedArrowSvg'
                            src={`${backEndIP}/double-arrow.svg`} />
                        <div className='ReschedulePopup-modalContainer__header__location__end'>{locations[selectedFlight.flights[0].end_location]?.name}</div>
                    </div>
                </div>

                <div className='ReschedulePopup-modalContainer__body'>
                    {localFlight?.map((item, i) => {
                        return <div className="ReschedulePopup-modalContainer__body__item" key={i + 1}>
                            <div className="ReschedulePopup-modalContainer__body__item__title">{item[0]}</div>
                            :
                            <div className="ReschedulePopup-modalContainer__body__item__value"> {item[1]}</div>
                        </div>
                    })}

                </div>
                <div className='ReschedulePopup-modalContainer__footer'>
                    {location.pathname!=="/fleetmanagement/all-flights" && !selectedFlight.flights[0].isCompleted && !selectedFlight.flights[0].isAborted && userCookie?.accessRole === "Hub Manager" &&
                        <div className='ReschedulePopup-modalContainer__footer__btns'>
                            <button className='ReschedulePopup-modalContainer__footer__schedule__schedule-btn'
                                onClick={() => { handleReschedulePopup() }}>
                                Reschedule
                            </button>
                            <button className='ReschedulePopup-modalContainer__footer__cancel__cancel-btn'
                                onClick={() => { handleOrderCancel() }}>
                                Cancel Order
                            </button>
                        </div>}
                </div>
                <button className="ReschedulePopup-modalContainer__close" onClick={() => closeReschedule()} >X</button>
            </div>
            {cancellationPopup &&
                <OrderCancellationPopup setOpenModal={setCancellationPopup} pageTitle={"Order Cancel Form"} content={
                    <CancellationOrderTemplateContent setCancellationPopup={setCancellationPopup} setShowPopup={setShowPopup}
                        selectedFlight={selectedFlight}
                        userCookie={userCookie}
                        SetLoadingVisibility={SetLoadingVisibility}
                        // setUpdateSelf={setUpdateSelf}
                        setHandelReschedulePopup={setHandelReschedulePopup}
                        handleNotifyAlert={handleNotifyAlert}
                    // orderListItemCopy={orderListItemCopy}
                    // orderListItems={orderListItems}
                    // updateOrderDetailsData={updateOrderDetailsData}
                    // index={index} 
                    />}
                />}
            {/* {cancellationPopup &&
                <CustomPopUpTemplete setOpenModal={setCancellationPopup} pageTitle={"Order Cancel Form"} content={
                    <CancellationOrderTemplateContent setCancellationPopup={setCancellationPopup} setShowPopup={setShowPopup}
                        orderData={se}
                        userCookie={userCookie}
                        SetLoadingVisibility={SetLoadingVisibility}
                        handleNotifyAlert={handleNotifyAlert}
                        index={index} />}
                />} */}
            {reschedulePopup &&
                <div onClick={closeRescheduleModal} ref={rescheduleModalRef} className='ReschedulePopup-modalBackground__reschedule'>
                    <div className='ReschedulePopup-modalBackground__reschedule__popup'>
                        <button className="ReschedulePopup-modalContainer__close" onClick={() => closeReschedulePopup()} >X</button>
                        <h3 className='ReschedulePopup-modalBackground__reschedule__popup__title'> Reschedule Order</h3>
                        <div className='ReschedulePopup-modalBackground__reschedule__popup__body'>
                            <DatePicker className='ReschedulePopup-modalContainer__footer__schedule__datePicker'
                                format="MM/dd/yyyy"
                                oneTap={true}
                                value={scheduleDate}
                                onChange={(date) => setScheduleDate(date)}
                                shouldDisableDate={(date) => {
                                    const availableDates = availableOrderSlots.map(slot => dayjs(slot).startOf('day'));
                                    const selectedDate = dayjs(date).startOf('day');
                                    const today = dayjs().startOf('day');

                                    return selectedDate.isSame(today) || !availableDates.some(availableDate => availableDate.isSame(selectedDate));
                                }}

                                placement='auto'
                            />
                            <textarea
                                className='ReschedulePopup-modalBackground__reschedule__popup__body__textArea'
                                placeholder="Enter the reason for rescheduling..."
                                value={rescheduleReason}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setRescheduleReason(value);

                                    if (value.length < 10 && value !== "") {
                                        setErrorRescheduleReason("At Least 10 Characters Required");
                                    } else {
                                        setErrorRescheduleReason(""); // Clear error if valid
                                    }
                                }}
                            ></textarea>
                         
                                <span
                                    style={{ visibility: errorRescheduleReason!=="" ? "visible" : "hidden" }}
                                    className='ReschedulePopup-modalBackground__reschedule__popup__body__error'
                                >
                                    {errorRescheduleReason ? errorRescheduleReason :"All Good"}
                                </span>
                        
                            <button className='ReschedulePopup-modalContainer__footer__schedule__schedule-btn'
                                onClick={() => { handleFlightReschedule(selectedFlight.flights[0].dronebookingId) }}
                                >
                                Reschedule
                            </button>
                        </div>
                    </div>
                </div>}

        </div >
    )
}

export default ReschedulePopup