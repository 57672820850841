import { configureStore } from '@reduxjs/toolkit'
import ordersFilterByReducer from "../components/OrdersTabContent/ordersFilterBySlice"
import ordersDataSliceReducer from "../components/OrdersTabContent/ordersDataSlice"
import manualOrdersDataSliceReducer from "../components/ManualOrdersTabContent/manualOrdersDataSlice"
import HubNodeLocationsReducer from "./HubNodeLocationsSlice"
import HelpContentReducer from "../components/SupportTabs/HelpContentDataSlice"
export default configureStore({
    reducer: {
        ordersFilterBy: ordersFilterByReducer,
        allOrdersData:ordersDataSliceReducer,
        allManualOrdersData:manualOrdersDataSliceReducer,
        hubNodeLocations:HubNodeLocationsReducer,
        helpContentData:HelpContentReducer
    },
})