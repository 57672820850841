import React, { useEffect, useRef, useState } from "react";
import "./CustomNotification.css";
import { backEndIP } from "../MyConfiguration";

function CustomNotification({ setOpenModal, messageToDisplay }) {
  const modalRef = useRef();

  const [timerId, setTimerId] = useState({})

  const icons = {
    success: `${backEndIP}/SuccessIcon-512.svg`,
    failed:  `${backEndIP}/failedIcon-480.svg`,
    alert: `${backEndIP}/AlertBoxIcon.svg`,
  }

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
      clearTimeout(timerId)
    }
  };

  useEffect(() => {
    if (messageToDisplay.type === "notify") {
      const Id = setTimeout(() => {
        modalRef.current.click()
      }, 2000);
      setTimerId((prev) => { clearTimeout(prev); return Id });
      return () => {
        clearTimeout(Id)
      }
    }
  }, [])



  return (
    <div onClick={closeModal} ref={modalRef} className="modalPopUpBackground">
      <div className="modalPopUpContainer" >
        <img className="custom-notify-box-img" src={icons[messageToDisplay.msgtype]} alt="" />
        {/* <div className="custom-notify-box-name">Alert</div> */}
        <div className="custom-notify-box-msg">{messageToDisplay.message}</div>
       {messageToDisplay.type ==="alert" && <div className="custom-alert-box-button-container">
          <div onClick={() => { modalRef.current.click(); }} id="cancelBtn">Cancel</div>
          <div onClick={() => { messageToDisplay.func();modalRef.current.click(); }} id="OkayBtn">OK</div>
        </div>}
      </div>
    </div>

  );
}

export default CustomNotification;
