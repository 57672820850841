import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import HubAddPopUp from "../HubAddPopUp/HubAddPopUp";
import { backEndIP } from "../MyConfiguration";
import "./CreateHubPanel.scss";
import { validateCountry, validateDistrict, validateFullAddress, validateLatitude, validateLocationName, validateLongitude, validatePinCode, validateState, validateTagName, validateZone } from "../../utility/hub form/hubFormFunction"



const InputFieldBox = ({ item, setNewHubData, }) => {
  const InputRef = useRef()
  const handleInputChange = () => {
    setNewHubData((prev) => {
      let obj = { ...prev };
      obj[item.key] = InputRef.current.value;
      return obj;
    });
  };

  return (
    <div className="CreateNewHubContainer__input-row-cont" key={item.key}>
      <div className="CreateNewHubContainer__input-row-cont__label"
      >{item.label}</div>
      <div className="CreateNewHubContainer__input-row-cont__colon"> : </div>
      <input ref={InputRef}
        className="CreateNewHubContainer__input-row-cont__input"
        type={item.type}
        onChange={handleInputChange}
      ></input>
    </div>
  );
};
const InputDetailField = ({ input, label, isMandatory, state, setState, keyName, width, type, validate }) => {

  const [error, setError] = useState({ success: false, message: "none" })
  console.log("Math.round(width / 0.33", Math.round(width / 0.33))
  return <div className={`CreateNewNode-maincontainer__InputDetailField`} style={{ width: `${Math.round(width * 100)}%`, flexGrow: `${Math.round(width / 0.33)}` }}>
    <div className='CreateNewNode-maincontainer__InputDetailField__label'>{label}
      {isMandatory ? "*" : "(Optional)"}
      {!error.success ? <span>{error.message}</span> : null}</div>
    <input className='CreateNewNode-maincontainer__InputDetailField__input' type={type}
      placeholder={label}
      value={input ? input : state[keyName]}
      onChange={(e) => {
        state[keyName] = e.target.value
        const error = validate(e.target.value)
        setError(error)
        setState({ ...state })
      }} />
  </div>
}

const CreateHubPanel = ({ userCookie, setFormPopup, setAllHub, allHub, setDuplicateHub, allLocations, handleNotifyAlert, SetLoadingVisibility, adminData, setSelectedTab }) => {
  const modalRef = useRef();
  const [newHubdata, setNewHubData] = useState([]);
  const [showAddPopup, setShowAddPopup] = useState(false)
  const [selectCountry, setSelectCountry] = useState(allLocations.countryNames[adminData.country]);
  const [selectZone, setSelectZone] = useState(allLocations.zonesNames[adminData.zone]);
  const [selectState, setSelectState] = useState(allLocations.stateNames[adminData.state]);
  const [userData, setUserData] = useState({})

  const [signupStep, setSignupStep] = useState(0);
  const signupStepsArr = ["Basic Details", "Company Details", "Get Started"];
  const [inputState, setInputState] = useState({})
  const [updateSelf, setUpdateSelf] = useState(false)

  const updateDetails = () => {
    setUpdateSelf((prev) => { return !prev })
    // console.log(signupData)
  }

  const signupStepComponents = {
    0: <div>"Basic Details"</div>,
    // 1: <div>"Operational Details"</div>,
    // 2: <div>"Additional Details"</div>,
  }

  console.log(newHubdata);
  const content = {
    outurl: "/hub/add_hub",
    input: [{ label: "Latitude", key: "latitude", type: "number" },
    { label: "Longitude", key: "longitude", type: "number" },
    { label: "Location Name", key: "location_name", type: "text" },
    { label: "District", key: "district", type: "text" },
    { label: "Full Address", key: "address", type: "text" },
    { label: "Pin code", key: "pin_code", type: "number" },
    { label: "Tag Name", key: "tag_name", type: "text" }],
    drop_down: [{ label: "Country", key: "country_id", inurl: "/country/get-country" },
    { label: "Zone", key: "zone_id", inurl: "/zone/get_zone" },
    { label: "State", key: "state_id", inurl: "/state/get_state" }]
  }

  const closeModal = e => {
    if (modalRef.current === e.target) {
      //window.location.reload(false)
      //setSelectedTab("All Flights")
      setFormPopup(false);
    }
  };
  console.log("state", selectState)
  console.log("Country", selectCountry)
  console.log("Zone", selectZone)
  const handleCreateNewHub = () => {
    console.log("inputData", inputState);
    SetLoadingVisibility(true)
    // console.log(newHubdata, selectState)
    axios({
      url: backEndIP + "/hub/add_hub",
      method: "POST",
      headers: {
        authorization: userCookie.token,
      },
      data: { ...inputState },
    })
      .then((resp) => {
        console.log("API Responses:", resp.data);
        SetLoadingVisibility(false)
        setFormPopup(false);
        const sortedData = resp.data.data.sort((a, b) => (Number(a.tag_name.slice(1)) > Number(b.tag_name.slice(1)) ? 1 : -1));
        setAllHub(sortedData);
        setDuplicateHub(sortedData);
        // setAllHub(resp.data.data.sort((a, b) => (Number(a.tag_name.slice(1)) > Number(b.tag_name.slice(1)) ? 1 : -1)))
        // setDuplicateHub(resp.data.data.sort((a, b) => (Number(a.tag_name.slice(1)) > Number(b.tag_name.slice(1)) ? 1 : -1)))
        // dispatch(setHubNodeLocationsLoading("idle"))
        handleNotifyAlert(
          "notify",
          `Hub Added successfully`,
          "success",
          () => { }
        );
        // console.log(resp.data);
        //setShowAddPopup(true);
        setAllHub([...allHub, resp.data.data])
      })
      .catch((err) => {
        // if (err.response.data.message) window.alert(err.response.data.message);
        // else window.alert("some error occurered");
        // console.log(err);
        console.log(err);
        SetLoadingVisibility(false);
        setFormPopup(false);
        handleNotifyAlert(
          "alert",
          `${err.response.message}`,
          "failed",
          () => { }
        );
      });
  }
  // console.log("Country", selectCountry)
  console.log("All Locatin", allLocations)
  // console.log("userCookie", userCookie)

  const createAllRows = (section) => {
    let rows = [];
    const mySection = section
    console.log("SelectedSection", mySection)
    for (let i = 1; i <= mySection.noOfRows; i++) {
      const myRow = mySection[`row_${i}`]
      console.log("myRow", myRow)
      let cols = []

      for (let j = 1; j <= myRow.noOfCols; j++) {
        const myCol = myRow[`col_${j}`]
        console.log("myCol", myCol)
        const col = <div className="CreateNewNode-maincontainer__body__rowtest__col" style={{ width: `${Math.round(myCol.width * 100)}%`, flexGrow: `${Math.round(myCol.width / 0.33)}` }}>
          {myCol.data.map((item, itemIndex) => {
            //if(item.value){console.log("VALUE", item.value)}
            return <InputDetailField input={item.value} key={item.label + itemIndex} label={item.label} keyName={item.keyName} isMandatory={item.isMandatory} state={inputState} setState={setInputState} width={1} type={item.type} validate={validateFunctions[item.keyName]} />
          })}
        </div>
        console.log("col", col)
        cols.push(col)

      }

      console.log("cols", cols)
      const row = <div className="CreateNewNode-maincontainer__body__rowtest">
        {cols}
      </div>

      console.log("row", row)
      rows.push(row)
      console.log("rows", rows)

    }
    console.log("row compiled", rows)
    return rows
  }

  const validateFunctions = {
    "latitude": validateLatitude,
    "longitude": validateLongitude,
    "location_name": validateLocationName,
    "district": validateDistrict,
    "address": validateFullAddress,
    "pin_code": validatePinCode,
    "tag_name": validateTagName,
    "country_id": validateCountry,
    "zone_id": validateZone,
    "state": validateState,

  };

  const formData = {
    noOfSections: 3,
    section_1: {
      label: "Add New Hub",
      noOfRows: 4,
      row_1: {
        height: 2, //in units
        noOfCols: 3,
        col_1: {
          width: 1 / 3,
          data: [
            { label: "Latitude", isMandatory: true, keyName: "latitude", type: "number", width: (1 / 3), validate: validateFunctions },

          ]
        },
        col_2: {
          width: 1 / 3,
          data: [
            { label: "Longitude", isMandatory: true, keyName: "longitude", type: "text", width: (1 / 3), validate: validateFunctions },


          ]
        },

        col_3: {
          width: 1 / 3,
          data: [
            { label: "Location Name", isMandatory: true, keyName: "location_name", type: "text", width: (1 / 3), validate: validateFunctions },


          ]
        },


      },


      row_2: {
        height: 1, //in units
        noOfCols: 3,
        col_1: {
          width: 1 / 3,
          data: [
            { label: "District", isMandatory: true, keyName: "district", type: "text", width: (1 / 3), validate: validateFunctions },


          ]
        },
        col_2: {
          width: 1 / 3,
          data: [

            { label: "Full Address", isMandatory: true, keyName: "address", type: "text", width: (1 / 3), validate: validateFunctions }

          ]
        },

        col_3: {
          width: 1 / 3,
          data: [

            { label: "Pin Code", isMandatory: true, keyName: "pin_code", type: "number", width: (1 / 3), validate: validateFunctions }

          ]
        },

      },

      row_3: {
        height: 1, //in units
        noOfCols: 1,
        col_1: {
          width: 1 / 3,
          data: [
            { label: "Tag Name", isMandatory: true, keyName: "tag_name", type: "text", width: (1 / 3), validate: validateFunctions },


          ]
        },


      },

      row_4: {
        height: 1, //in units
        noOfCols: 3,
        col_1: {
          width: 1 / 3,
          data: [
            { label: "Country", isMandatory: true, keyName: "country_id", type: "text", width: (1 / 3), validate: validateFunctions, value: selectCountry },


          ]
        },
        col_2: {
          width: 1 / 3,
          data: [

            { label: "Zone", isMandatory: true, keyName: "zone_id", type: "text", width: (1 / 3), validate: validateFunctions, value: selectZone },

          ]
        },

        col_3: {
          width: 1 / 3,
          data: [

            { label: "State", isMandatory: true, keyName: "state", type: "text", width: (1 / 3), validate: validateFunctions, value: selectState }

          ]
        },

      },

    },



  }



  return (
    <div onClick={closeModal} ref={modalRef} className="CreateNewHubBackground">
      <div className="CreateNewHubContainer">
        <div className="CreateNewHubContainer__form">Add New Hub</div>
        <div className="CreateNewHubContainer__subContainer">
          <div className="CreateNewHubContainer__left">
            {content.input.map((item) => {
              return <InputFieldBox key={item.key} item={item} setNewHubData={setNewHubData} userCookie={userCookie}></InputFieldBox>
            })}
          </div>
          {/* {content.drop_down.map((item) => {
                return <InputDropDownBox key={item.key} item={item} setInfoToSend={setInfoToSend} userCookie={userCookie}></InputDropDownBox>
            })} */}
          <div className="CreateNewHubContainer__right">
            <div className="CreateNewHubContainer__field">
              <div className="CreateNewHubContainer__field__title">Country</div>
              <div className="CreateNewHubContainer__field__colon">:</div>
              <div className="CreateNewHubContainer__field__value">
                <select className="CreateNewHubContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectCountry(allLocations.countryNames[e.currentTarget.value])
                      setNewHubData((prev) => {
                        // console.log("select", selectCountry)
                        let obj = { ...prev };
                        obj[content.drop_down[0].key] = e.target.value;
                        return obj;
                      });
                    } else {
                      setSelectCountry(allLocations.countryNames[adminData.country]);
                      setSelectZone(allLocations.zonesNames[adminData.zone]);
                      setSelectState(allLocations.stateNames[adminData.state]);
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option value={""} className="CreateNewHubContainer__field__value__option">{selectCountry}</option>
                  {allLocations.country.map((item, i) => {
                    return <option value={item._id} key={i+1} className="CreateNewHubContainer__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>
             <div className="CreateNewHubContainer__field">
              <div className="CreateNewHubContainer__field__title">Zone</div>
              <div className="CreateNewHubContainer__field__colon">:</div>
              <div className="CreateNewHubContainer__field__value">
                <select className="CreateNewHubContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectZone(allLocations.zonesNames[e.currentTarget.value])
                      setNewHubData((prev) => {
                        // console.log("select", selectZone)
                        let obj = { ...prev };
                        obj[content.drop_down[1].key] = e.target.value;
                        return obj;
                      });
                    }
                    else {
                      setSelectZone(allLocations.zonesNames[adminData.zone]);
                      setSelectState(allLocations.stateNames[adminData.state]);
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option className="CreateNewHub-container__field__value__option" value={""}>{selectZone}</option>
                  {allLocations.zones.map((item, i) => {
                    return <option value={item._id} key={i+1} className="CreateNewHub-container__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="CreateNewHubContainer__field">
              <div className="CreateNewHubContainer__field__title">State</div>
              <div className="CreateNewHubContainer__field__colon">:</div>
              <div className="CreateNewHubContainer__field__value">
                <select className="CreateNewHubContainer__field__value__option"
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      setSelectState(allLocations.stateNames[e.currentTarget.value])
                      setNewHubData((prev) => {
                        // console.log("select", selectState)
                        let obj = { ...prev };
                        obj[content.drop_down[2].key] = e.target.value;
                        return obj;
                      });
                    }
                    else {
                      setSelectState(allLocations.stateNames[adminData.state]);
                    }
                    // console.log(e.currentTarget.value);
                  }}>
                  <option value={""} className="CreateNewHubContainer__field__value__option">{selectState}</option>
                  {allLocations.states[selectZone].map((item, i) => {
                    return <option value={item._id} key={i+1} className="CreateNewHubContainer__field__value__option">{item.name}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="CreateNewHubContainer__bottom">
          {selectState !== "" && <button className="CreateNewHubContainer__bottom__createHubBtn"
            onClick={handleCreateNewHub}
          >
            Create Hub
          </button>}
        </div>
      </div>

      {showAddPopup ? <HubAddPopUp setOpenModal={setShowAddPopup} setSelectedTab={setSelectedTab} messageToDisplay={{ message: "New Hub Added Succesfully" , func: () => { } }} /> : null}
    </div>

    // <div onClick={closeModal} ref={modalRef} className="CreateNewNode-background">
    //   <button className="CreateNewNode-background__close"
    //     onClick={() => { setFormPopup(false); }}
    //   >X</button>
    //   <div className='CreateNewNode-maincontainer'>
    //     <div className='CreateNewNode-maincontainer__header'>
    //       <div className='CreateNewNode-maincontainer__header__itemsCont'>
    //         {Object.keys(formData).map((section, i) => {
    //           // console.log("section",section)
    //           if (section.startsWith("section")) {
    //             const mySection = formData[section];
    //             // console.log("mySection", mySection)
    //             const num = parseInt(section.split("_")[1])
    //             // console.log("num",signupStep,num)
    //             return <>
    //               <div className={`${signupStep === num - 1 ? "CreateNewNode-maincontainer__header__itemsCont__item__active"
    //                 : signupStep < num - 1 ? "CreateNewNode-maincontainer__header__itemsCont__item__postActive"
    //                   : "CreateNewNode-maincontainer__header__itemsCont__item__preActive"}`}
    //                 onClick={() => { setSignupStep(num - 1) }}>{mySection.label}</div>
    //               {/* {num < formData.noOfSections ?
    //                 <svg className='CreateNewNode-maincontainer__header__itemsCont__item__arrow' viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                   <path id="ArrowRight" d="M15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.6967C10.4645 0.403807 9.98959 0.403807 9.6967 0.6967C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM-6.55671e-08 6.75L15 6.75L15 5.25L6.55671e-08 5.25L-6.55671e-08 6.75Z"
    //                     fill={signupStep > num - 1 ? "var(--primaryTextColor)" : "#878B8F"} />
    //                 </svg>
    //                 : null} */}
    //             </>
    //           }

    //           return null
    //         })}

    //       </div>
    //       <div className='CreateNewNode-maincontainer__header__progress-cont' >
    //         <div className={`CreateNewNode-maincontainer__header__progress-cont__progress`}
    //           style={{ width: `${((signupStep + 1) / formData.noOfSections) * 100}%` }}>
    //         </div>
    //       </div>
    //     </div>
    //     <div className='CreateNewNode-maincontainer__body'>
    //       {Object.keys(formData).map((section, s) => {
    //         // console.log("section", section)
    //         // console.log(section, data[section]["rows"])
    //         if (section.startsWith("section")) {
    //           const mySection = formData[section];
    //           console.log("mySection", mySection)
    //           const num = parseInt(section.split("_")[1])
    //           return <>
    //             {/* {s === signupStep ? data[section]["rows"].map((row, r) => {
    //                     return <div className='CreateNewNode-maincontainer__body__row'>
    //                         {row.map((item, i) => {
    //                             console.log("item", item)
    //                             return <InputDetailField label={item.label} keyName={item.keyName} isMandatory={item.isMandatory} state={inputState} setState={setInputState} width={item.width} type={item.type} />
    //                         })}
    //                     </div>
    //                     }) : null} */}

    //             {num - 1 === signupStep && mySection.noOfRows ? createAllRows(mySection) : null}
    //           </>
    //         }
    //         return null

    //       })
    //       }
    //       {/* <button onClick={() => { handleCreateNewLocation() }}>Submit</button> */}
    //     </div>
    //     <div className='CreateNewNode-maincontainer__footer'>
    //       <button className='CreateNewNode-maincontainer__footer__nextBtn'
    //         onClick={() => {
    //           handleCreateNewHub()
    //           // if (signupStep < 1) {
    //           //   setSignupStep(signupStep + 1)

    //           // } 
    //           //  else {
    //           //   handleCreateNewHub();


    //           //   console.log('Form submitted');
    //           // }


    //         }}> Submit
    //         {/* {signupStep < 2 ? 'Next' : 'Submit'} */}
    //         <svg className='w-4 h-3 ml-3' viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //           <path id="ArrowRight" d="M15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.6967C10.4645 0.403807 9.98959 0.403807 9.6967 0.6967C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM-6.55671e-08 6.75L15 6.75L15 5.25L6.55671e-08 5.25L-6.55671e-08 6.75Z"
    //             fill="white" />
    //         </svg>
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CreateHubPanel;
