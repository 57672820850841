const systemUser = [
    {
        label: "FLEET MANAGEMENT",
        children: [],
    },
    {
        label: "OPERATIONS MANAGEMENT",
        children: [
            {
                label: "MONITOR DRONE",
                children: [],
            },
            {
                label: "MONITOR ALL DRONE",
                children: [],
            }
        ],
    },
    {
        label: "ORDER MANAGEMENT",
        children: [],
    },
    {
        label: "DRONE VIEW & TRACKING",
        children: [
            {
                label: "VIEW FLIGHT",
                children: [],
            },
            {
                label: "VIEW ALL DRONE",
                children: [],
            }],
    }
]


const hubManager = [
    {
        label: "FLEET MANAGEMENT",
        children: [],
    },
    {
        label: "OPERATIONS MANAGEMENT",
        children: [
            {
                label: "MONITOR DRONE",
                children: [],
            },
            {
                label: "MONITOR ALL DRONE",
                children: [],
            }
        ],
    },
    {
        label: "ORDER MANAGEMENT",
        children: [],
    },
    {
        label: "DRONE VIEW & TRACKING",
        children: [
            {
                label: "VIEW FLIGHT",
                children: [],
            },
            {
                label: "VIEW ALL DRONE",
                children: [],
            }],
    }
]

const DronePilot = [
    {
        label: "FLEET MANAGEMENT",
        children: [],
    },
    {
        label: "OPERATIONS MANAGEMENT",
        children: [
            {
                label: "MONITOR DRONE",
                children: [],
            }
        ],
    },
    {
        label: "ORDER MANAGEMENT",
        children: [],
    }
]

const StateManager = [
    {
        label: "FLEET MANAGEMENT",
        children: [],
    },
    {
        label: "OPERATIONS MANAGEMENT",
        children: [
            {
                label: "MONITOR ALL DRONE",
                children: [],
            }
        ],
    },
    {
        label: "ORDER MANAGEMENT",
        children: [],
    },
    {
        label: "DRONE VIEW & TRACKING",
        children: [
            {
                label: "VIEW ALL DRONE",
                children: [],
            }],
    }
]

const zoneManager = [
    {
        label: "FLEET MANAGEMENT",
        children: [],
    },
    {
        label: "OPERATIONS MANAGEMENT",
        children: [
            {
                label: "MONITOR ALL DRONE",
                children: [],
            }
        ],
    },
    {
        label: "ORDER MANAGEMENT",
        children: [],
    },
    {
        label: "DRONE VIEW & TRACKING",
        children: [
            {
                label: "VIEW ALL DRONE",
                children: [],
            }],
    }
]


const countryManager = [
    {
        label: "FLEET MANAGEMENT",
        children: [],
    },
    {
        label: "OPERATIONS MANAGEMENT",
        children: [
            {
                label: "MONITOR ALL DRONE",
                children: [],
            }
        ],
    },
    {
        label: "ORDER MANAGEMENT",
        children: [],
    },
    {
        label: "DRONE VIEW & TRACKING",
        children: [
            {
                label: "VIEW ALL DRONE",
                children: [],
            }],
    }
]

const CEO = [
    {
        label: "FLEET MANAGEMENT",
        children: [],
    },
    {
        label: "OPERATIONS MANAGEMENT",
        children: [
            {
                label: "MONITOR ALL DRONE",
                children: [],
            }
        ],
    },
    {
        label: "ORDER MANAGEMENT",
        children: [],
    },
    {
        label: "DRONE VIEW & TRACKING",
        children: [
            {
                label: "VIEW ALL DRONE",
                children: [],
            }],
    }
]


const OrderManager = [
    {
        label: "ORDER MANAGEMENT",
        children: [],
    }
]

export const customSorting = (data, sequence, label) =>{
    const sequenceMap = new Map(sequence.map((value, i)=>[value, i]))
    return data.sort((a, b)=>{
        const aValue = sequenceMap.get(a[label]);
        const bValue = sequenceMap.get(b[label]);

        return aValue - bValue;
    })
}