import React, { useEffect, useState } from 'react';
import EmployeeDeployPopup from '../EmployeeDeployPopup/EmployeeDeployPopup';
import "./EmployeesTabContent.scss"
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';
import AddNewEmployeeForm from '../AddNewEmployeeForm/AddNewEmployeeForm';
import HubsFilter from '../HubsFilter/HubsFilter';
import ZoneDropDown from '../ZoneDropDown/ZoneDropDown';
import StateDropDown from '../StateDropDown/StateDropDown';


const EmployeesTabContent = ({
    employees,
    setEmployees,
    adminLocation,
    adminDesignation,
    allLocations,
    refreshDeployLocations,
    setRefreshDeployLocations,
    adminAuthLocType,
    allZoneLocations,
    userCookie,
    SetLoadingVisibility,
    handleNotifyAlert }) => {


    const [employeeDeployVisibility, setEmployeeDeployVisibility] = useState(false);
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [addEmployeePopup, setAddEmployeePopup] = useState(false)




    const locations = {
        "country": "countryNames",
        "zone": "zoneNames",
        "state": "stateNames",
        "hub": "hubNames",
    }

    useEffect(() => {
        axios({
            url: backEndIP + "/admin/employee",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then((employeesResp) => {
            //console.log(employeesResp.data)
            setEmployees(employeesResp.data.data);
        }).catch((err) => {
            console.log(err);
        })

    }, [addEmployeePopup])
    const handleFilterChange = (e) => {
    }
    const [selectedHub, setSelectedHub] = useState("All Hubs")
    const [selectedZone, setSelectedZone] = useState("All Zones")
    const [selectedState, setSelectedState] = useState("All States")
    console.log("All Location", allLocations)

    // useEffect(() => {
    //     console.log("zone", selectedZone, allLocations?.states, allLocations.states[allLocations.zonesNames[selectedZone]])
    // }, [selectedZone])


    return (
        <div className='EmployeesTabContent-container'>
            <div className='EmployeesTabContent-container__filter'>
                {adminDesignation === "System User" ? <button className='EmployeesTabContent-container__filter__addButton'
                    onClick={() => { setAddEmployeePopup(true) }}>Add Employee</button>
                    : null}

                <div className="EmployeesTabContent-container__dropdowns">
                    {/* <ZoneDropDown userCookie={userCookie}
                    allZoneLocations={allLocations.zonesNames}
                    setSelectedZone={setSelectedZone}
                    selectedZone={selectedZone}
                />
                <StateDropDown userCookie={userCookie}
                    allStateLocations={selectedZone === "All Zones" ? allLocations.stateNames : allLocations?.states[allLocations.zonesNames[selectedZone]]}
                    setSelectedState={setSelectedState}
                    selectedState={selectedState}
                /> */}
                    {/* <HubsFilter userCookie={userCookie}
                    allHubLocations={allLocations.hubNames}
                    setSelectedHub={setSelectedHub}
                    selectedZone={selectedZone}
                /> */}


                </div>
            </div>


            <div className='EmployeesTabContent-datacontainer'>

                <div className='EmployeesTabContent-container__index'>
                    <div className='EmployeesTabContent-container__index__Employee EmployeesTabContent-container__index__term'>
                        Name
                    </div>
                    <div className='EmployeesTabContent-container__index__active EmployeesTabContent-container__index__term'>
                        Account Status
                    </div>
                    <div className='EmployeesTabContent-container__index__Designation EmployeesTabContent-container__index__term'>
                        Designation
                    </div>
                    <div className='EmployeesTabContent-container__index__Country EmployeesTabContent-container__index__term'>
                        Country
                    </div>
                    <div className='EmployeesTabContent-container__index__Zone EmployeesTabContent-container__index__term'>
                        Zone
                    </div>
                    <div className='EmployeesTabContent-container__index__State EmployeesTabContent-container__index__term'>
                        State
                    </div>
                    <div className='EmployeesTabContent-container__index__Hub EmployeesTabContent-container__index__term'>
                        Physical Location
                    </div>
                    <div className='EmployeesTabContent-container__index__authLoc EmployeesTabContent-container__index__term'>
                        Authorized Location
                    </div>
                    <div className='EmployeesTabContent-container__index__Change-Location EmployeesTabContent-container__index__term'>
                        Actions
                    </div>
                </div>
                {/* {employees?.map((employee) => {
                    //console.log(employee)
                    if (
                        (employee.hub === selectedHub || selectedHub === "All Hubs") &&
                        (employee.zone === selectedZone || selectedZone === "All Zones") &&
                        (employee.state === selectedState || selectedState === "All States")
                    )
                        return <div className='EmployeesTabContent-datacontainer__row' key={employee._id}>
                            <div className='EmployeesTabContent-datacontainer__row__Employee EmployeesTabContent-datacontainer__row__term'>
                                {employee.userName}
                            </div>
                            <div className='EmployeesTabContent-datacontainer__row__active EmployeesTabContent-datacontainer__row__term'>
                                {employee.active ? "Active" : "Deactivated"}
                            </div>
                            <div className='EmployeesTabContent-datacontainer__row__Designation EmployeesTabContent-datacontainer__row__term'>
                                {employee.accessRole}
                            </div>
                            <div className='EmployeesTabContent-datacontainer__row__Country EmployeesTabContent-datacontainer__row__term'>
                                {employee?.country && allLocations?.countryNames[employee.country] ? allLocations?.countryNames[employee.country] : ""}
                            </div>
                            <div className='EmployeesTabContent-datacontainer__row__Zone EmployeesTabContent-datacontainer__row__term'>
                                {employee?.zone && allLocations?.zonesNames[employee.zone] ? allLocations.zonesNames[employee.zone] : ""}
                            </div>
                            <div className='EmployeesTabContent-datacontainer__row__State EmployeesTabContent-datacontainer__row__term'>
                                {employee?.state && allLocations?.stateNames[employee.state] ? allLocations.stateNames[employee.state] : ""}
                            </div>
                            <div className='EmployeesTabContent-datacontainer__row__Hub EmployeesTabContent-datacontainer__row__term'>
                                {employee?.location && allLocations?.hubNames[employee.location] ? allLocations.hubNames[employee.location] : ""}
                            </div>
                            <div className='EmployeesTabContent-datacontainer__row__authLoc EmployeesTabContent-datacontainer__row__term'>
                                {employee.authorized_location && allLocations.allNames[employee.authorized_location] ? allLocations.allNames[employee.authorized_location] : ""}
                                //{employee.authorized_location && allLocations[locations[employee.authorized_location_type]][employee.
                                // authorized_location] ? allLocations[locations[employee.authorized_location_type]][employee.
                                //authorized_location] : ""}
                            </div>
                            <div className='EmployeesTabContent-datacontainer__row__Change-Location EmployeesTabContent-datacontainer__row__term'>
                                <button className='EmployeesTabContent-datacontainer__row__Change-Location__getDtilbtn EmployeesTabContent-datacontainer__row__term'
                                    onClick={() => { setEmployeeDetails(employee); setEmployeeDeployVisibility(true) }}>Get Details</button>
                            </div>
                        </div>
                    //   return null;
                })} */}
                {employees?.map((employee) => {
                    if (!employee) return null;

                    const isMatching =
                        (employee?.hub === selectedHub || selectedHub === "All Hubs") &&
                        (employee?.zone === selectedZone || selectedZone === "All Zones") &&
                        (employee?.state === selectedState || selectedState === "All States");

                    if (!isMatching) return null;

                    return (
                        <div
                            className="EmployeesTabContent-datacontainer__row"
                            key={`${employee._id || Math.random()}-${employee.userName || "unknown"}`}
                        >
                            <div className="EmployeesTabContent-datacontainer__row__Employee EmployeesTabContent-datacontainer__row__term">
                                {employee.userName || "N/A"}
                            </div>
                            <div className="EmployeesTabContent-datacontainer__row__active EmployeesTabContent-datacontainer__row__term">
                                {employee.active ? "Active" : "Deactivated"}
                            </div>
                            <div className="EmployeesTabContent-datacontainer__row__Designation EmployeesTabContent-datacontainer__row__term">
                                {employee.accessRole || "N/A"}
                            </div>
                            <div className="EmployeesTabContent-datacontainer__row__Country EmployeesTabContent-datacontainer__row__term">
                                {allLocations?.countryNames?.[employee?.country] || "N/A"}
                            </div>
                            <div className="EmployeesTabContent-datacontainer__row__Zone EmployeesTabContent-datacontainer__row__term">
                                {allLocations?.zonesNames?.[employee?.zone] || "N/A"}
                            </div>
                            <div className="EmployeesTabContent-datacontainer__row__State EmployeesTabContent-datacontainer__row__term">
                                {allLocations?.stateNames?.[employee?.state] || "N/A"}
                            </div>
                            <div className="EmployeesTabContent-datacontainer__row__Hub EmployeesTabContent-datacontainer__row__term">
                                {allLocations?.hubNames?.[employee?.location] || "N/A"}
                            </div>
                            <div className="EmployeesTabContent-datacontainer__row__authLoc EmployeesTabContent-datacontainer__row__term">
                                {allLocations?.allNames?.[employee?.authorized_location] || "N/A"}
                            </div>
                            <div className="EmployeesTabContent-datacontainer__row__Change-Location EmployeesTabContent-datacontainer__row__term">
                                <button
                                    className="EmployeesTabContent-datacontainer__row__Change-Location__getDtilbtn EmployeesTabContent-datacontainer__row__term"
                                    onClick={() => {
                                        try {
                                            setEmployeeDetails(employee);
                                            setEmployeeDeployVisibility(true);
                                        } catch (error) {
                                            console.error("Error handling employee details:", error);
                                        }
                                    }}
                                >
                                    Get Details
                                </button>
                            </div>
                        </div>
                    );
                })}

            </div>
            {employeeDeployVisibility &&
                <EmployeeDeployPopup
                    setEmployees={setEmployees}
                    adminDesignation={adminDesignation}
                    setOpenModal={setEmployeeDeployVisibility}
                    employeeDetails={employeeDetails}
                    allLocations={allLocations}
                    userCookie={userCookie}
                    SetLoadingVisibility={SetLoadingVisibility}
                    handleNotifyAlert={handleNotifyAlert}
                    adminLocation={adminLocation}
                    adminAuthLocType={adminAuthLocType}
                />}
            {addEmployeePopup &&
                <AddNewEmployeeForm
                    setOpenModal={setAddEmployeePopup}
                    setEmployees={setEmployees}
                    employees={employees}
                    userCookie={userCookie}
                    SetLoadingVisibility={SetLoadingVisibility}
                    handleNotifyAlert={handleNotifyAlert}
                />}
        </div>
    )
}

export default EmployeesTabContent