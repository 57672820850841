import React, { useRef } from "react";
import "./CustomAlertBox.css";
import { backEndIP } from "../MyConfiguration";

function CustomAlertBox({ setOpenModal, messageToDisplay, setIsAborted }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
      if (setIsAborted != undefined) { setIsAborted(true); }
    }
  };


  return (
    <div onClick={closeModal} ref={modalRef} className="modalalertBackground">
      <div className="modalalertContainer" >
        <img className="custom-alert-box-img" src={`${backEndIP}/AlertBoxIcon.svg`} alt="" />
        {/* <div className="custom-alert-box-name">Alert</div> */}
        <div className="custom-alert-box-msg">{messageToDisplay.message}</div>
        <div className="custom-alert-box-button-container">
          <div onClick={() => { modalRef.current.click(); }} id="cancelBtn">Cancel</div>
          <div onClick={() => { messageToDisplay.func(); }} id="OkayBtn">OK</div>
        </div>
      </div>
    </div>

  );
}

export default CustomAlertBox;
