import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import "./MonitorDrones.scss";
import AttitudeInstrument from "../AttitudeInstrument";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomAlertBox from "../CustomAlert/CustomAlertBox";
import { useNavigate } from "react-router-dom";
import InfoIndicatorBox from "../InfoIndicatorBox/InfoIndicatorBox";
import io from "socket.io-client";
import axios from "axios";
import { backEndIP, clientSocketIP, copyRightTitle } from "../MyConfiguration";
import CustomNotification from "../CustomNotification/CustomNotification";

import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { selectHubNodeLocations, selectHubNodeLocationsLoading, setHubNodeLocations } from "../../ReduxStore/HubNodeLocationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { GMapViewAllDrones } from "./GMapViewAllDrones";
import { logoutAction } from "../utility";
import { Avatar, Dropdown } from "rsuite";

const MonitorDrones = ({ userCookie, setuserCookie, removeCookie, themeGlobal }) => {

    const [socket, setSocket] = useState(io(clientSocketIP, {
        autoConnect: false,
        transports: ["websocket", "polling"],
        auth: {
            token: userCookie.token,
            page: "monitor-all-drones"
        }
    }))
    const dispatch = useDispatch()
    const [allDronesInfo, setAllDronesInfo] = useState({});
    const [isConnected, setIsConnected] = useState(false);
    const [ConnectBtnString, setConnectBtnString] = useState("Connect Server");
    const [loadingVisibility, setLoadingVisibility] = useState(false);
    const [customAlertVisibility, setCustomAlertVisibility] = useState(false);
    const [alertBoxMesage, setAlertBoxMessage] = useState({
        message: "",
        func: () => { },
    });
    const [notificationVisibility, setNotificationVisibility] = useState(false);
    const [messageToDisplay, setMessageToDisplay] = useState({
        type: "notify",
        message: "msg",
        msgtype: "success",
        func: () => { },
    });
    const [isAborted, setIsAborted] = useState(true);

    // const [dimen, setDimen] = useState({ height: window.innerHeight, width: window.innerHeight })

    const [startLocation, setStartLocation] = useState("HUB");
    const [endLocation, setEndLocation] = useState("HUB");
    const [routePoints, setRoutePoints] = useState([]);
    const [centerToDrone, SetCenterToDrone] = useState(false);
    const [fitRoute, setFitRoute] = useState(false);
    const [gcsLog, setGCS_Log] = useState([]);
    const [updateSelf, setUpdateSelf] = useState(false)
    const [armStatus, setArmStatus] = useState(false);
    const [flightData, setFlightData] = useState({});
    const [userInitial, setUserInitial] = useState("");
    const [allAvailableDrones, setAllAvailableDrones] = useState([])
    const [selectedDrone, setSelectedDrone] = useState("Not Selected")
    const hubNodeLocationsLoadingState = useSelector(selectHubNodeLocationsLoading)
    const hubNodeLocations = useSelector(selectHubNodeLocations);


    useEffect(() => {
        setLoadingVisibility(true)
        axios({
            url: backEndIP + "/admin/verifytoken",
            // url: backEndIP + "/user/login",
            method: "GET",
            headers: {
                authorization: userCookie.token
            },
        }).then(async (loginData) => {
            await setuserCookie("token", loginData.data.token, { path: "/" })
            await setuserCookie("username", loginData.data.userName, { path: "/" })
            await setuserCookie("useremail", loginData.data.userEmail, { path: "/" })
            await setuserCookie("designation", loginData.data.designation, { path: "/" })
            await setuserCookie("accessRole", loginData.data.accessRole, { path: "/" })
            setAssignedDroneId(loginData.data.droneAssigned)
            SetDroneFlightId(loginData.data.flightId)
            setFlightData(loginData.data.flightData)
            console.log("Flightinfo =", loginData.data.flightId, loginData.data.flightData)
            setUserInitial(loginData.data.userName[0].toLocaleUpperCase())
            setLoadingVisibility(false)
            // if (loginData.data.flightId !== "") {
            //     socket.emit("client:getPreFlightChecklist", loginData.data.flightId)

            // }
            if (loginData.data.userEmail.split("@")[1] === "techeagle.in") {
                // navigate("/monitor")
            }
            else {
                socket.off();
                socket.close();
                navigate("/view-flight")
            }
        }).catch(async (err) => {
            // window.alert("login again")
            setLoadingVisibility(false)
            console.log(err);
            socket.off();
            socket.close();
            await removeCookie("token");
            await removeCookie("username");
            await removeCookie("useremail");
            await removeCookie("designation");
            await removeCookie("accessRole");
            navigate("/");
        })
    }, [])

    useEffect(() => {
        if (hubNodeLocationsLoadingState === "idle") {
            axios({
                url: backEndIP + "/location/hub-node-all-locations",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((respHubNodeLocations) => {
                console.log("respHubNodeLocations=", respHubNodeLocations.data)
                dispatch(setHubNodeLocations(respHubNodeLocations.data.data))
            }).catch((err) => {
                console.log(err);

            })
        }
    }, [hubNodeLocationsLoadingState])

    const handleUpdateAlertNotifyMsg = (type, message, msgtype, func) => {
        setMessageToDisplay({
            type: type,
            message: message,
            msgtype: msgtype,
            func: () => {
                func();
            },
        });
        if (type === "notify") {
            setNotificationVisibility(true);
        } else if (type === "alert") {
            setNotificationVisibility(true);
        }
    };



    useEffect(() => {
        if (selectedDrone !== "" && selectedDrone !== undefined) {
            // gcsLog.push([new Date().toTimeString().split("(")[0], `Drone ${selectedDrone} is selected`]);
            // setUpdateSelf(prev=>!prev);
        }
        console.log("selectedDrone =", selectedDrone)
    }, [selectedDrone])



    useEffect(() => {
        console.log("allAvailableDrones =", allAvailableDrones)
        if (!selectedDrone?.length) {
            setSelectedDrone(allAvailableDrones[0]);
        }
    }, [allAvailableDrones])


    const navigate = useNavigate();
    const [assignedDroneId, setAssignedDroneId] = useState("Unassigned")
    const [droneFlightId, SetDroneFlightId] = useState(null)
    const abortTimerID = { timer_id: "" };



    const RefLogBox = useRef();

    useEffect(() => {
        RefLogBox.current.scrollIntoView({ behavior: "smooth" });
    }, [gcsLog.length]);



    const videoRef = useRef();
    useEffect(() => {
        if (hubNodeLocationsLoadingState === "idle") {
            axios({
                url: backEndIP + "/location/hub-node-all-locations",
                // url: backEndIP + "/user/login",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then((respHubNodeLocations) => {
                console.log("respHubNodeLocations=", respHubNodeLocations.data)
                dispatch(setHubNodeLocations(respHubNodeLocations.data.data))
            }).catch((err) => {
                console.log(err);

            })
        }
    }, [hubNodeLocationsLoadingState])
    useEffect(() => {
        socket.on("connect", () => {
            setIsConnected(true);
            socket.emit("upgrade");
            socket.emit("client:get_route")
            socket.emit("client:getFlightData")
            setConnectBtnString("Disconnect");
            gcsLog.push(
                [new Date().toTimeString().split("(")[0], "connected to server"]
                // `${new Date().toTimeString().split("(")[0]}: ${"cconnect dronnected to server"}`
            );
            setUpdateSelf(prev => !prev);
        });


        socket.on("pong", () => {
            // setLastPong(new Date().toISOString());
        });
        socket.on("server:register-drone", data => {
            const droneIndex = allAvailableDrones.indexOf(data);
            setAllAvailableDrones([...allAvailableDrones])
            if (droneIndex === -1) {
                allAvailableDrones.push(data);
                setAllAvailableDrones([...allAvailableDrones])
            }
            console.log("server:register-drone", data)
        })
        socket.on("server:monitor_data", (data) => {
            allDronesInfo[data[0]] = data[1]
            setAllDronesInfo({ ...allDronesInfo });
            // console.log(data)
        });
        socket.on("server:set_route", (data) => {
            console.log(data);
            routePoints[data[0]] = data[1];
            setRoutePoints({ ...routePoints })
        });
        socket.on("server:gcs_log", (data) => {

            gcsLog.push([new Date().toTimeString().split("(")[0], data]
                // `${new Date().toTimeString().split("(")[0]}: ${data}`
            );
            setUpdateSelf(prev => !prev);
        });

        socket.on("server:arm_status", (data) => {
            setArmStatus(true);
            console.log(data)
        })

        socket.on("disconnect", () => {
            setIsConnected(false);
            setAllAvailableDrones([])
            setAllDronesInfo({})
            setSelectedDrone(prev => "Not selected");
            setConnectBtnString("Connect Server");
            gcsLog.push([new Date().toTimeString().split("(")[0], "disconnected from server"]);
            setUpdateSelf(prev => !prev);
        });

        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("pong");
            socket.off("server:register-drone");
            socket.off("server:arm_status");
            socket.off("server:monitor_data");
            socket.off("server:set_route");
            socket.off("server:gcs_log");
        };
    }, [socket]);
    const handleConnectClick = (e) => {

        if (isConnected) {
            socket.disconnect();
        }
        else {
            setConnectBtnString("Connecting");
            socket.connect();
        }

    };

    const renderToggle = props => (
        <Avatar circle {...props} src="" style={{ backgroundColor: "#FD6125" }} />
    );

    const handleReadMissionClick = () => {
        socket.emit("client:get_route")
    }
    const handleReadMissionFileClick = () => {
        socket.emit("client:read-mission");
    }
    const InfoBoxDataArray = [
        {
            posi: 1,
            label: "Altitude (m)",
            value: `${allDronesInfo[selectedDrone]?.alt ? allDronesInfo[selectedDrone]?.alt : "00"}`,
        },
        {
            posi: 2,
            label: "AirSpeed (Km/hr)",
            value: `${allDronesInfo[selectedDrone]?.a_speed ? allDronesInfo[selectedDrone]?.a_speed : "00"}`,
        },
        {
            posi: 3,
            label: "GndSpeed (Km/hr)",
            value: `${allDronesInfo[selectedDrone]?.g_speed ? allDronesInfo[selectedDrone]?.g_speed : "00"}`,
        },
        {
            posi: 4,
            label: "Voltage (V)",
            value: `${allDronesInfo[selectedDrone]?.voltage ? allDronesInfo[selectedDrone]?.voltage : "00"}`,
        },
        {
            posi: 5,
            label: "ETA (mm:ss)",
            value: `${allDronesInfo[selectedDrone]?.eta ? allDronesInfo[selectedDrone]?.eta : "00:00"}`,
        },
        {
            posi: 6,
            label: "Distance (Km)",
            value: `${allDronesInfo[selectedDrone]?.distance ? allDronesInfo[selectedDrone]?.distance : "00"}`,
        },
        {
            posi: 7,
            label: "Climb rate (m/s)",
            value: `${allDronesInfo[selectedDrone]?.climb_rate ? allDronesInfo[selectedDrone]?.climb_rate : "00"}`,
        },
        {
            posi: 8,
            label: "Mode",
            value: `${allDronesInfo[selectedDrone]?.mode ? allDronesInfo[selectedDrone]?.mode : "NA"}`,
        },
        {
            posi: 9,
            label: "GPS-fix",
            value: `${allDronesInfo[selectedDrone]?.gpsfix ? allDronesInfo[selectedDrone]?.gpsfix : "NA"}`,
        },
    ];




    const [currentMissionPlan, setCurrentMissionPlan] = useState([]);
    const [selectedWayPoint, setSelectedWayPoint] = useState(null)
    const [currentMissionPlanRoutePoints, setCurrentMissionPlanRoutePoints] = useState([]);



    return (
        <div className="home-page-container">
            <div className="home-sidebar">
                <div className="home-sidebar__logodiv">
                    <img
                        className="home-sidebar__logodiv__logoimg"
                        src={themeGlobal.nameLogo}
                        alt="logo"
                        onClick={() => { navigate("/dashboard") }}
                    />
                    <button className="home-sidebar__logout"
                        onClick={() => {
                            setAlertBoxMessage({
                                message:
                                    "Are you sure you want Logout?",
                                func: () => { logoutAction(removeCookie, navigate, socket) }
                            });
                            setCustomAlertVisibility(true);
                        }}>
                        Logout
                    </button>
                </div>
                <div className="home-sidebar__logodiv-border"></div>
                <div className="home-sidebar__DroneName">

                    <div className='home-sidebar__DroneName__droneInfo'>
                        <div className='home-sidebar__DroneName__droneInfo__droneid'>
                            <div className='home-sidebar__DroneName__droneInfo__droneid__title'>Drone Selected</div>
                            <div className='home-sidebar__DroneName__droneInfo__droneid__value'>{selectedDrone}</div>
                        </div>
                        <div className='home-sidebar__DroneName__droneInfo__flightid'>
                            <div className='home-sidebar__DroneName__droneInfo__flightid__title'>Flight ID</div>
                            <div className='home-sidebar__DroneName__droneInfo__flightid__value'>{droneFlightId !== null ? droneFlightId : "Null"}</div>
                        </div>
                    </div>
                </div>
                {/* <StandardButton name={"test"} style={{fontSize:"20px"}}/> */}
                {/* <button onClick={getAllElevationMap}>get Elevation</button> */}
                <div className="home-sidebar__infoContainer">
                    {InfoBoxDataArray.map((inf) => {
                        return (
                            <InfoIndicatorBox
                                key={inf.label}
                                infoLabel={inf.label}
                                infoValue={inf.value}
                                _width={"42%"}
                                _height={"42%"}
                            ></InfoIndicatorBox>
                        );
                    })}
                </div>
                <AttitudeInstrument rol={allDronesInfo[selectedDrone]?.roll} pit={allDronesInfo[selectedDrone]?.pitch} />
            </div>
            <div className="home-main-map">
                <div className="home-main-map__topInfobar">

                    <div className="home-main-map__topInfobar__connectBtn-container">
                        <button
                            className="home-main-map__topInfobar__connectBtn-container__connectBtn"
                            onClick={handleConnectClick}
                        // style={{backgroundColor:"#e82222"}}
                        >
                            {ConnectBtnString}
                        </button>
                        <div>VERTIPLANE X3</div>
                        {/* <LocationDropDown
                            key={`drone select`}
                            AllLocations={AllDrones}
                            setLocation={setSelectedDrone}
                            label={"Drone :"}
                        /> */}
                    </div>
                    <div className="home-main-map__topInfobar__instuctionNStatus">
                        <div
                            className="home-main-map__topInfobar__instuctionNStatus__message"
                        // ref={RefLogBox}
                        >

                            {gcsLog.length !== 0 &&
                                gcsLog.map((msg, i) => {
                                    return <div key={`gcsLog${i}`}>
                                        <span style={{ color: "#FD6125" }}><strong>{msg[0]}</strong></span>
                                        <span>: {msg[1]}</span>
                                    </div>;
                                })}
                            <div ref={RefLogBox}></div>
                        </div>
                    </div>

                    <div className="home-main-map__topInfobar__SelectLocations-container">
                        <div className="home-main-map__topInfobar__SelectLocations-container__buttons">

                            <button
                                className="home-main-map__topInfobar__SelectLocations-container__buttons__btn"
                                onClick={handleReadMissionClick}
                            >
                                Read Mission
                            </button>
                        </div>
                    </div>

                    <div className="home-main-map__topInfobar__user">
                        {/* <div className="home-main-map__topInfobar__user__proImg"
                            onClick={() => {
                                socket.off();
                                socket.close();
                                navigate("/adminpanel")
                            }}>{userInitial}</div> */}
                        <div className='home-main-map__topInfobar__user__proImg'>
                            <Dropdown renderToggle={renderToggle}
                                // style={{ position: 'absolute', top: '0px', right: '10px' }}
                                placement="bottomEnd"
                            >
                                <Dropdown.Item panel style={{ padding: 0, width: 100 }}>
                                    {/* <p>Signed in as</p> */}
                                    {/* <strong>{userCookie.username}</strong> */}
                                </Dropdown.Item>
                                {/* <Dropdown.Separator /> */}
                                <Dropdown.Item>{`Your profile`}</Dropdown.Item>
                                {/* <Dropdown.Item>Your stars</Dropdown.Item>
                        <Dropdown.Item>Your Gists</Dropdown.Item> */}
                                <Dropdown.Item
                                    onClick={() => {
                                        setAlertBoxMessage({
                                            message:
                                                "Are you sure you want to navigate to the Clearsky Training Portal?",
                                            func: () => {
                                                setCustomAlertVisibility(false);
                                                // window.location.href = "https://training.clearsky.techeagle.org/";
                                                window.open("https://training.clearsky.techeagle.org/", "_blank");
                                            }
                                        });
                                        setCustomAlertVisibility(true);
                                    }}
                                >Get Training</Dropdown.Item>
                                <Dropdown.Separator />
                                <Dropdown.Item><a href="https://forms.gle/PdXdQM5Lfnxv5Bc3A" target='blank' className='supportlink'>Support</a></Dropdown.Item>
                                {/* <Dropdown.Item>Settings</Dropdown.Item> */}
                                <Dropdown.Item onClick={() => {
                                    setAlertBoxMessage({
                                        message:
                                            "Are you sure you want Logout?",
                                        func: () => { logoutAction(removeCookie, navigate, socket) }
                                    });
                                    setCustomAlertVisibility(true);
                                }}>Log Out</Dropdown.Item>
                            </Dropdown>
                            {/* <div className='DashboardPage-container__header__userIcon__username'>{userCookie.username}</div> */}
                        </div>
                    </div>
                </div>
                <div className="home-main-map__GoogleMapContainer">

                    <GMapViewAllDrones
                        // checklistDone={checklistDone}
                        routePoints={routePoints}
                        allAvailableDrones={allAvailableDrones}
                        allDronesInfo={allDronesInfo}
                        selectedDrone={selectedDrone}
                        setSelectedDrone={setSelectedDrone}
                        droneLat={allDronesInfo[selectedDrone]?.lat}
                        droneLong={allDronesInfo[selectedDrone]?.long}
                        heading={allDronesInfo[selectedDrone]?.heading}
                        centerToDrone={centerToDrone}
                        fitRoute={fitRoute}
                        hubNodeLocations={hubNodeLocations}
                        SetCenterToDrone={SetCenterToDrone}
                        isConnected={isConnected}
                        missionRoutePoints={currentMissionPlanRoutePoints}

                    />

                    <button
                        className="home-main-map__GoogleMapContainer__followDroneBtn"
                        onClick={() => {
                            SetCenterToDrone(true);
                        }}
                    >
                        Follow Drone
                    </button>
                    <button
                        className="home-main-map__GoogleMapContainer__ZoomtoRoute"
                        onClick={() => {
                            setFitRoute(prev => !fitRoute);
                        }}
                    >
                        Fit Route
                    </button>

                </div>
                <div className="home-main-map__footer-container">
                    <div className="home-main-map__footer-container__copyright">
                        <img
                            className="home-main-map__footer-container__copyright__company-logo"
                            src={themeGlobal.EagleLogo}

                        ></img>
                        <div className="home-main-map__footer-container__copyright__title">
                            {copyRightTitle}
                        </div>
                    </div>

                </div>
            </div>
            {customAlertVisibility && (
                <CustomAlertBox
                    setOpenModal={setCustomAlertVisibility}
                    messageToDisplay={alertBoxMesage}
                    setIsAborted={setIsAborted}
                />
            )}

            {notificationVisibility && (<CustomNotification setOpenModal={setNotificationVisibility} messageToDisplay={messageToDisplay} />)}
            {loadingVisibility && <LoadingScreen />}
        </div>
    );
};

export default MonitorDrones;
