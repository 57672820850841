import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import "./AuthLocation.scss"
import axios from 'axios';
import { backEndIP } from '../MyConfiguration';

const AuthLocationInputBox = ({
  adminDesignation,
  employeeDetails,
  allLocations,
  userCookie,
  deploymentData,
  adminLocation,
  setDeploymentData,
  authLocationData,
  setCountry,
  setZone,
  setState,
  setHubLocation,
  setAuthLocationData }) => {
  const [authCountry, setAuthCountry] = useState({ _id: "", name: "" })
  const [authZone, setAuthZone] = useState({ _id: "", name: "" });
  const [authState, setAuthState] = useState({ _id: "", name: "" });
  const [authHubLocation, setAuthHubLocation] = useState({ _id: "", name: "" });
  const [authLocation, setAuthLocation] = useState({ _id: "", name: "" });

  useEffect(() => {
    // console.log(allLocations.allNames[authCountry], allLocations.allNames[authZone], allLocations.allNames[authState], allLocations.allNames[authHubLocation])
    // console.log(authCountry, authZone, authState, authHubLocation);
    // console.log("deploymentData =", deploymentData)

  }, [authCountry, authZone, authState, authHubLocation])

  const locDropDownToReset = {
    'country': ["zones", "states", "hubs"],
    "zones": ["states", "hubs"],
    "states": ["hubs"],
    "hubs": [],
  }
  const AllowedAdminDesignation = ["System User", "CEO", "Country Manager"]
  const hubManagerAndLowerDesignation = ["Hub Manager", "Testing Engineer", "Order Manager", "Drone Pilot", "Drone Maintenance Engineer"]

  const startLocArray = {
    "country": { label: "Country", locArray: allLocations.country, state: authCountry, setState: setAuthCountry, },
    "zones": { label: "Zone", locArray: allLocations.zones, state: authZone, setState: setAuthZone },
    "states": { label: "State", locArray: allLocations.states, state: authState, setState: setAuthState },
    "hubs": { label: "Hub", locArray: allLocations.hubs, state: authHubLocation, setState: setAuthHubLocation },
  }
  const endPermission = {
    "Country Manager": { permission: 0, objectName: "countryNames" },
    "Zones Manager": { permission: 1, objectName: "zonesNames" },
    "State Manager": { permission: 2, objectName: "stateNames" },
    "Hub Manager": { permission: 3, objectName: "hubNames" },
    "Drone Pilot": { permission: 3, objectName: "hubNames" },
    "Order Manager": { permission: 3, objectName: "hubNames" },
  }
  // console.log("admin designation =", adminDesignation)
  // console.log("country =", authCountry)
  return (
    <div className='AuthLocationInputBox-modalContainer'>
      <div className="AuthLocationInputBox-modalContainer__right">
        <div className="AuthLocationInputBox-modalContainer__PhyTitle"> Authorized Location</div>
        {adminDesignation === "Hub Manager" &&
          <div className="AuthLocationInputBox-modalContainer__field">
            <div className="AuthLocationInputBox-modalContainer__field__title">Hub Location</div>
            <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
            <div className="AuthLocationInputBox-modalContainer__field__value">
              <div className="AuthLocationInputBox-modalContainer__field__value__option">{allLocations?.hubNames[adminLocation]}</div>
            </div>
          </div>}

        {authLocationData[deploymentData.accessRole].availableLocation.map((locTypeName, i) => {
          // console.log(locTypeName, startLocArray[locTypeName].locArray[allLocations.allNames[authState]])
          if (authLocationData[deploymentData.accessRole].startIndex < i) {
            return <div className="AuthLocationInputBox-modalContainer__field">
              <div className="AuthLocationInputBox-modalContainer__field__title">{startLocArray[locTypeName].label}</div>
              <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
              <div className="AuthLocationInputBox-modalContainer__field__value">
                <select
                  className="AuthLocationInputBox-modalContainer__field__value__option"
                  defaultValue={""}
                  value={startLocArray[locTypeName].state}
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      // console.log("Country", allLocations.countryNames[e.currentTarget.value])
                      // const updateIdName = { _id: e.currentTarget.value, name: allLocations.countryNames[e.currentTarget.value] }
                      startLocArray[locTypeName].setState(e.currentTarget.value)
                    }
                    // console.log("Designation found", hubManagerAndLowerDesignation.includes(deploymentData.accessRole))
                    if (AllowedAdminDesignation.includes(adminDesignation)) {
                      if (hubManagerAndLowerDesignation.includes(deploymentData.accessRole)) {
                        // console.log("Country found", locTypeName === "zones")
                        deploymentData.country_id = authCountry;
                        setCountry(allLocations.allNames[authCountry])
                        if (locTypeName === "zones") {
                          deploymentData.zone_id = e.target.value;
                          setZone(allLocations.allNames[e.target.value])
                        } else if (locTypeName === "states") {
                          deploymentData.state_id = e.target.value;
                          setState(allLocations.allNames[e.target.value])
                        } else if (locTypeName === "hubs") {
                          deploymentData.location_id = e.target.value;
                          deploymentData.newLocation = "Hub"
                          setHubLocation(allLocations.allNames[e.target.value])
                        }
                      }
                    }
                    //console.log(e.currentTarget.value);
                    if (i === authLocationData[deploymentData.accessRole].availableLocation.length - 1) {
                      deploymentData.authLocation = e.currentTarget.value;
                      deploymentData.authLocType = startLocArray[locTypeName].label.toLocaleLowerCase();
                      setDeploymentData({ ...deploymentData })
                    }
                    locDropDownToReset[locTypeName]?.map((loc) => {
                      // console.log("reset dropdown", loc, startLocArray[loc].state)
                      startLocArray[loc].setState("")
                    })
                  }}>
                  <option value={""}></option>
                  {locTypeName !== "states" && locTypeName !== "hubs" && authCountry && startLocArray[locTypeName].locArray?.map((item) => {
                    return <option value={item._id}>{item.name}</option>
                  })}
                  {locTypeName === "states" && authZone !== "" && authZone.length && startLocArray[locTypeName].locArray[allLocations.allNames[authZone]]?.map((item) => {
                    return <option value={item._id}>{item.name}</option>
                  })}
                  {locTypeName === "hubs" && authState && authState.length && startLocArray[locTypeName].locArray[allLocations.allNames[authState]]?.map((item) => {
                    // console.log("hubs dropdown", item)
                    return <option value={item._id}>{item.name}</option>
                  })}
                </select>
              </div>
            </div>
          }
          else if (authLocationData[deploymentData.accessRole].startIndex === i) {
            if (startLocArray[locTypeName].state !== adminLocation) {
              startLocArray[locTypeName].setState(adminLocation)
              if (locTypeName === "country") {
                deploymentData.authLocation = adminLocation;
                deploymentData.authLocType = startLocArray[locTypeName].label.toLocaleLowerCase();
              }
              // if (AllowedAdminDesignation.includes(adminDesignation)) {
              //   if (hubManagerAndLowerDesignation.includes(deploymentData.accessRole)) {
              //     console.log("Country found", locTypeName === "zones")
              //     deploymentData.country_id = adminLocation;
              //     setCountry(allLocations.allNames[adminLocation])
              //     if (locTypeName === "zones") {
              //       deploymentData.zone_id = adminLocation;
              //       setZone(allLocations.allNames[adminLocation])
              //     } else if (locTypeName === "states") {
              //       deploymentData.state_id = adminLocation;
              //       setState(allLocations.allNames[adminLocation])
              //     } else if (locTypeName === "hubs") {
              //       deploymentData.location_id = adminLocation;
              //       deploymentData.newLocation = "Hub"
              //       setHubLocation(allLocations.allNames[adminLocation])
              //     }
              //   }
              // }
            }
            return <div className="AuthLocationInputBox-modalContainer__field">
              <div className="AuthLocationInputBox-modalContainer__field__title">{startLocArray[locTypeName].label}</div>
              <div className="AuthLocationInputBox-modalContainer__field__colon">:</div>
              <div className="AuthLocationInputBox-modalContainer__field__value">
                <div>{allLocations.allNames[adminLocation]}</div>
              </div>
            </div>

          }
          return null
        })}
      </div>
    </div>
  );
}


export default AuthLocationInputBox;